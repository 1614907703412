import { InsulinRepository } from '@/io/repository/InsulinRepository.ts';
import { makeQueryKey } from '@/queries/Queries';
import { createQuery } from '@/queries/utils/CreateQuery';
import { DiabetesDataParams } from '@/queries/utils/DiabetesQueryUtils.ts';

export class InsulinQueries {
  constructor(
    private readonly diabetes: InsulinRepository = new InsulinRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useInsulinPump = createQuery(
    'insulin-devices',
    data =>
      makeQueryKey(
        data.patientId,
        data.from?.toISODate(),
        data.to?.toISODate(),
      ),
    async (data: DiabetesDataParams) => {
      const devices = await this.diabetes.getInsulinDevices(
        data.patientId,
        data.from,
        data.to,
      );

      // Only pick the pump devices and prioritize the most recent one (lastUpload)
      const pumpDevices = devices
        .filter(d => d.kind === 'pump')
        .sort((a, b) => {
          if (!a.lastUpload && b.lastUpload) return 1;
          if (a.lastUpload && !b.lastUpload) return -1;
          if (a.lastUpload && b.lastUpload)
            return a.lastUpload.localeCompare(b.lastUpload);
          return 0;
        });

      // A query can't return undefined, so we return an object with the device
      return { device: pumpDevices.length > 0 ? pumpDevices[0] : undefined };
    },
  );
}
