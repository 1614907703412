import { PaletteColor, Theme } from '@mui/material';

import { BaseGlycemiaParameters } from '@/models/DiabetesDataModel.ts';

export const valueToPercentage = (
  value: number,
  start: number,
  end: number,
) => {
  const height = end - start;
  return (value - start) / height;
};

export const thresholdColorMapping = (
  value: number,
  thresholds: BaseGlycemiaParameters,
  theme: Theme,
): PaletteColor => {
  return value > thresholds.severeHyperglycemia
    ? theme.palette.glycemia.severeHyperglycemia
    : value > thresholds.hyperglycemia
      ? theme.palette.glycemia.hyperglycemia
      : value < thresholds.hypoglycemia
        ? theme.palette.glycemia.severeHypoglycemia
        : value < thresholds.severeHypoglycemia
          ? theme.palette.glycemia.hypoglycemia
          : theme.palette.glycemia.normal;
};
