import {
  BolusInsulin,
  ContinuousBasalInsulin,
  DiscreteBasalInsulin,
  Insulin,
  SplitInsulinData,
} from '@/models/diabetes/InsulinModel.ts';

export const isContinuousInsulin = (
  insulin: Insulin,
): insulin is ContinuousBasalInsulin =>
  insulin.type === 'short' && insulin.reason === 'basal';

export const isDiscreteInsulin = (
  insulin: Insulin,
): insulin is DiscreteBasalInsulin =>
  insulin.type === 'long' && insulin.reason === 'basal';

export const isBolusInsulin = (insulin: Insulin): insulin is BolusInsulin =>
  insulin.type === 'short' && insulin.reason === 'bolus';

/**
 * Split insulin data into continuous, discrete and bolus
 *
 * Used to display the insulin data in the daily graph
 *
 * Continuous insulin comes from pump, is short-acting and basal
 * Discrete insulin comes from pen, is long-acting and basal
 * Bolus insulin comes from pen, is short-acting and bolus
 * @param data The insulin data to split
 */
export const splitInsulinData = (data: Insulin[]): SplitInsulinData => {
  const continuous: ContinuousBasalInsulin[] = [];
  const discrete: DiscreteBasalInsulin[] = [];
  const bolus: BolusInsulin[] = [];

  data.forEach(insulin => {
    // Unshift to put the data in ascending order by date
    if (isContinuousInsulin(insulin)) {
      continuous.unshift(insulin);
    } else if (isDiscreteInsulin(insulin)) {
      discrete.unshift(insulin);
    } else if (isBolusInsulin(insulin)) {
      bolus.unshift(insulin);
    }
  });

  return { continuous, discrete, bolus };
};
