import React from 'react';

import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, CardContent, Theme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@/components/form/Form';
import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { Contact, ContactRelationship } from '@/models/ContactModel';
import {
  ContactData,
  contactFormSchema,
} from '@/pages/patient-medical-file/contact-patient/ContactPatient.schema';
import {
  CommentInput,
  EmailInput,
  EmergencyContactInput,
  FirstnameInput,
  LastnameInput,
  PhoneInput,
  SelectRelationshipInput,
} from '@/pages/patient-medical-file/contact-patient/ContactPatientFormInputs';

export type ContactPatientFormProps = {
  initialValues?: Contact;
  isSubmitting: boolean;
  onSubmit: (data: ContactData) => void;
  submitText: string;
  cancel: () => void;
};

const FORM_ID = 'ContactPatientForm';

export const relationships = {
  partner: 'contact.partner',
  grand_parent: 'contact.grandParent',
  parent: 'contact.parent',
  parent_in_law: 'contact.parentInLaw',
  child: 'contact.child',
  brother: 'contact.brother',
  cousin: 'contact.cousin',
  friend: 'contact.friend',
  other: 'contact.other',
} satisfies Record<ContactRelationship, string>;

export const ContactPatientForm: React.FC<ContactPatientFormProps> = ({
  initialValues,
  isSubmitting,
  onSubmit,
  submitText,
  cancel,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const formContext = useForm<ContactData>({
    defaultValues: initialValues || {
      relationshipWithPatient: 'other',
      isEmergencyContact: false,
    },
    resolver: yupResolver(contactFormSchema),
  });

  return (
    <Card className={styles.container} elevation={0}>
      <Form id={FORM_ID} onSubmit={onSubmit} formContext={formContext}>
        <CardContent className={styles.form}>
          <SelectRelationshipInput className={styles.selectRelationship} />
          <div className={styles.row}>
            <FirstnameInput className={styles.input} />
            <LastnameInput className={styles.input} />
          </div>
          <div className={styles.row}>
            <PhoneInput className={styles.input} />
            <EmailInput className={styles.input} />
          </div>
          <CommentInput className={styles.comment} />
          <div className={styles.row}>
            <EmergencyContactInput className={styles.input} />
          </div>
        </CardContent>
        <CardActions>
          <Button
            id="cancel-contact-create"
            onClick={cancel}
            variant="outlined"
            disabled={isSubmitting}
          >
            {t('button.cancel')}
          </Button>
          <Button
            id="submit-contact-create"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Loader size="S" /> : submitText}
          </Button>
        </CardActions>
      </Form>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  form: css`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${theme.spacing(8)};
    flex-wrap: wrap;
  `,
  selectRelationship: css`
    flex: 1 1;
    min-width: ${theme.spacing(160)};
  `,
  comment: css`
    width: 100%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(8)};
    width: 100%;
    flex-wrap: wrap;
  `,
  input: css`
    flex: 1 1 ${theme.spacing(96)};
  `,
});
