import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';

export type SearchBarProps = {
  id?: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
};

export const SearchBar: React.FC<SearchBarProps> = ({
  id,
  value,
  onChange,
  className = '',
  placeholder = '',
}) => {
  const styles = useStyles(makeStyles);

  return (
    <div id={id} className={cx(styles.container, className)}>
      <Icons.search className={styles.icons} />
      <input
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        className={styles.common}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
    width: fit-content;
    padding: ${theme.spacing(2, 0, 2, 0)};
    border-bottom: ${theme.spacing(1)} solid;
    border-color: ${theme.palette.text.primary};
    color: ${theme.palette.text.primary};
    :focus-within {
      border-color: ${theme.palette.primary.dark};
      color: ${theme.palette.primary.dark};
    }
  `,
  icons: css`
    width: ${FontSizes.body};
    height: ${FontSizes.body};
    color: inherit;
  `,
  common: css`
    color: ${theme.palette.text.primary};
    background-color: transparent;
    max-width: ${theme.spacing(80)};
    ::placeholder {
      color: ${theme.palette.text.primary};
      font-size: ${FontSizes.body};
    }
  `,
});
