import { DecoderFunction } from 'typescript-json-decoder';

import { ApiError } from '@/models/ApiErrorData.ts';
import { BGMPreferences } from '@/models/UISettingsModel.ts';

const LOCAL_STORAGE_RETRIEVE_ERROR = 'LOCAL_STORAGE_RETRIEVE_ERROR';
const LOCAL_STORAGE_STORE_ERROR = 'LOCAL_STORAGE_STORE_ERROR';

const getStorageKey = <T extends keyof LocalStorageData>(
  userId: string,
  key: T,
): string => `${key}&${userId}`;

export type LocalStorageData = {
  BGMPreferences: BGMPreferences;
};

export type LocalStorageDataSourceSpec = {
  store: <T extends keyof LocalStorageData>(
    userId: string,
    key: T,
    data: LocalStorageData[T],
  ) => Promise<undefined>;
  retrieve: <T extends keyof LocalStorageData>(
    userId: string,
    key: T,
    schema: DecoderFunction<LocalStorageData[T]>,
  ) => Promise<LocalStorageData[T] | undefined>;
};

class LocalStorageDatasource implements LocalStorageDataSourceSpec {
  store = async <T extends keyof LocalStorageData>(
    userId: string,
    key: T,
    data: LocalStorageData[T],
  ): Promise<undefined> => {
    try {
      window.localStorage.setItem(
        getStorageKey(userId, key),
        JSON.stringify(data),
      );

      return undefined;
    } catch (_) {
      throw new ApiError({
        statusCode: 400,
        error: LOCAL_STORAGE_STORE_ERROR,
        data: undefined,
      });
    }
  };

  retrieve = async <T extends keyof LocalStorageData>(
    userId: string,
    key: T,
    schema: DecoderFunction<LocalStorageData[T]>,
  ): Promise<LocalStorageData[T] | undefined> => {
    try {
      const jsonData = window.localStorage.getItem(getStorageKey(userId, key));
      if (jsonData) {
        return schema(JSON.parse(jsonData));
      }
      return undefined;
    } catch (_) {
      throw new ApiError({
        statusCode: 400,
        error: LOCAL_STORAGE_RETRIEVE_ERROR,
        data: undefined,
      });
    }
  };
}

export const localStorageDataSource = new LocalStorageDatasource();
