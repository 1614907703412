import { css } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { AgpProfile } from '@/uiKit/molecules/graphs/AgpProfile';
import { GlycemiaBars } from '@/uiKit/molecules/graphs/GlycemiaBars';

import { compareValues } from './Common';
import { GlycemiaStatsCGMNoData } from './GlycemiaStatsCGMNoData';

type GlycemiaStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
};

export const GlycemiaStatsCGM = ({
  patientId,
  from,
  to,
}: GlycemiaStatsProps) => {
  const { t } = useTranslation();
  const { data: glycemiaStats } = Queries.diabetes.useGlycemiaIndicators(
    {
      patientId,
      from: from,
      to: to,
      glycemiaMeasurementMethod: 'cgm',
    },
    {
      enabled: !!patientId,
    },
  );

  const sizeOfRange = to.diff(from, 'days').days;
  const { data: previousGlycemiaStats } =
    Queries.diabetes.useGlycemiaIndicators(
      {
        patientId,
        from: from.minus({ days: sizeOfRange + 1 }),
        to: from.minus({ days: 1 }),
        glycemiaMeasurementMethod: 'cgm',
      },
      {
        enabled: !!patientId,
      },
    );

  const styles = useStyles(makeStyles);

  const { data: glycemiaParameters } =
    Queries.diabetes.useParameters(patientId);

  if (!glycemiaStats || !glycemiaParameters) {
    return <GlycemiaStatsCGMNoData patientId={patientId} />;
  }

  return (
    <Card>
      <CardHeader title={t('glycemia.title')} />
      <CardContent className={styles.content}>
        <div className={styles.row}>
          <div className={styles.statsCol}>
            <div className={styles.statsItems}>
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.averageSensor')}
                value={glycemiaStats.meanGlycemia}
                unit={'mg/dL'}
                precision={0}
                variation={compareValues(
                  glycemiaStats.meanGlycemia,
                  previousGlycemiaStats?.meanGlycemia,
                )}
                lowerIsBetter
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.estimatedHba1c')}
                value={glycemiaStats.hb1ac as number}
                unit={'%'}
                precision={1}
                variation={compareValues(
                  glycemiaStats.hb1ac,
                  previousGlycemiaStats?.hb1ac,
                )}
                lowerIsBetter
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.coefVariation')}
                value={(glycemiaStats.coefficientOfVariation as number) * 100}
                unit={'%'}
                precision={0}
                variation={
                  compareValues(
                    glycemiaStats.coefficientOfVariation,
                    previousGlycemiaStats?.coefficientOfVariation,
                  ) * 100
                }
                lowerIsBetter
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.sensorUsage')}
                value={(glycemiaStats.usagePercentage as number) * 100}
                unit={'%'}
                precision={0}
                variation={
                  compareValues(
                    glycemiaStats.usagePercentage,
                    previousGlycemiaStats?.usagePercentage,
                  ) * 100
                }
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.nbHypo')}
                value={glycemiaStats.numberOfHypoglycemia}
                precision={0}
                variation={compareValues(
                  glycemiaStats.numberOfHypoglycemia,
                  previousGlycemiaStats?.numberOfHypoglycemia,
                )}
                lowerIsBetter
              />
            </div>
          </div>
          {glycemiaStats.glycemiaRanges &&
            glycemiaParameters.type === 'CGM' && (
              <div>
                <Typography variant="body">
                  {t('charts.timeBetweenThresholds')}
                </Typography>
                <GlycemiaBars
                  width={300}
                  height={275}
                  data={glycemiaStats.glycemiaRanges}
                  glycemiaThresholds={glycemiaParameters.parameters}
                />
              </div>
            )}
        </div>
        {glycemiaStats.agpProfile && glycemiaParameters.type === 'CGM' && (
          <div className={styles.row}>
            <AgpProfile
              agp={glycemiaStats.agpProfile}
              glycemiaParameters={glycemiaParameters.parameters}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    display: flex;
    flex-direction: column;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    row-gap: ${theme.spacing(8)};
  `,
  statsCol: css`
    flex: 1 1 50%;
  `,
  statsItems: css`
    display: flex;
    flex-direction: row;
    column-gap: ${theme.spacing(8)};
    row-gap: ${theme.spacing(12)};
    flex-wrap: wrap;
  `,
  item: css`
    flex: 0 1 30%;
  `,
});
