import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type DotProps = {
  content?: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
};

export const Dot: React.FC<DotProps> = ({
  backgroundColor,
  borderColor,
  content,
}) => {
  const styles = useStyles(
    makeLegendDotStyles,
    backgroundColor,
    borderColor,
    !!content,
  );

  return <div className={styles.item}>{content}</div>;
};

const makeLegendDotStyles = (
  theme: Theme,
  backgroundColor: string | undefined,
  borderColor: string | undefined,
  hasContent: boolean,
) => ({
  item: css({
    alignContent: 'center',
    textAlign: 'center',
    minWidth: hasContent ? '1.5rem' : '',
    width: hasContent ? 'fit-content' : theme.typography.body.fontSize,
    padding: theme.spacing(0, 1),
    aspectRatio: 1,
    borderRadius: '100%',
    border: borderColor ? `${theme.spacing(0.5)} solid ${borderColor}` : '',
    backgroundColor: backgroundColor,
  }),
});
