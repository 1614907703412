export const roundWithPrecision = (value: number, precision: number) => {
  const factor = 10 ** precision;
  return Math.round(value * factor) / factor;
};

export const formatNumber = (value: number, precision: number) => {
  const rounded = roundWithPrecision(value, precision);
  if (rounded % 1 === 0) {
    // Remove decimal part if it's 0
    return rounded.toFixed(0);
  }
  // Keep decimal part if it's not 0
  return rounded.toFixed(precision);
};
