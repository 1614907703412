import {
  NetworkMode,
  QueryClient,
  QueryFunctionContext,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ApiError } from '@/models/ApiErrorData.ts';

export type QueryKey = readonly string[];

export type AcceptedVariables =
  | string
  | number
  | boolean
  | unknown[]
  | Record<string, unknown>;

export type CreateQueryOptions<Variables extends AcceptedVariables, Data> = {
  staleTime?: number;
  gcTime?: number;
  networkMode?: NetworkMode;
  retry?:
    | boolean
    | number
    | ((failureCount: number, error: ApiError) => boolean);
  retryDelay?: number | ((retryAttempt: number, error: ApiError) => number);
  refetchInterval?: number | false;
  structuralSharing?:
    | boolean
    | (<T = Data>(oldData: T | undefined, newData: T) => T);
  onSuccess?: (
    data: Data,
    variables: Variables,
    context: CreateQueryFunctionContext,
  ) => void | Promise<void>;
};

type CreateQueryFunctionContext = QueryFunctionContext & {
  queryClient: QueryClient;
};

/**
 * Same as the options of useQuery but with some fields removed
 * @see UseQueryOptions
 *
 * queryKey: Already defined in the createQuery function and should not be modified
 * queryFn: Already defined in the createQuery function and should not be modified
 * networkMode: Depends on the data source and should not be modified by the UI
 * throwOnError: Use useSuspenseQuery / createSuspenseQuery instead
 * meta: Do not use if possible (untyped)
 * queryClient: Should use the default queryClient from the context (remove from omit only if you know what you are doing)
 *
 */
export type FilteredUseQueryOptions<Data, TransformData = Data> = Omit<
  UseQueryOptions<Data, ApiError, TransformData>,
  | 'queryFn'
  | 'queryKey'
  | 'networkMode'
  | 'throwOnError'
  | 'meta'
  | 'queryClient'
>;

/**
 * Creates a new query
 * Returns a useQuery hook with an already defined behavior (the queryKey, queryFn and networkMode are already set) and some default options
 *
 * Usage:
 * - First call createQuery with a function and a query key outside the component tree. You can also add other options like retry, staleTime, etc.
 * ```ts
 * type UsePatientQueryVariables = {
 *    patientId: string;
 * };
 *
 * export const usePatientQuery = createQuery(
 *    name: 'usePatientQuery',
 *    queryKey: (variables) => [variables.id],
 *    queryFn: async ({ patientId }: UsePatientQueryVariables) => {
 *        // Your API / Storage call here
 *        return await fetchPatient(patientId);
 *    }, {
 *        staleTime: 1000 * 60 * 5, // 5 minutes, we want to refresh our patients regularly
 *        // The only option that is not part of useQuery is onSuccess. It is just called after the queryFn is successful
 *        onSuccess: (resultData, variables, context) => {
 *            // In there you have access to the context, so it can be used to invalidate other queries, update the cache, etc.
 *        }
 *    }
 *    );
 * ```
 *
 * - Then use the hook in your component
 * ```tsx
 * const patientQuery = usePatientQuery({ // First parameter is the variables
 *    patientId: '123'
 * }, { // Second parameter is the options, same as useQuery with some fields removed (see FilteredUseQueryOptions)
 *    enabled: true,
 *    staleTime: 1000 * 60, // 1 minute, Query options can also be overridden here
 * });
 * ```
 *
 * - Also, the hook has some additional properties
 *   They can be used outside the component tree
 * ```ts
 * // Get the query key for a specific set of variables
 * const queryKey = usePatientQuery.getQueryKey({ patientId: '123' });
 * // Manually update the query data
 * usePatientQuery.manualUpdate(queryClient, { patientId: '123' }, (data) => ({ ...data, name: 'John' }));
 * // Invalidate the query
 * usePatientQuery.invalidate({ patientId: '123' });
 * ```
 */
export const createQuery = <Variables extends AcceptedVariables, Data>(
  queryName: string,
  queryKey: (variables: Partial<Variables>) => string[],
  queryFn: (variables: Variables) => Data | Promise<Data>,
  createQueryOptions?: CreateQueryOptions<Variables, Data>,
) => {
  const useCustomUseQuery = <TransformData = Data>(
    variables: Variables,
    options?: FilteredUseQueryOptions<Data, TransformData>,
  ) => {
    const queryClient = useQueryClient();
    return useQuery<Data, ApiError, TransformData>(
      {
        queryKey: [queryName, ...queryKey(variables)],
        queryFn: async context => {
          const result = await queryFn(variables);
          await createQueryOptions?.onSuccess?.(result, variables, {
            ...context,
            queryClient,
          });
          return result;
        },
        ...createQueryOptions,
        ...options,
      },
      queryClient,
    );
  };
  useCustomUseQuery.getQueryKey = (variables: Partial<Variables>) => [
    queryName,
    ...queryKey(variables),
  ];
  useCustomUseQuery.queryName = queryName;
  useCustomUseQuery.manualUpdate = (
    queryClient: QueryClient,
    variables: Partial<Variables>,
    updater: (data: Data) => Data,
  ) => {
    queryClient.setQueryData(useCustomUseQuery.getQueryKey(variables), updater);
  };
  useCustomUseQuery.invalidate = (
    queryClient: QueryClient,
    variables: Partial<Variables>,
  ) => {
    queryClient.invalidateQueries({
      queryKey: useCustomUseQuery.getQueryKey(variables),
    });
  };
  useCustomUseQuery.clear = (
    queryClient: QueryClient,
    variables: Partial<Variables>,
  ) => {
    queryClient.resetQueries({
      queryKey: useCustomUseQuery.getQueryKey(variables),
    });
  };

  return useCustomUseQuery;
};
