import { UserRepository } from '@/io/repository/UserRepository';
import {
  BGMPreferences,
  PatientListUISettings,
} from '@/models/UISettingsModel.ts';
import { makeQueryKey } from '@/queries/Queries';
import { createMutation } from '@/queries/utils/CreateMutation.ts';
import { createQuery } from '@/queries/utils/CreateQuery';

export class UserQueries {
  constructor(
    private readonly repository: UserRepository = new UserRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useMe = createQuery(
    'me',
    ({}) => [],
    async () => this.repository.getUser(),
  );

  useFeatureFlags = createQuery(
    'featureFlags',
    ({}) => [],
    async () => this.repository.getFeatureFlags(),
  );

  useBGMPreferences = createQuery(
    'use-bgm-preferences',
    ({ userId }) => makeQueryKey(userId),
    async ({ userId }: { userId: string }) =>
      this.repository.getBGMPreferences(userId),
  );

  usePatientListUISettings = createQuery(
    'use-patient-list-ui-settings',
    ({ userId }) => makeQueryKey(userId),
    async ({ userId }: { userId: string }) =>
      this.repository.getPatientListUISettings(userId),
  );

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useUpdateBGMPreferences = createMutation(
    'update-bgm-preferences',
    async ({ userId, data }: { userId: string; data: BGMPreferences }) =>
      this.repository.updateBGMPreferences(userId, data),
    {
      onSettled: (_, __, variables, { queryClient }) => {
        // Invalidate the BGM preferences query
        this.useBGMPreferences.invalidate(queryClient, variables);
      },
    },
  );

  useUpdatePatientListUISettings = createMutation(
    'update-patient-list-ui-settings',
    async ({ userId, data }: { userId: string; data: PatientListUISettings }) =>
      this.repository.updatePatientListUISettings(userId, data),
    {
      onSettled: (_, __, variables, { queryClient }) => {
        // Invalidate the patient list UI settings query
        this.usePatientListUISettings.invalidate(queryClient, variables);
      },
    },
  );
}
