import React from 'react';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';

import { SvgText } from '@/uiKit/atoms/svg/SvgText.tsx';
import { SvgLayout } from '@/utils/graphUtils.ts';

export type SvgLegendItemProps = {
  label: string;
  unit?: string;
} & Partial<SvgLayout>;

// useParentSize is badly typed, this is a workaround
type UseParentSizeReturn = {
  width: number;
  height: number;
  top: number;
  left: number;
  parentRef: React.MutableRefObject<SVGSVGElement | null>;
};

export const SvgLegendItem: React.FC<SvgLegendItemProps> = ({
  label,
  unit,
  ...dim
}) => {
  const { parentRef, width } = useParentSize() as UseParentSizeReturn;
  const theme = useTheme();

  return (
    <Group {...dim}>
      <SvgText
        verticalAnchor="end"
        innerRef={ref => (parentRef.current = ref)}
        variant="bodySmall"
        color={theme.palette.text.primary}
      >
        {label}
      </SvgText>
      <SvgText
        verticalAnchor="end"
        // Add a bit more space between the label and the unit
        left={width + 4}
        variant="caption"
        color={theme.palette.text.secondary}
      >
        {unit}
      </SvgText>
    </Group>
  );
};
