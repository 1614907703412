import { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { StyleType } from '@/uiKit/StyleTypes';

type ChipType = {
  background?: string;
  onClick?: () => void;
  enabled?: boolean;
};

export const ChipLegacy = ({
  background,
  onClick,
  children,
  className,
  enabled = true,
}: ChipType & PropsWithChildren & StyleType) => {
  const theme = useTheme();
  const backgroundColor = background
    ? background
    : theme.palette.background.default;

  const styles = useStyles(makeStyles, backgroundColor, enabled);

  return (
    <div
      onClick={enabled ? onClick : undefined}
      className={cx(styles.container, className)}
    >
      {children}
    </div>
  );
};

const makeStyles = (
  theme: Theme,
  backgroundColor: string,
  enabled: boolean,
) => ({
  container: css`
    border-radius: ${theme.spacing(8)};
    background: ${backgroundColor};
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    gap: ${theme.spacing(8)};
    cursor: ${enabled ? 'pointer' : 'not-allowed'};
    display: flex;
    justify-content: flex-end;
    opacity: ${enabled ? 1 : 0.6};
  `,
});
