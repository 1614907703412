import {
  DecoderFunction,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import { DeviceType } from '@/models/MedicalDeviceModel.ts';
import {
  decodeId,
  intersectionWithContext,
  nullOrUndef,
  stringUnion,
} from '@/utils/decoderUtils.ts';

export type ActivityIntensity = 'low' | 'medium' | 'high';

export type Activity = {
  id: number;
  patientId: string;
  date: string;
  deviceId?: string;
  deviceType?: DeviceType;

  intensity?: ActivityIntensity;
  duration: number;
};

export const activityDecoder: DecoderFunction<Activity> =
  intersectionWithContext('activity', {
    id: number,
    patientId: field('patient_id', string),
    date: string,
    deviceId: field('device_id', nullOrUndef(decodeId)),
    deviceType: field(
      'device_type',
      nullOrUndef(stringUnion('pump', 'pen', 'reader', 'sensor', 'manual')),
    ),
    intensity: nullOrUndef(stringUnion('low', 'medium', 'high')),
    duration: number,
  });

export const getActivityIntensityTranslation = (
  intensity: ActivityIntensity | undefined,
): string => {
  switch (intensity) {
    case 'low':
      return 'models.activityIntensity.low';
    case 'medium':
      return 'models.activityIntensity.medium';
    case 'high':
      return 'models.activityIntensity.high';
    default:
      return 'models.activityIntensity.unknown';
  }
};
