import { ThresholdStep } from '@/uiKit/molecules/thresholds/ThresholdStep';

import { AGPStats } from './AGPStatsModel';

export type GlycemiaMeasureType = 'interstitial' | 'capillary';
export type GlycemiaPeriod = 'breakfast' | 'lunch' | 'dinner' | 'before_bed';
export type GlycemiaPeriodTiming = 'before' | 'after';
export type GlycemiaDistribution = Record<ThresholdStep, number>;

export type GlycemiaIndicatorsResponse = {
  meanGlycemia: number;
  numberOfHypoglycemia: number;
  usagePercentage?: number;
  averageDailyMeasures?: number;
  glycemiaRanges?: GlycemiaDistribution;
  agpProfile?: AGPStats;
  hb1ac?: number;
  coefficientOfVariation?: number;
};

export const glycemiaIndicatorsDecoder = (
  value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
): GlycemiaIndicatorsResponse => {
  if (value['detail'] == 'no data available') {
    return {
      meanGlycemia: 0,
      numberOfHypoglycemia: 0,
      usagePercentage: 0,
      averageDailyMeasures: 0,
      glycemiaRanges: undefined,
      agpProfile: undefined,
      hb1ac: 0,
      coefficientOfVariation: 0,
    };
  }

  return {
    meanGlycemia: value['mean_glycemia'],
    numberOfHypoglycemia: value['number_of_hypoglycemia'],
    usagePercentage: value['usage_percentage'],
    averageDailyMeasures: value['average_daily_measures'],
    glycemiaRanges: value['glycemia_ranges']
      ? {
          severeHyperglycemia: value['glycemia_ranges']['severe_hyperglycemia'],
          hyperglycemia: value['glycemia_ranges']['hyperglycemia'],
          normal: value['glycemia_ranges']['normal'],
          hypoglycemia: value['glycemia_ranges']['hypoglycemia'],
          severeHypoglycemia: value['glycemia_ranges']['severe_hypoglycemia'],
        }
      : undefined,
    agpProfile: value['agp_profile'],
    hb1ac: value['hb1ac'],
    coefficientOfVariation: value['coefficient_of_variation'],
  };
};
