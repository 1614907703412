import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { Circle, Line, LinePath } from '@visx/shape';
import { ScaleLinear } from 'd3-scale';

import { useMousePosition } from '@/hooks/useMousePosition.ts';
import { useStyles } from '@/hooks/useStyles';
import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel.ts';
import { Glycemia } from '@/models/diabetes/GlycemiaModel.ts';
import {
  glycemiaColorInfo,
  valueToColor,
} from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaGradient.tsx';
import { GlycemiaGraphTooltip } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaTooltips.tsx';
import { GraphAreaListener } from '@/uiKit/molecules/graphs/GraphEvents/GraphAreaListener.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';
import {
  SvgCoords,
  SvgLayout,
  SvgPoint,
  boundedDateTimeToSecond,
  findSurroundingPoints,
  selectSurroundingPoint,
  splitGapsInData,
} from '@/utils/graphUtils.ts';

export type ContinuousGlycemiaGraphProps = {
  gradientId: string;
  date: string;
  yScale: ScaleLinear<number, number>;
  xScale: ScaleLinear<number, number>;
  thresholds: CGMGlycemiaParameters;
  continuous: Glycemia[];
  paddingTop?: number;
} & SvgLayout;

type ContinuousGlycemiaGraphLayout = {
  container: SvgLayout;
  mouseLine: (point: SvgPoint) => SvgCoords;
  graphArea: SvgLayout;
  eventArea: SvgLayout;
};

export const ContinuousGlycemiaGraph_: React.FC<
  ContinuousGlycemiaGraphProps
> = ({
  gradientId,
  date,
  continuous,
  thresholds,
  yScale,
  xScale,
  paddingTop = 0,
  left,
  top,
  width,
  height,
}) => {
  const styles = useStyles(makeStyles);
  const theme = useTheme();
  const colorInfo = useMemo(
    () => glycemiaColorInfo(thresholds, yScale, theme),
    [theme, yScale, thresholds],
  );
  const day = toDateTime(date);
  const { onMouseLeave, onMouseMove, position } = useMousePosition();
  const layout = useMemo(
    () => getLayout({ left, top, width, height }, paddingTop),
    [left, top, width, height, paddingTop],
  );

  // Convert data to x, y coordinates
  const coordData = continuous.map(glycemia => ({
    data: glycemia,
    x: xScale(boundedDateTimeToSecond(day, glycemia.date)),
    y: yScale(glycemia.value),
  }));

  const surroundingPoints = findSurroundingPoints(coordData, position?.x);
  const selectedPoint = selectSurroundingPoint(surroundingPoints, {
    rule: 'closest',
    maxDistance: xScale(15 * 60),
  });

  // Split data at gaps of 1 hour
  // xScale because the data got xScaled in pixels too
  const splitData = splitGapsInData(coordData, xScale(60 * 60));

  return (
    <Group className={styles.container} {...layout.container}>
      <Group {...layout.graphArea}>
        {splitData.map(line => (
          <LinePath
            key={`line-${line.at(0)?.x}`}
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth={2}
            curve={curveMonotoneX}
            data={line}
            x={item => item.x}
            y={item => item.y}
            stroke={`url(#${gradientId})`}
          />
        ))}
        {selectedPoint && (
          <GlycemiaGraphTooltip glycemia={selectedPoint.data}>
            <Line
              {...layout.mouseLine(selectedPoint)}
              className={styles.mouseLine}
            />
            <Circle
              className={cx(css`
                color: ${valueToColor(selectedPoint.y, colorInfo, theme).light};
                fill: currentColor;
                stroke: currentColor;
              `)}
              cx={selectedPoint.x}
              cy={selectedPoint.y}
              r={4}
            />
          </GlycemiaGraphTooltip>
        )}
      </Group>
      <GraphAreaListener
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        {...layout.eventArea}
      />
    </Group>
  );
};

export const ContinuousGlycemiaGraph = React.memo(ContinuousGlycemiaGraph_);

const getLayout = (
  dim: SvgLayout,
  verticalPadding: number,
): ContinuousGlycemiaGraphLayout => {
  return {
    container: {
      top: dim.top,
      left: dim.left,
      width: dim.width,
      height: dim.height,
    },
    mouseLine: point => ({
      from: { x: point.x, y: -verticalPadding },
      to: { x: point.x, y: dim.height - verticalPadding },
    }),
    graphArea: {
      top: verticalPadding,
      left: 0,
      width: dim.width,
      height: dim.height - verticalPadding,
    },
    eventArea: {
      top: 0,
      left: 0,
      width: dim.width,
      height: dim.height,
    },
  };
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  mouseLine: css({
    stroke: theme.palette.grey[200],
    strokeWidth: 1,
  }),
});
