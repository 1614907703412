import {
  DecoderFunction,
  field,
  literal,
  record,
  string,
  union,
} from 'typescript-json-decoder';

import { FileModel, fileDecoder } from '@/models/FileModel.ts';
import {
  intersectionWithContext,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type UserType = 'practitioner' | 'patient' | 'admin';

export type UserIdentity = {
  givenName: string;
  familyName: string;
};

export const userIdentityDecoder: DecoderFunction<UserIdentity> =
  recordWithContext('UserIdentity', {
    givenName: string,
    familyName: string,
  });

export type User = {
  id: string;
  email: string;
  userType: UserType;
  last_login?: string;
  createdAt: string;
  updatedAt: string;
};

type BaseExtendedUser = {
  id: string;
  email: string;
  lastLogin?: string;
  createdAt: string;
  updatedAt: string;
};

export type PatientUser = BaseExtendedUser & {
  userType: 'patient';
  firstName: string;
  lastName: string;
};

export type PractitionerUser = BaseExtendedUser & {
  userType: 'practitioner';
  firstName: string;
  lastName: string;
  qualification: 'doctor' | 'nurse';
  rpps?: string;
  profilePicture?: FileModel;
};

export type AdminUser = BaseExtendedUser & {
  userType: 'admin';
};

export type ExtendedUser = PatientUser | PractitionerUser | AdminUser;

export const userDecoder: DecoderFunction<User> = recordWithContext('User', {
  id: string,
  email: string,
  userType: stringUnion('practitioner', 'patient', 'admin'),
  last_login: nullOrUndef(string),
  createdAt: string,
  updatedAt: string,
});

const baseExtendedUserDecoder: DecoderFunction<BaseExtendedUser> = record({
  id: string,
  email: string,
  lastLogin: field('last_login', nullOrUndef(string)),
  createdAt: field('created_at', string),
  updatedAt: field('updated_at', string),
});

const patientUserDecoder: DecoderFunction<PatientUser> =
  intersectionWithContext(
    'PatientUser',
    baseExtendedUserDecoder,
    record({
      userType: field('user_type', literal('patient')),
      firstName: field('first_name', string),
      lastName: field('last_name', string),
    }),
  );

const practitionerUserDecoder: DecoderFunction<PractitionerUser> =
  intersectionWithContext(
    'PractitionerUser',
    baseExtendedUserDecoder,
    record({
      userType: field('user_type', literal('practitioner')),
      firstName: field('first_name', string),
      lastName: field('last_name', string),
      qualification: stringUnion('doctor', 'nurse'),
      rpps: nullOrUndef(string),
      profilePicture: field('profile_picture', nullOrUndef(fileDecoder)),
    }),
  );

const adminUserDecoder: DecoderFunction<AdminUser> = intersectionWithContext(
  'AdminUser',
  baseExtendedUserDecoder,
  record({
    userType: field('user_type', literal('admin')),
  }),
);

export const extendedUserDecoder: DecoderFunction<ExtendedUser> = union(
  patientUserDecoder,
  practitionerUserDecoder,
  adminUserDecoder,
);

export const userFormattedName = (
  familyName: string | undefined,
  givenName: string | undefined,
) =>
  familyName && givenName
    ? `${familyName} ${givenName}`.capitalizeAll().trim()
    : '';
