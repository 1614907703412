import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { Text } from '@visx/text';
import { TextProps } from '@visx/text/lib/Text';

import { useStyles } from '@/hooks/useStyles';
import { TypographyVariant } from '@/theme/BaseTheme.ts';
import { SvgLayout } from '@/utils/graphUtils.ts';

export type SvgTextProps = Omit<TextProps, 'x' | 'y' | 'width' | 'height'> &
  Partial<SvgLayout> & {
    variant?: TypographyVariant;
  };

/**
 * Text component for SVGs.
 *
 * TODO test performance to check if it should be memoized.
 * Always use this component to render text in SVGs.
 * Standardizes text style with the rest of the application.
 */
export const SvgText: React.FC<SvgTextProps> = ({
  children,
  className,
  verticalAnchor,
  variant,
  left: x,
  top: y,
  ...props
}) => {
  const styles = useStyles(makeStyles, variant ?? 'body');

  return (
    <Text
      className={cx(styles.text, className)}
      verticalAnchor={verticalAnchor ?? 'start'}
      x={x}
      y={y}
      {...props}
    >
      {children}
    </Text>
  );
};

const makeStyles = (theme: Theme, variant: TypographyVariant) => ({
  text: css({
    fill: 'currentcolor',
    fontSize: theme.typography[variant].fontSize,
    fontWeight: theme.typography[variant].fontWeight,
  }),
});
