export const margin = {
  top: 35,
  bottom: 20,
  left: 30,
  right: 45,
};

export const percentiles = [5, 25, 50, 75, 95];
export const HOURS = Array.from(Array(25).keys())
  .map(e => e + ' h')
  .map(e => (e.length === 3 ? '0' + e : e));
export const HOURS_SCALING = HOURS.length;
