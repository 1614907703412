import { PatientRepository } from '@/io/repository/PatientRepository';
import { createQuery } from '@/queries/utils/CreateQuery';

export class PatientQueries {
  constructor(
    private readonly patient: PatientRepository = new PatientRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  usePatient = createQuery(
    'patient',
    ({}) => [],
    async () => this.patient.getPatient(),
  );
}
