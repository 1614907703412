import React from 'react';

import { css, cx } from '@emotion/css';
import { PaletteColor } from '@mui/material';
import { Group } from '@visx/group';

import { Icons } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles';
import {
  BolusInsulin,
  DiscreteBasalInsulin,
  Insulin,
} from '@/models/diabetes/InsulinModel.ts';
import {
  BADGE_HEIGHT,
  BADGE_WIDTH,
  SvgBadge,
} from '@/uiKit/atoms/svg/SvgBadge.tsx';
import { InsulinPointTooltip } from '@/uiKit/molecules/graphs/InsulinGraph/InsulinTooltips.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';
import {
  SvgLayout,
  boundedDateTimeToSecond,
  dayScale,
  mergeOverlappingData,
} from '@/utils/graphUtils.ts';
import { formatNumber } from '@/utils/mathUtils.ts';

export type DiscreteInsulinPointsProps = {
  date: string;
  discrete: BolusInsulin[] | DiscreteBasalInsulin[];
  color: PaletteColor;
  className?: string;
  paddingTop?: number;
} & SvgLayout;

const DiscreteInsulinPoints_: React.FC<DiscreteInsulinPointsProps> = ({
  date,
  discrete,
  color,
  className,
  paddingTop = 0,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const graphArea = {
    top: dim.top + paddingTop,
    left: dim.left,
    width: dim.width,
    height: dim.height - paddingTop,
  };

  const xScale = dayScale(graphArea.width);

  const day = toDateTime(date);

  const mergedData = mergeOverlappingData<Insulin>(
    discrete,
    ins => xScale(boundedDateTimeToSecond(day, ins.date)),
    BADGE_WIDTH,
  );

  // Convert data to x, y coordinates
  const coordData = mergedData.map(merged => ({
    data: merged.data,
    x: merged.x,
    y: merged.data.reduce((acc, ins) => acc + ins.quantity, 0),
    count: merged.data.length,
  }));

  return (
    <Group className={cx(styles.container, className)} {...graphArea}>
      {coordData.map(point => (
        <InsulinPointTooltip
          key={point.x}
          insulins={point.data}
          x={BADGE_WIDTH / 2}
        >
          <SvgBadge
            key={point.x}
            text={`${formatNumber(point.y, 2)} U`}
            Icon={Icons.injection}
            left={point.x}
            top={(graphArea.height - BADGE_HEIGHT) / 2}
            color={color}
            dot={point.count > 1 ? point.count : false}
          />
        </InsulinPointTooltip>
      ))}
    </Group>
  );
};

export const DiscreteInsulinPoints = React.memo(DiscreteInsulinPoints_);

const makeStyles = () => ({
  container: css``,
});
