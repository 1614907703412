import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import { SystemZone } from 'luxon';
import { DecoderFunction } from 'typescript-json-decoder';

import {
  ApiError,
  ApiErrorData,
  apiErrorResponseDecoder,
} from '@/models/ApiErrorData.ts';

const AXIOS_NOT_INITIALIZED_ERROR = 'AXIOS_NOT_INITIALIZED';
const RESPONSE_VALIDATION_ERROR = 'RESPONSE_VALIDATION_FAILED';
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

class ApiDatasource {
  axiosInstance: AxiosInstance | undefined;

  createInstance = (token: string) => {
    this.axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_APP_API_URL || '/api/v1',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Version: 2, // this is an API-specific value
        Timezone: SystemZone.instance.name,
      },
    });
  };

  request = async <T>(
    method: Method,
    url: string,
    decoder: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ): Promise<T> => {
    if (!this.axiosInstance) {
      throw new ApiError({
        statusCode: 500,
        error: AXIOS_NOT_INITIALIZED_ERROR,
      });
    }
    let result: AxiosResponse<unknown>;
    try {
      result = await this.axiosInstance.request({
        ...config,
        method,
        url,
      });
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        let errorResponse: ApiErrorData | undefined;
        try {
          errorResponse = apiErrorResponseDecoder(e.response.status)(
            e.response.data,
          );
        } catch (_) {
          throw new ApiError({
            statusCode: e.response.status,
            error: RESPONSE_VALIDATION_ERROR,
          });
        }
        throw new ApiError(errorResponse);
      } else {
        throw new ApiError({
          statusCode: 500,
          error: UNKNOWN_ERROR,
        });
      }
    }
    try {
      return decoder(result.data);
    } catch (e) {
      console.warn(e);
      throw new ApiError({
        statusCode: 400,
        error: RESPONSE_VALIDATION_ERROR,
      });
    }
  };

  get = <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ): Promise<T> => this.request('get', url, schema, config);

  post = <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<T> =>
    this.request('post', url, schema, {
      ...config,
      data,
    });

  put = <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<T> =>
    this.request('put', url, schema, {
      ...config,
      data,
    });

  patch = <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<T> =>
    this.request('patch', url, schema, {
      ...config,
      data,
    });

  delete = <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ): Promise<T> => this.request('delete', url, schema, config);
}

export type ApiDataSourceSpec = {
  get: <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ) => Promise<T>;
  post: <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => Promise<T>;
  put: <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => Promise<T>;
  patch: <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => Promise<T>;
  delete: <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ) => Promise<T>;
};

export const apiDataSource = new ApiDatasource();
