import React from 'react';

import { css, cx } from '@emotion/css';
import { Group } from '@visx/group';

import { useStyles } from '@/hooks/useStyles';
import { SvgLayout, SvgPoint } from '@/utils/graphUtils.ts';

export type InsulinLegendProps = {
  topLabel: React.ReactNode;
  bottomLabel: React.ReactNode;
  legendPadding: SvgPoint;
  middle: number;
  className?: string;
} & SvgLayout;

type InsulinLegendLayout = {
  topLabel: SvgLayout;
  bottomLabel: SvgLayout;
};

const InsulinLegend_: React.FC<InsulinLegendProps> = ({
  topLabel,
  bottomLabel,
  legendPadding,
  middle,
  className,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const layout = getLayout(dim, middle, legendPadding);
  return (
    <Group className={cx(styles.container, className)}>
      <Group {...layout.topLabel}>{topLabel}</Group>
      <Group {...layout.bottomLabel}>{bottomLabel}</Group>
    </Group>
  );
};

export const InsulinLegend = React.memo(InsulinLegend_);

const getLayout = (
  dim: SvgLayout,
  middle: number,
  padding: SvgPoint,
): InsulinLegendLayout => {
  return {
    topLabel: {
      left: dim.left + padding.x,
      top: dim.top + padding.y,
      width: dim.width - padding.x * 2,
      height: middle - padding.y * 2,
    },
    bottomLabel: {
      left: dim.left + padding.x,
      top: dim.top + middle + padding.y,
      width: dim.width - padding.x * 2,
      height: middle - padding.y * 2,
    },
  };
};

const makeStyles = () => ({
  container: css``,
});
