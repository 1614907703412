import { useContext, useLayoutEffect, useRef } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';

import { Card } from '@/components/card/Card';
import { MessagesByStateContext } from '@/components/chat/ChatContext';
import { DateSeparator } from '@/components/chat/body/DateSeparator';
import { MessageContent } from '@/components/chat/body/MessageContent';
import { useStyles } from '@/hooks/useStyles';
import { Message } from '@/models/ChatModel';
import { toDateTime } from '@/utils/dateUtils.ts';

type GroupedMessage = {
  sentDate: DateTime;
  messages: Message[];
};

export const Messages = () => {
  const styles = useStyles(makeStyle);
  const endOfMessageRef = useRef<HTMLDivElement>(null);
  const { messages } = useContext(MessagesByStateContext);

  useLayoutEffect(() => {
    if (endOfMessageRef?.current) {
      endOfMessageRef.current.scrollIntoView();
    }
  });

  const dateViewed: number[] = [];

  const res: GroupedMessage[] = Object.values(
    messages.reduce<GroupedMessage[]>((acc, curr) => {
      const sentDate = toDateTime(curr.createdAt).startOf('day');
      const dateValue = sentDate.startOf('day').toMillis();
      if (dateViewed.includes(dateValue)) {
        const existing = acc.find(e => e.sentDate.toMillis() === dateValue);
        existing?.messages.push(curr);
      } else {
        acc.push({ sentDate, messages: [curr] });
        dateViewed.push(dateValue);
      }

      return acc;
    }, []),
  );

  return (
    <div className={styles.container}>
      {messages &&
        res.map((groupedMessage, i) => (
          <div key={i}>
            <DateSeparator date={groupedMessage.sentDate}></DateSeparator>
            {groupedMessage.messages.map((message, i) => (
              <Card key={i} elevation={0} className={styles.card}>
                <MessageContent message={message}></MessageContent>
              </Card>
            ))}
          </div>
        ))}
      <div ref={endOfMessageRef}></div>
    </div>
  );
};

const makeStyle = (theme: Theme) => ({
  container: css`
    height: 60vh;
  `,
  card: css`
    display: flex;
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(4)};
  `,
});
