import React from 'react';

import { injectGlobal } from '@emotion/css';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { frFR } from '@mui/x-date-pickers/locales';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.min.css';

import { CustomToastContainer } from '@/components/toast/Toast';
import { i18nInstance } from '@/i18n.ts';
import { KeycloakAuth } from '@/keycloak';
import { SentryInit } from '@/observation_of_users/Sentry.tsx';
import { TrackingWrapper } from '@/observation_of_users/TrackingWrapper';
import { queryClient } from '@/queries/Queries';
import { Router } from '@/router/Router';
import { lightTheme } from '@/theme/LightTheme';
import '@/utils/stringUtils';

const container = document.getElementById('root');

createRoot(container as HTMLElement).render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        localeText={
          frFR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <CssBaseline />
        <I18nextProvider i18n={i18nInstance}>
          <KeycloakAuth>
            <TrackingWrapper>
              <React.StrictMode>
                <CustomToastContainer />
                <QueryClientProvider client={queryClient}>
                  <Router />
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    buttonPosition="top-left"
                  />
                </QueryClientProvider>
              </React.StrictMode>
            </TrackingWrapper>
            <SentryInit />
          </KeycloakAuth>
        </I18nextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);

injectGlobal`
    input, a, fieldset, p, ul { all: unset; }
    
    button { all: unset; cursor: pointer; }
    
    html, body, .root {
        width: 100%;
        height: 100%;
    }
`;
