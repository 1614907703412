import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { useComponentSize } from '@/hooks/useComponentSize.ts';
import { useStyles } from '@/hooks/useStyles.ts';
import { CGMDailyGraphData } from '@/models/CGMDailyGraphData.ts';
import { InsulinDataDisplayType } from '@/models/DiabetesDisplayModeModel.ts';
import {
  SvgDailyGraph,
  SvgDailyGraphSkeleton,
} from '@/uiKit/organisms/DailyGraphList/SvgDailyGraph.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';

export type DailyGraphContentProps = {
  data: CGMDailyGraphData;
  insulinDisplayMode: InsulinDataDisplayType;
  className?: string;
};

/**
 * Display a single day of continuous glucose monitoring data.
 * Data has to come from a CGM device.
 *
 * @param data - The data to display
 * @param insulinDisplayMode
 * @param className
 * @constructor
 */
export const DailyGraphContent: React.FC<DailyGraphContentProps> = ({
  data,
  insulinDisplayMode,
  className,
}) => {
  const [divRef, { width }, isTransitioning] = useComponentSize();
  const graphHeight = insulinDisplayMode === 'continuous' ? 320 : 240;
  const styles = useStyles(makeStyles, graphHeight);
  return (
    <div
      ref={instance => {
        divRef.current = instance;
      }}
      className={className}
    >
      <Typography variant="body">
        {toDateTime(data.date)
          .toLocaleString(DateTime.DATE_HUGE)
          .capitalizeAll()}
      </Typography>
      <div className={styles.content}>
        {isTransitioning ? (
          <SvgDailyGraphSkeleton
            insulinDisplayMode={insulinDisplayMode}
            height={graphHeight}
            width={width}
          />
        ) : (
          <SvgDailyGraph
            data={data}
            width={width}
            height={graphHeight}
            insulinDisplayMode={insulinDisplayMode}
          />
        )}
      </div>
    </div>
  );
};

const makeStyles = (_: Theme, height: number) => ({
  content: css`
    height: ${height}px;
    overflow: clip;
  `,
});
