import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { FontSizes } from '@/assets/fonts';
import { PatientDetailHeader } from '@/components/navigation/PatientDetailHeader';
import { PatientMenu } from '@/components/navigation/PatientMenu';
import { PatientNavigationIcons } from '@/components/navigation/PatientNavigationIcons';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { ValidateIdentity } from '@/pages/patients/ValidateIdentity';
import { Queries } from '@/queries/Queries';

type PatientNavigationProps = {
  className?: string;
};

export const PatientNavigation: React.FC<PatientNavigationProps> = ({
  className,
}) => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const patientQuery = Queries.practitioner.usePatient(id ?? '');

  return (
    <>
      <div
        id="manage-patient-navigation"
        className={cx(styles.container, className)}
      >
        <PatientDetailHeader patient={patientQuery.data} />

        {patientQuery.data ? (
          <div className={styles.nav}>
            <PatientMenu />
            {id && <PatientNavigationIcons patientId={id} />}
          </div>
        ) : (
          <div className={styles.emptyNav} />
        )}
      </div>
      <ValidateIdentity patientId={id ?? ''} />
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.palette.common.white};
    padding: 2vh 4vw 0 4vw;
    gap: 2vh;
  `,
  nav: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  `,
  emptyNav: css`
    height: calc(${FontSizes.h5} + 5px);
  `,
});
