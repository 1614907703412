import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useComponentSize } from '@/hooks/useComponentSize.ts';
import { useStyles } from '@/hooks/useStyles';
import { AGPStats } from '@/models/AGPStatsModel';
import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel';
import {
  AgpProfileSvg,
  AgpProfileSvgSkeleton,
} from '@/uiKit/molecules/graphs/AgpProfile/AgpProfileSvg.tsx';

type AgpProfileProps = {
  className?: string;
  agp: AGPStats;
  glycemiaParameters: CGMGlycemiaParameters;
};

export const AgpProfile = ({
  className,
  agp,
  glycemiaParameters,
}: AgpProfileProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const [divRef, { width }, isTransitioning] = useComponentSize();
  const hasData = Object.entries(agp).length > 0;

  return (
    <div
      ref={instance => (divRef.current = instance)}
      className={cx(styles.container, className)}
    >
      <Typography variant="bodyLarge">
        {t('pages.patientMonitoring.agp.title')}
      </Typography>
      {hasData ? (
        isTransitioning ? (
          <AgpProfileSvgSkeleton />
        ) : (
          <AgpProfileSvg
            width={width}
            agp={agp}
            thresholds={glycemiaParameters}
          />
        )
      ) : (
        <Typography variant="body">{t('charts.noData')}</Typography>
      )}
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
});
