import { stringUnion } from '@/utils/decoderUtils';

export type TeleMonitoringCriteria =
  | 'adult_monitoring_d1'
  | 'adult_discovery_d1'
  | 'teen_monitoring_d1'
  | 'teen_discovery_d1'
  | 'corticotherapie'
  | 'teen_care_gap'
  | 'start_basal_d2'
  | 'monitoring_d2'
  | 'pregnate_d'
  | 'gestational_insulined';

export const teleMonitoringCriteriaDecoder = stringUnion(
  'adult_monitoring_d1',
  'adult_discovery_d1',
  'teen_monitoring_d1',
  'teen_discovery_d1',
  'corticotherapie',
  'teen_care_gap',
  'start_basal_d2',
  'monitoring_d2',
  'pregnate_d',
  'gestational_insulined',
);

export const TelemonitoringCriteriaTranslationMap: { [key: string]: string } = {
  adult_monitoring_d1: 'diabetesForms.inclusionCriteriaLabel.adultMonitoringD1',
  adult_discovery_d1: 'diabetesForms.inclusionCriteriaLabel.adultDiscoveryD1',
  teen_monitoring_d1: 'diabetesForms.inclusionCriteriaLabel.teenMonitoringD1',
  teen_discovery_d1: 'diabetesForms.inclusionCriteriaLabel.teenDiscoveryD1',
  corticotherapie: 'diabetesForms.inclusionCriteriaLabel.corticotherapie',
  teen_care_gap: 'diabetesForms.inclusionCriteriaLabel.teenCareGap',
  gestational_insulined:
    'diabetesForms.inclusionCriteriaLabel.gestationalInsulined',
  pregnate_d: 'diabetesForms.inclusionCriteriaLabel.pregnateD',
  start_basal_d2: 'diabetesForms.inclusionCriteriaLabel.startBasalD2',
  monitoring_d2: 'diabetesForms.inclusionCriteriaLabel.monitoringD2',
};
