import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { AtomProps } from '@/uiKit/atoms/AtomTypes.ts';

export type TagListProps = AtomProps<{
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
}>;

export const TagList: React.FC<TagListProps> = ({
  children,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <ul className={cx(styles.container, className)} {...props}>
      {children}
    </ul>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(4)};
  `,
});
