import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { PractitionerUser } from '@/models/UserModel.ts';
import { NotFound } from '@/pages/NotFound';
import { QATools } from '@/pages/QaTools/QaTools';
import { PractitionerAccountPage } from '@/pages/account/PractitionerAccountPage.tsx';
import { MeasurementImportPage } from '@/pages/add-measurement/MeasurementImportPage';
import { HealthProLayout } from '@/pages/layout/HealthProLayout';
import { PractitionerManagePatientLayout } from '@/pages/layout/PractitionerManagePatientLayout.tsx';
import { PatientMedicalFile } from '@/pages/patient-medical-file/PatientMedicalFile';
import { PatientMonitoring } from '@/pages/patient-monitoring/PatientMonitoring';
import PatientSettings from '@/pages/patient-settings/PatientSettings';
import { PatientsPage } from '@/pages/patients/PatientsPage';
import { Queries } from '@/queries/Queries';
import { ImpersonatingBanner } from '@/uiKit/molecules/ImpersonationBanner';
import { isProd } from '@/utils/commonUtils';

export type PractitionerRouterProps = {
  me: PractitionerUser;
};

export const PractitionerRouter: React.FC<PractitionerRouterProps> = ({
  me,
}) => {
  Queries.user.useFeatureFlags({}); // prefetch feature flags

  return (
    <>
      <ImpersonatingBanner />
      <Routes>
        <Route path="/" element={<PatientsPage me={me} />} />
        <Route path="/patients" element={<PatientsPage me={me} />} />
        <Route path="/account" element={<PractitionerAccountPage />} />
        <Route
          path="/patients/:id"
          element={<PractitionerManagePatientLayout outlet me={me} />}
        >
          <Route index element={<PatientMonitoring me={me} />} />
          <Route path="medical-file" element={<PatientMedicalFile />} />
          <Route path="upload-data" element={<MeasurementImportPage />} />
          <Route path="settings" element={<PatientSettings />} />
          {!isProd() && (
            <Route path="patient-management" element={<QATools />} />
          )}
        </Route>
        <Route
          path="*"
          element={
            <HealthProLayout>
              <NotFound />
            </HealthProLayout>
          }
        />
      </Routes>
    </>
  );
};
