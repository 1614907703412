import React, { useEffect, useRef, useState } from 'react';

import { FormHelperText, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const handleKeyDown = (event: React.KeyboardEvent) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

type ControlledDateFieldProps = {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
  rules?: unknown;
  maxDate?: unknown;
};

export const ControlledDateField = ({
  id,
  label,
  required = true,
  rules,
  name,
}: ControlledDateFieldProps) => {
  const { t } = useTranslation();

  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [isTouched, setIsTouched] = useState(false);

  const timeValidationRule = {
    validDate: () => {
      const fullDate = `${year}-${month}-${day}`;
      const date = DateTime.fromISO(fullDate);
      return date.isValid || t('errors.dateFormat');
    },
  };

  const defaultRules = required
    ? { required: t('form.required'), validate: timeValidationRule }
    : timeValidationRule;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'day' | 'month' | 'year',
  ) => {
    const value = event.target.value;

    if (type === 'day') {
      if (value.length === 2) {
        setDay(value);
        monthRef.current?.focus(); // Automatically move to month
      } else {
        setDay(value);
      }
    } else if (type === 'month') {
      if (value.length === 2) {
        setMonth(value);
        yearRef.current?.focus(); // Automatically move to year
      } else {
        setMonth(value);
      }
    } else if (type === 'year') {
      setYear(value);
    }
  };

  const handleBlur = (onChange: (value: string) => void) => {
    const fullDate = `${year}-${month}-${day}`;
    const date = DateTime.fromISO(fullDate);
    if (date.isValid) {
      onChange(date.toISODate());
    } else {
      onChange('<invalid>');
    }
  };

  return (
    <Controller
      name={name}
      rules={rules ? rules : defaultRules}
      render={props => (
        <DateFieldContent
          {...props}
          id={id}
          handleChange={handleChange}
          handleBlur={handleBlur}
          isTouched={isTouched}
          setIsTouched={setIsTouched}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
          dayRef={dayRef}
          monthRef={monthRef}
          yearRef={yearRef}
          label={label}
          day={day}
          month={month}
          year={year}
        />
      )}
    />
  );
};

type DateFieldContentProps = {
  id?: string;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'day' | 'month' | 'year',
  ) => void;
  handleBlur: (onChange: (value: string) => void) => void;
  isTouched: boolean;
  setIsTouched: React.Dispatch<React.SetStateAction<boolean>>;
  setDay: React.Dispatch<React.SetStateAction<string>>;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  dayRef: React.MutableRefObject<HTMLInputElement | null>;
  monthRef: React.MutableRefObject<HTMLInputElement | null>;
  yearRef: React.MutableRefObject<HTMLInputElement | null>;
  label?: string;
  day: string;
  month: string;
  year: string;
};

const DateFieldContent: React.FC<DateFieldContentProps> = ({
  id,
  field,
  fieldState,
  handleChange,
  handleBlur,
  isTouched,
  setIsTouched,
  setDay,
  setMonth,
  setYear,
  dayRef,
  monthRef,
  yearRef,
  label,
  day,
  month,
  year,
}) => {
  const { onChange, value, ...rest } = field;
  const error = fieldState?.error;
  const errorMessage = error ? error?.message : null;
  const errorMessageToDisplay =
    errorMessage && typeof errorMessage === 'string' ? errorMessage : ' ';

  // Set initial values when the component mounts or value changes
  useEffect(() => {
    const dateValue = DateTime.fromISO(value);
    if (dateValue.isValid && !isTouched) {
      setDay(String(dateValue.day).padStart(2, '0'));
      setMonth(String(dateValue.month).padStart(2, '0'));
      setYear(String(dateValue.year));
    }
  }, [value, isTouched]);

  return (
    <div id={id}>
      {label ? <Typography variant="subtitle">{label}</Typography> : null}
      <div style={{ display: 'flex', gap: '10px' }}>
        <TextField
          {...rest}
          inputRef={dayRef}
          value={day}
          onChange={e => handleChange(e, 'day')}
          onFocus={() => setIsTouched(true)}
          onBlur={() => handleBlur(onChange)}
          placeholder="JJ"
          error={!!error}
          variant="standard"
          onKeyDown={handleKeyDown}
          inputProps={{ maxLength: 2 }}
          style={{ width: '30px' }}
        />
        <Typography variant="body">/</Typography>
        <TextField
          {...rest}
          inputRef={monthRef}
          value={month}
          onChange={e => handleChange(e, 'month')}
          onFocus={() => setIsTouched(true)}
          onBlur={() => handleBlur(onChange)}
          onKeyDown={handleKeyDown}
          placeholder="MM"
          variant="standard"
          error={!!error}
          inputProps={{ maxLength: 2 }}
          style={{ width: '30px' }}
        />
        <Typography variant="body">/</Typography>
        <TextField
          {...rest}
          inputRef={yearRef}
          value={year}
          onChange={e => handleChange(e, 'year')}
          onBlur={() => handleBlur(onChange)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsTouched(true)}
          placeholder="AAAA"
          variant="standard"
          error={!!error}
          inputProps={{ maxLength: 4 }}
          style={{ width: '50px' }}
        />
      </div>
      <FormHelperText error={!!error}>{errorMessageToDisplay}</FormHelperText>
    </div>
  );
};
