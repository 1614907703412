import { useState } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CannotRenewReason } from '@/models/CarePlanModel';
import { CarePlanRenewal } from '@/pages/patient-settings/CarePlanRenewal';
import { Queries } from '@/queries/Queries';

type RenewalButtonProps = {
  'data-source': 'monitoring' | 'settings';
  patientId: string;
};

const cannotRenewTranslationMap: Record<CannotRenewReason, string> = {
  has_upcoming: 'diabetesForms.renewed',
  has_careplan_until_term: 'diabetesForms.hasUntilTerm',
  monitored_too_long: 'diabetesForms.tooLong',
};

export const RenewalButton = ({
  'data-source': dataSource,
  patientId,
}: RenewalButtonProps) => {
  const { data: carePlanData } = Queries.practitioner.useCarePlans(patientId);
  const { t } = useTranslation();
  const [isTelemonitoringDrawerOpen, setIsTelemonitoringDrawerOpen] =
    useState(false);

  if (!carePlanData) {
    return null;
  }

  if (!carePlanData.can_renew_care_plan) {
    const buttonLabel = carePlanData.cannot_renew_reason
      ? t(cannotRenewTranslationMap[carePlanData.cannot_renew_reason])
      : t('diabetesForms.renewed');

    return (
      <>
        <Button id="disabled-renew-tls-button" variant="outlined" disabled>
          {buttonLabel}
        </Button>
      </>
    );
  }

  const buttonLabel =
    carePlanData.renewal_suggestion === 'expiring'
      ? t('diabetesForms.renew') // For now, expiring also displays 'renew' but in the future more cases will come
      : carePlanData.renewal_suggestion === 'add_insulin'
        ? t('diabetesForms.addInsulin')
        : t('diabetesForms.renew');

  return (
    <>
      <Button
        id={`renew-tls-button-${dataSource}`}
        data-source={dataSource}
        data-action="renew-tls"
        variant="contained"
        onClick={() => setIsTelemonitoringDrawerOpen(true)}
      >
        {buttonLabel}
      </Button>
      {isTelemonitoringDrawerOpen && (
        <CarePlanRenewal
          patientId={patientId}
          onClose={() => setIsTelemonitoringDrawerOpen(false)}
        />
      )}
    </>
  );
};
