import React from 'react';

import { css } from '@emotion/css';
import {
  Button,
  CardActions,
  CardContent,
  MenuItem,
  Select,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Loader } from '@/components/loading/Loader.tsx';
import { AlertCategoriesTranslations } from '@/components/notifications/ActiveNotifications.tsx';
import { useStyles } from '@/hooks/useStyles';
import {
  AlertCategory,
  CreateAlertConfig,
  HyperglycemiaAlertParameters,
  HyperglycemiaTarAlertParameters,
  HypoglycemiaAlertParameters,
  HypoglycemiaTbrAlertParameters,
} from '@/models/AlertModel.ts';
import { Queries } from '@/queries/Queries.ts';

export type AlertCreationDialogProps = {
  patientId: string;
  onClose: () => void;
};

export const AlertCreationDialog: React.FC<AlertCreationDialogProps> = ({
  patientId,
  onClose,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const createAlertConfig = Queries.alerts.useCreateAlertConfig();
  const [selectedCategory, setSelectedCategory] =
    React.useState<AlertCategory>('hypoglycemia');

  return (
    <>
      <CardContent className={styles.container}>
        <Select
          value={selectedCategory}
          variant="standard"
          onChange={e => setSelectedCategory(e.target.value as AlertCategory)}
        >
          <MenuItem value="hypoglycemia">
            {t(AlertCategoriesTranslations['hypoglycemia'])}
          </MenuItem>
          <MenuItem value="hypoglycemia_tbr">
            {t(AlertCategoriesTranslations['hypoglycemia_tbr'])}
          </MenuItem>
          <MenuItem value="hyperglycemia">
            {t(AlertCategoriesTranslations['hyperglycemia'])}
          </MenuItem>
          <MenuItem value="hyperglycemia_tar">
            {t(AlertCategoriesTranslations['hyperglycemia_tar'])}
          </MenuItem>
          <MenuItem value="technical_alert">
            {t(AlertCategoriesTranslations['technical_alert'])}
          </MenuItem>
          <MenuItem value="remove_consent">
            {t(AlertCategoriesTranslations['remove_consent'])}
          </MenuItem>
          <MenuItem value="severe_hypoglycemia">
            {t(AlertCategoriesTranslations['severe_hypoglycemia'])}
          </MenuItem>
        </Select>
      </CardContent>
      <CardActions>
        <Button
          id="create-alert-config"
          variant="contained"
          startIcon={<Icons.plus />}
          onClick={() =>
            createAlertConfig.mutate(
              {
                patientId,
                alertConfig: createConfig(selectedCategory),
              },
              {
                onSuccess: onClose,
              },
            )
          }
        >
          {createAlertConfig.isPending ? (
            <Loader size="S" />
          ) : (
            t('alertConfig.create')
          )}
        </Button>
        <Button id="cancel-alert-config" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
      </CardActions>
    </>
  );
};

export const defaultHyperglycemiaAlertParameters: HyperglycemiaAlertParameters =
  {
    n_days: 7,
    max_number_of_hypers: 1,
    type: 2,
  };

export const defaultHypoglycemiaAlertParameters: HypoglycemiaAlertParameters = {
  n_days: 7,
  max_number_of_hypos: 1,
  type: 2,
};

export const defaultHyperglycemiaTarAlertParameters: HyperglycemiaTarAlertParameters =
  {
    n_days: 7,
    max_tar: 0.1,
    type: 1,
  };

export const defaultHypoglycemiaTbrAlertParameters: HypoglycemiaTbrAlertParameters =
  {
    n_days: 7,
    max_tbr: 0.1,
    type: 1,
  };

const createConfig = (category: AlertCategory): CreateAlertConfig => {
  const base: Omit<CreateAlertConfig, 'parameters'> = {
    category,
    criticity: 'medium',
  };
  switch (category) {
    case 'hyperglycemia':
      return {
        ...base,
        parameters: defaultHyperglycemiaAlertParameters,
      };
    case 'hypoglycemia':
      return {
        ...base,
        parameters: defaultHypoglycemiaAlertParameters,
      };
    case 'hypoglycemia_tbr':
      return {
        ...base,
        parameters: defaultHypoglycemiaTbrAlertParameters,
      };
    case 'hyperglycemia_tar':
      return {
        ...base,
        parameters: defaultHyperglycemiaTarAlertParameters,
      };
    // measurement and transmission should never be created and never deleted either
    case 'measurement':
    case 'transmission':
    case 'technical_alert':
    case 'severe_hypoglycemia':
    case 'remove_consent':
      return {
        ...base,
        parameters: {},
      };
  }
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin: ${theme.spacing(16)};
  `,
});
