import React from 'react';

import { css, cx } from '@emotion/css';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { Contact } from '@/models/ContactModel';
import { relationships } from '@/pages/patient-medical-file/contact-patient/ContactPatientForm';

export type ContactViewProps = {
  contact: Contact;
  setEditing?: (contact: Contact) => void;
  onDelete?: (contact: Contact) => void;
  className?: string;
};

export const ContactView: React.FC<ContactViewProps> = ({
  contact,
  setEditing,
  onDelete,
  className,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  return (
    <div className={cx(styles.container, className)}>
      <CardHeader
        title={`${contact.firstname} ${contact.lastname}`}
        titleTypographyProps={{ variant: 'h6' }}
        className={styles.header}
      />
      <CardContent className={styles.content}>
        <Typography variant="body">
          {t(relationships[contact.relationshipWithPatient ?? 'other'])}
        </Typography>
        {contact.isEmergencyContact ? (
          <Typography variant="body" className={styles.emergency}>
            {t('contact.emergencyContact').slice(0, -1)}
          </Typography>
        ) : null}
        <div className={styles.row}>
          <Icons.phone className={styles.icon} />
          <Typography variant="body">{contact.phone}</Typography>
        </div>
        {contact.email ? (
          <div className={styles.row}>
            <Icons.email className={styles.icon} />
            <Typography variant="body">{contact.email}</Typography>
          </div>
        ) : null}
        {contact.comment ? (
          <Typography variant="body" className={styles.comment}>
            {contact.comment}
          </Typography>
        ) : null}
      </CardContent>
      <CardActions className={styles.actions}>
        {setEditing ? (
          <Button
            id="edit-contact"
            variant="outlined"
            onClick={() => setEditing(contact)}
          >
            <Icons.edit className={styles.iconAction} />
          </Button>
        ) : null}
        {onDelete ? (
          // TODO: Add a confirmation dialog before deleting the contact
          // Find a way to pop ephemeral dialogs
          <Button
            id="delete-contact"
            variant="outlined"
            onClick={() => onDelete(contact)}
          >
            <Icons.trash className={styles.iconAction} />
          </Button>
        ) : null}
      </CardActions>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `,
  icon: css`
    width: ${FontSizes.body};
    height: ${FontSizes.body};
  `,
  iconAction: css`
    width: ${FontSizes.bodyLarge};
    height: ${FontSizes.bodyLarge};
  `,
  header: css`
    padding: 0;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;
  `,
  actions: css`
    padding: 0;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(8)};
  `,
  emergency: css`
    color: ${theme.palette.warning.main};
    font-weight: bold;
  `,
  comment: css`
    color: ${theme.palette.text.secondary};
    font-style: italic;
  `,
});
