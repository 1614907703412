import { ColorsStructure, MedicalColors } from '@/theme/types';

/**
 * From Figma UIKIT
 * https://www.figma.com/design/ZVp70EdtHVxHi7PAQKj5zQ/Steto---UI-kit?node-id=7-368&t=1VF9HsEvNViaNYEt-0
 * Steto colors
 *
 */
export const uikitColors = {
  primary: {
    200: '#CFE0FF', // light
    500: '#9CBAFF', // main
    800: '#1D2B6D', // dark
    900: '#0A0F2D', // text
  },
  accent: {
    300: '#D9FFF1', // light
    500: '#58F2BB', // main
    700: '#128C86', // dark
    900: '#026C66', // text
  },
  neutral: {
    50: '#f7f8fb', // background
    100: '#EEEFF5', // background
    200: '#DFE0EC', // background
    300: '#D0D0DD', // border & divider
    400: '#828792', // icon & disabled
    500: '#727480', // text
    600: '#636873', // text
    800: '#434B5B', // text
    900: '#1E1E32',
  },
  success: {
    300: '#b3f2db', // contrastText
    500: '#00d185', // light
    700: '#00b271', // main
    900: '#008c5a', // dark
  },
  danger: {
    300: '#ffdbde', // contrastText
    500: '#ffbbbe', // light
    700: '#ff1d25', // main
    900: '#bf1015', // dark
  },
  info: {
    300: '#CFE0FF', // contrastText
    500: '#9CBAFF', // light
    700: '#1D2B6D', // main
    900: '#0A0F2D', // dark
  },
  warning: {
    300: '#ffe6bb', // contrastText
    500: '#ffa91d', // light
    700: '#d88f18', // main
    900: '#4A1A00', // dark
  },

  production: '#1D2B6D',
  development: '#1D6D2B',
  test: '#6D1D2B',

  black: '#000',
  white: '#FFF',
  ivory: '#FFFFF0',

  pink: {
    300: '#FFD6DD', // contrastText
    500: '#FF96A9', // light
    700: '#A60264', // main
    900: '#860050', // dark
  },
  violet: {
    300: '#DCD6FF', // contrastText
    500: '#B1A4FF', // light
    700: '#5029BE', // main
    900: '#2A0D5E', // dark
  },
  green: {
    300: '#D9FFF1', // contrastText
    500: '#58F2BB', // light
    700: '#026C66', // main
    900: '#013733', // dark
  },
  red: {
    300: '#FBEAEA', // contrastText
    500: '#E17070', // light
    700: '#A30000', // main
    900: '#5C0000', // dark
  },
  blue: {
    300: '#CFE0FF', // contrastText
    500: '#9CBAFF', // light
    700: '#1D2B6D', // main
    900: '#0A0F2D', // dark
  },
  orange: {
    100: '#FFF3E0',
    300: '#FFE0B1', // contrastText
    500: '#FFB043', // light
    600: '#923400', // main
    900: '#4A1A00', // dark
  },
  purple: {
    300: '#EDEAFF', // light
    500: '#C3B4FF', // main
    600: '#7A61CC', // dark
    900: '#3B206A', // contrastText
  },
  yellow: {
    300: '#FFF3E0', // contrastText
    500: '#FFD700', // light
    700: '#EB9728',
    900: '#B66900',
  },
} satisfies ColorsStructure;

/**
 * Medical colors
 * Should never change
 */
export const medicalColors: MedicalColors = {
  insulin: {
    basal: {
      300: '#CFE0FF',
      500: '#9CBAFF',
      700: '#4D6BBD',
      900: '#1D2B6D',
    },
    bolus: {
      300: '#FFD6DD',
      500: '#FF96A9',
      700: '#BF405B',
      900: '#A60264',
    },
  },
  glycemia: {
    severeHyperglycemia: {
      300: '#ffd6c4',
      500: '#ff7538',
      700: '#d96330',
      900: '#a63a1f',
    },
    hyperglycemia: {
      300: '#ffe6bb',
      500: '#ffc96d',
      700: '#d88f18',
      900: '#4A1A00',
    },
    normal: {
      300: '#b3f2db',
      500: '#00d185',
      700: '#00b271',
      900: '#008c5a',
    },
    hypoglycemia: {
      300: '#ffbbbe',
      500: '#ff1d25',
      700: '#ff1d25',
      900: '#bf1015',
    },
    severeHypoglycemia: {
      300: '#d0b3c6',
      500: '#600040',
      700: '#600040',
      900: '#40002b',
    },
  },
  agp: {
    p90: '#4a1dff21',
    p50: '#4a1dff54',
    median: '#4a1dff',
  },
  bgm: {
    header: '#DCD6FF',
    clearColumn: '#FAF9FF',
    darkColumn: '#F3F1FF',
    redBackground: '#FFB4B6',
    purpleText: '#5029be',
    activityIcon: '#FFA91B',
    orangeBackground: '#FFE5BE',
    orangeDarkBackground: '#FFC8B1',
    greenBackground: '#ACEED7',
    purpleBackground: '#955580',
  },
};

export const Colors = {
  ...uikitColors,
  ...medicalColors,
};
