import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { ApiErrorData } from '@/models/ApiErrorData.ts';

export type ShowApiErrorProps = {
  errorMapping: Record<string, string>;
  className?: string;
  error?: ApiErrorData;
};

export const ShowApiError: React.FC<ShowApiErrorProps> = ({
  className,
  errorMapping,
  error,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const errorTranslationData =
    error?.data && typeof error.data === 'object'
      ? (error.data as Record<string, unknown>)
      : {};

  const text = useMemo(() => {
    if (error?.error && error.error in errorMapping) {
      return errorMapping[error.error];
    } else if (error?.error === undefined) {
      return undefined;
    } else {
      return 'errors.unknownError';
    }
  }, [error, errorMapping]);

  return (
    <div className={cx(className, styles.container)}>
      {text ? (
        <Typography variant="body" className={styles.error}>
          {t(text, errorTranslationData)}
        </Typography>
      ) : null}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    height: 2.5em;
  `,
  error: css`
    color: ${theme.palette.error.main};
  `,
});
