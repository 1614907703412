import { StringSchema, addMethod, string } from 'yup';

import { toDateTime } from '@/utils/dateUtils.ts';

declare module 'yup' {
  interface StringSchema {
    date(message: string): this;
    minDate(minDate: Date, message: string): this;
    maxDate(maxDate: Date, message: string): this;
  }
}

function date(this: StringSchema, message: string) {
  return this.test('isDate', message, a => !!a && toDateTime(a).isValid);
}

addMethod(string, 'date', date);

function minDate(this: StringSchema, minDate: Date, message: string) {
  return this.test(
    'isAtLeastDate',
    message,
    a => !!a && toDateTime(a) >= toDateTime(minDate),
  );
}

addMethod(string, 'minDate', minDate);

function maxDate(this: StringSchema, maxDate: Date, message: string) {
  return this.test(
    'isAtMostDate',
    message,
    a => !!a && toDateTime(a) <= toDateTime(maxDate),
  );
}

addMethod(string, 'maxDate', maxDate);
