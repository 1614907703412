import React from 'react';

import { css } from '@emotion/css';
import { Button, Typography } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { LoadingScreen } from '@/components/loading/LoadingScreen';
import { useKeycloak } from '@/keycloak';
import PostHogPageView from '@/observation_of_users/TrackPageViews';
import { Queries } from '@/queries/Queries';
import { PatientRouter } from '@/router/PatientRouter';
import { PractitionerRouter } from '@/router/PractitionerRouter';

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes />
      <PostHogPageView />
    </BrowserRouter>
  );
};

const Routes: React.FC = () => {
  const keycloak = useKeycloak();
  const me = Queries.user.useMe({});
  const posthog = usePostHog();
  const { t } = useTranslation();

  if (me.data && !keycloak.impersonating) {
    posthog?.identify(me.data?.id, {
      email: me.data.email,
    });
  }

  switch (me.status) {
    case 'pending':
      return <LoadingScreen />;
    case 'success': {
      switch (me.data.userType) {
        case 'practitioner':
          return <PractitionerRouter me={me.data} />;
        case 'patient':
          return <PatientRouter />;
        case 'admin':
          return (
            <div
              className={css`
                display: flex;
                flex-direction: column;
              `}
              id="admin-page"
            >
              <Typography id="admin-title" variant="h4">
                {t('admin.title')}
              </Typography>
              <Button
                id="admin-logout"
                variant="contained"
                onClick={() =>
                  keycloak.logout({
                    redirectUri: window.origin,
                  })
                }
              >
                {t('admin.logout')}
              </Button>
            </div>
          );
      }
    }
    case 'error': {
      if (me.error?.data.statusCode === 401) {
        keycloak.logout({ redirectUri: window.origin });
      }
      return <LoadingScreen />;
    }
  }
};
