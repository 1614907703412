import { DecoderFunction, field, number } from 'typescript-json-decoder';

import { recordWithContext } from '@/utils/decoderUtils.ts';

export type InsulinInjectionMethod = 'pump' | 'pen';

export type InsulinIndicatorsResponse = {
  averageNumberOfInjection: number;
  averageQuantityInjected: {
    bolus: number;
    basal: number;
  };
};

export const insulinIndicatorsDecoder = (
  value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
): InsulinIndicatorsResponse => {
  if (value['detail'] == 'no data available') {
    return {
      averageNumberOfInjection: 0,
      averageQuantityInjected: {
        bolus: 0,
        basal: 0,
      },
    };
  }

  return {
    averageNumberOfInjection: value['average_number_of_injections'],
    averageQuantityInjected: {
      bolus: value['average_quantity_injected']['bolus'],
      basal: value['average_quantity_injected']['basal'],
    },
  };
};

export type InsulinContinuousIndicators = {
  averageNumberOfBolusInjection: number;
  averageBasalQuantityInjected: number;
  averageBolusQuantityInjected: number;
};

export const insulinContinuousIndicatorDecoder: DecoderFunction<InsulinContinuousIndicators> =
  recordWithContext('InsulinContinuousIndicators', {
    averageNumberOfBolusInjection: field(
      'average_number_of_bolus_injection',
      number,
    ),
    averageBasalQuantityInjected: field(
      'average_basal_quantity_injected',
      number,
    ),
    averageBolusQuantityInjected: field(
      'average_bolus_quantity_injected',
      number,
    ),
  });
