import {
  DecoderFunction,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import { DeviceType } from '@/models/MedicalDeviceModel.ts';
import {
  decodeId,
  intersectionWithContext,
  nullOrUndef,
  stringUnion,
  stringUnionMapUndef,
} from '@/utils/decoderUtils.ts';

export type FoodSize = 'light' | 'medium' | 'big';
export type Meal = 'breakfast' | 'lunch' | 'dinner' | 'snack';

export type Food = {
  id: number;
  patientId: string;
  date: string;
  deviceId?: string;
  deviceType?: DeviceType;

  size?: FoodSize;
  carbs?: number;
  comments?: string;
  meal?: Meal;
};

export const foodDecoder: DecoderFunction<Food> = intersectionWithContext(
  'Food',
  {
    id: number,
    patientId: field('patient_id', string),
    date: string,
    deviceId: field('device_id', nullOrUndef(decodeId)),
    deviceType: field(
      'device_type',
      nullOrUndef(stringUnion('pump', 'pen', 'reader', 'sensor', 'manual')),
    ),
    size: stringUnionMapUndef<FoodSize>('unknown', 'light', 'big', 'medium'),
    carbs: nullOrUndef(number),
    comments: nullOrUndef(string),
    meal: stringUnionMapUndef<Meal>(
      'unknown',
      'breakfast',
      'lunch',
      'dinner',
      'snack',
    ),
  },
);

export const getFoodMealTranslation = (meal: Meal | undefined): string => {
  switch (meal) {
    case 'breakfast':
      return 'models.meal.breakfast';
    case 'lunch':
      return 'models.meal.lunch';
    case 'dinner':
      return 'models.meal.dinner';
    case 'snack':
      return 'models.meal.snack';
    default:
      return 'models.meal.unknown';
  }
};

export const getFoodSizeTranslation = (size: FoodSize | undefined): string => {
  switch (size) {
    case 'light':
      return 'models.mealSize.light';
    case 'medium':
      return 'models.mealSize.medium';
    case 'big':
      return 'models.mealSize.heavy';
    default:
      return 'models.mealSize.unknown';
  }
};
