import { css } from '@emotion/css';
import { InputLabel, Stack, Theme, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';

import { ChipSelect } from '../atoms/ChipSelect';

type ChipChoice = {
  displayName: string;
  value: string;
};

type ChipSelectorProps = {
  id?: string;
  fieldName: string;
  choices: ChipChoice[];
  label?: string;
};

export const ChipSelector = ({
  id,
  fieldName,
  choices,
  label,
}: ChipSelectorProps) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Controller
      name={fieldName}
      rules={{ required: t('form.required') }}
      render={({ fieldState, field }) => {
        const error = fieldState.error;
        const errorMessage =
          typeof error?.message === 'string' ? error.message : '';

        return (
          <>
            <InputLabel>
              <Typography variant="subtitle">{label}</Typography>
            </InputLabel>
            <Stack
              id={id}
              direction="row"
              spacing={20}
              className={styles.inputPadding}
            >
              {choices.map((choice: ChipChoice) => (
                <ChipSelect
                  id={`${id}-${choice.value}`}
                  clickable={true}
                  selected={field.value === choice.value}
                  key={choice.value}
                  label={choice.displayName}
                  onClick={() => field.onChange(choice.value)}
                />
              ))}
            </Stack>
            {error ? (
              <Typography color="error">{errorMessage}</Typography>
            ) : null}
          </>
        );
      }}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  inputPadding: css`
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};
  `,
});
