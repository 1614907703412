import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import {
  Practitioner,
  UpdatePractitioner,
  practitionerDecoder,
} from '@/models/PractitionerModel';

/**
 * For health pro only
 */
export class PractitionerRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getPractitioner = (): Promise<Practitioner> => {
    return this.datasource.get('/practitioners/me/', practitionerDecoder);
  };

  updatePractitioner = (data: UpdatePractitioner): Promise<Practitioner> => {
    return this.datasource.patch(
      '/practitioners/me/',
      practitionerDecoder,
      data,
    );
  };
}
