import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Link, matchPath } from 'react-router-dom';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { MenuActionTooltip } from '@/components/floating/MenuTooltip';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { useWindowSize } from '@/hooks/useWindowSize';
import { isProd } from '@/utils/commonUtils.ts';

const MenuItems = [
  {
    id: 'manage-patient-menu-monitoring',
    paths: ['/patients/:id'],
    title: 'patientNavigation.monitoring',
    link: (id: string | undefined) => `/patients/${id}`,
  },
  {
    id: 'manage-patient-menu-settings',
    paths: ['/patients/:id/settings'],
    title: 'patientNavigation.settings',
    link: (id: string | undefined) => `/patients/${id}/settings`,
  },
  {
    id: 'manage-patient-menu-medical-file',
    paths: ['/patients/:id/medical-file'],
    title: 'patientNavigation.medicalFile',
    link: (id: string | undefined) => `/patients/${id}/medical-file`,
  },
  ...(!isProd()
    ? [
        {
          id: 'manage-patient-menu-patient-management',
          paths: ['/patients/:id/patient-management'],
          title: 'patientNavigation.dataManagement',
          link: (id: string | undefined) =>
            `/patients/${id}/patient-management`,
        },
      ]
    : []),
];

export const PatientMenu: React.FC = () => {
  const { screenSize } = useWindowSize();

  switch (screenSize) {
    case 'SM':
      return <PatientMenuSmall />;
    case 'MD':
    case 'LG':
    case 'XL':
    case 'XXL':
      return <PatientMenuFull />;
  }
};

const getCurrentItem = (pathname: string) =>
  MenuItems.find(item => isSelected(pathname, item.paths));

const PatientMenuSmall: React.FC = () => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const selectedItem = getCurrentItem(pathname);

  return (
    <div className={styles.menuSmall}>
      {selectedItem ? (
        <MenuItem
          id={selectedItem.id}
          selected={true}
          link={selectedItem.link(id)}
          title={t(selectedItem.title)}
        />
      ) : null}
      <MenuActionTooltip
        placement="bottom-start"
        actions={MenuItems.map(item =>
          item.title !== selectedItem?.title ? (
            <SmallMenuItem
              id={item.id}
              key={item.title}
              title={t(item.title)}
              link={item.link(id)}
            />
          ) : null,
        )}
      >
        <Icons.hamburger className={styles.icon} />
      </MenuActionTooltip>
    </div>
  );
};

const PatientMenuFull: React.FC = () => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      {MenuItems.map(item => (
        <MenuItem
          id={item.id}
          key={item.title}
          selected={isSelected(pathname, item.paths)}
          title={t(item.title)}
          link={item.link(id)}
        />
      ))}
    </div>
  );
};

type MenuItemProps = {
  id: string;
  title: string;
  link: string;
  selected: boolean;
};
const MenuItem: React.FC<MenuItemProps> = ({ id, title, link, selected }) => {
  const styles = useStyles(makeMenuItemStyles, selected);
  const navigate = useNavigate();

  return (
    <div id={id} className={styles.menuItem} onClick={() => navigate(link)}>
      <div className={styles.menuItemLink}>
        <Typography variant="subtitle" className={styles.text}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

type SmallMenuItemProps = {
  id: string;
  title: string;
  link: string;
};

const SmallMenuItem: React.FC<SmallMenuItemProps> = ({ id, title, link }) => {
  const styles = useStyles(makeMenuItemStyles, false);

  return (
    <Link id={id} to={link}>
      <Typography variant="body" className={styles.text}>
        {title}
      </Typography>
    </Link>
  );
};

const isSelected = (pathname: string, paths: readonly string[]) => {
  return paths.some(path => matchPath(path, pathname) !== null);
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
  `,
  tooltipAction: css`
    display: flex;
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.background.default};
    }
  `,
  menuSmall: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  icon: css`
    margin-left: ${theme.spacing(8)};
    width: ${FontSizes.body};
    height: ${FontSizes.body};
    color: ${theme.palette.text.primary};
  `,
});

const makeMenuItemStyles = (theme: Theme, selected: boolean) => ({
  menuItem: css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: ${theme.spacing(2)} ${theme.spacing(2)} 0 0;

    &:hover {
      background-color: ${theme.palette.background.default};
      a {
        border-bottom: ${!selected
          ? `${theme.palette.background.paper} ${theme.spacing(1)} solid`
          : ''};
      }
    }
  `,
  menuItemLink: css`
    flex-grow: 1;
    margin: ${theme.spacing(3)} ${theme.spacing(8)} 0;
    border-bottom: ${selected
      ? `${theme.palette.primary.main} ${theme.spacing(1)} solid`
      : ''};
  `,
  text: css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: clip;
    font-weight: 600;
    color: ${selected ? theme.palette.primary.main : ''};
  `,
});
