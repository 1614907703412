import {
  DecoderFunction,
  boolean,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import { DeviceType } from '@/models/MedicalDeviceModel.ts';
import {
  decodeId,
  deepField,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils.ts';

/***********************
 *    Insulin types    *
 ***********************/

export type InsulinType = 'long' | 'short';
export type InsulinReason = 'bolus' | 'basal';
export type InsulinPeriod = 'breakfast' | 'lunch' | 'dinner';
export type InsulinMethod = 'pen' | 'pump';

export type Insulin = {
  id: number;
  patientId: string;
  date: string;
  deviceId?: string;
  deviceType?: DeviceType;

  quantity: number;
  type: InsulinType;
  period: InsulinPeriod;
  reason: InsulinReason;
  method: InsulinMethod;
  correction: boolean;
  iob?: number;
  carbs?: number;
  carbsRatio?: number;
};

export type DiscreteBasalInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'long';
  reason: 'basal';
};

export type ContinuousBasalInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'short';
  reason: 'basal';
};

export type BolusInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'short';
  reason: 'bolus';
};

export type SplitInsulinData = {
  continuous: ContinuousBasalInsulin[];
  discrete: DiscreteBasalInsulin[];
  bolus: BolusInsulin[];
};

/***********************
 *   Insulin decoders  *
 ***********************/

export const insulinDecoder: DecoderFunction<Insulin> = recordWithContext(
  'Insulin',
  {
    id: number,
    patientId: field('patient_id', string),
    date: string,
    deviceId: field('device_id', nullOrUndef(decodeId)),
    deviceType: field(
      'device_kind',
      nullOrUndef(stringUnion('pump', 'pen', 'reader', 'sensor', 'manual')),
    ),
    quantity: number,
    type: stringUnion<InsulinType>('long', 'short'),
    reason: stringUnion<InsulinReason>('bolus', 'basal'),
    period: stringUnion<InsulinPeriod>('breakfast', 'lunch', 'dinner'),
    method: stringUnion<InsulinMethod>('pen', 'pump'),
    correction: boolean,
    iob: deepField('bolus_settings.iob', nullOrUndef(number)),
    carbs: deepField('bolus_settings.carbs', nullOrUndef(number)),
    carbsRatio: deepField('bolus_settings.carbs_ratio', nullOrUndef(number)),
  },
);

export const getInsulinTypeTranslation = (type: InsulinType): string => {
  switch (type) {
    case 'long':
      return 'models.insulinType.slow';
    case 'short':
      return 'models.insulinType.fast';
  }
};

export const getInsulinReasonTranslation = (reason: InsulinReason): string => {
  switch (reason) {
    case 'bolus':
      return 'models.insulinReason.bolus';
    case 'basal':
      return 'models.insulinReason.basal';
  }
};
