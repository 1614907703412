import {
  DecoderFunction,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import { DeviceType } from '@/models/MedicalDeviceModel.ts';
import {
  decodeId,
  intersectionWithContext,
  nullOrUndef,
  stringUnion,
} from '@/utils/decoderUtils.ts';

export type ReportCategory =
  | 'severe_hypoglycemia'
  | 'perceived_hypoglycemia'
  | 'perceived_hyperglycemia'
  | 'tech_alert'
  | 'other';

export type Report = {
  id: number;
  patientId: string;
  date: string;
  deviceId?: string;
  deviceType?: DeviceType;

  category: ReportCategory;
  message?: string;
};

export const reportDecoder: DecoderFunction<Report> = intersectionWithContext(
  'report',
  {
    id: number,
    patientId: field('patient_id', string),
    date: string,
    deviceId: field('device_id', nullOrUndef(decodeId)),
    deviceType: field(
      'device_type',
      nullOrUndef(stringUnion('pump', 'pen', 'reader', 'sensor', 'manual')),
    ),
    category: stringUnion<ReportCategory>(
      'severe_hypoglycemia',
      'perceived_hypoglycemia',
      'perceived_hyperglycemia',
      'tech_alert',
      'other',
    ),
    message: field('message', nullOrUndef(string)),
  },
);

export const getReportCategoryTranslation = (
  category: ReportCategory,
): string => {
  switch (category) {
    case 'severe_hypoglycemia':
      return 'models.reportCategory.severeHypoglycemia';
    case 'perceived_hypoglycemia':
      return 'models.reportCategory.perceivedHypoglycemia';
    case 'perceived_hyperglycemia':
      return 'models.reportCategory.perceivedHyperglycemia';
    case 'tech_alert':
      return 'models.reportCategory.techAlert';
    case 'other':
      return 'models.reportCategory.other';
  }
};
