import React from 'react';

import { css } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Images } from '@/assets/images';
import { useStyles } from '@/hooks/useStyles';

export const AboutSteto: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card id="about-steto-card" className={styles.container} elevation={0}>
      <CardHeader id="about-title" title={t('pages.account.about.title')} />
      <CardContent className={styles.body}>
        <div className={styles.content}>
          <Typography id="about-description" variant="body">
            {t('pages.account.about.description')}
          </Typography>
          <div className={styles.imagesRow}>
            <Images.stetoInfo
              id="steto-info"
              className={styles.image}
              alt="steto-info"
            />
            <div className={styles.subContent}>
              <Typography id="about-name" variant="body">
                {t('pages.account.about.name')}
              </Typography>
              <Typography id="about-address" variant="body">
                {t('pages.account.about.address')}
              </Typography>
              <Typography id="about-postCode" variant="body">
                {t('pages.account.about.postCode')}
              </Typography>
              <Typography id="about-rcs" variant="body">
                {t('pages.account.about.rcs')}
              </Typography>
              <Typography id="about-class" variant="body">
                {t('pages.account.about.class')}
              </Typography>
              <Icons.ce id="about-ce-logo" className={styles.icon} />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  body: css`
    align-items: center;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  imagesRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: ${theme.spacing(8)} 0;
  `,
  subContent: css`
    display: flex;
    flex-direction: column;
    width: min(300px, 45%);
    align-items: center;
  `,
  image: css`
    width: min(300px, 45%);
  `,
  icon: css`
    height: 3em;
    margin-top: ${theme.spacing(8)};
  `,
});
