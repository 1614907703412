import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { useQueryString } from '@/hooks/query/useQueryString.ts';
import { toDateTime } from '@/utils/dateUtils.ts';

export type QueryDateRange = {
  from: DateTime<true>;
  to: DateTime<true>;
};

export const useQueryDateRange = (): QueryDateRange | undefined => {
  const { from, to } = useQueryString();

  return useMemo(() => {
    if (from && to) {
      const fromDate = toDateTime(from);
      const toDate = toDateTime(to);
      if (fromDate.isValid && toDate.isValid) {
        return { from: fromDate, to: toDate };
      }
    }

    return undefined;
  }, [from, to]);
};
