import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { Group } from '@visx/group';

import { IconType } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles';
import { ThemeColor } from '@/theme/types.ts';

export type SvgCircledIconProps = {
  Icon: IconType;
  circleColor: ThemeColor;
  iconColor: ThemeColor;
  width: number;
  height: number;
  left?: number;
  top?: number;
  iconToCircleRatio?: number;
  className?: string;
  classes?: {
    root?: string;
    circle?: string;
    icon?: string;
  };
};

export const SvgCircledIcon: React.FC<SvgCircledIconProps> = ({
  Icon,
  circleColor,
  iconColor,
  className,
  classes,
  left = 0,
  top = 0,
  iconToCircleRatio = 0.7,
  width,
  height,
}) => {
  const styles = useStyles(makeStyles, circleColor, iconColor);
  const iconWidth = width * iconToCircleRatio;
  const iconHeight = height * iconToCircleRatio;

  return (
    <Group
      left={left}
      top={top}
      className={cx(styles.container, className, classes?.root)}
    >
      <ellipse
        cx={width / 2}
        cy={height / 2}
        rx={width / 2}
        ry={height / 2}
        className={cx(styles.circle, classes?.circle)}
      />
      <Icon
        className={cx(styles.icon, classes?.icon)}
        x={width / 2 - iconWidth / 2}
        y={height / 2 - iconHeight / 2}
        width={iconWidth}
        height={iconHeight}
      />
    </Group>
  );
};

const makeStyles = (
  _theme: Theme,
  circleColor: ThemeColor,
  iconColor: ThemeColor,
) => ({
  container: css``,
  circle: css({
    fill: circleColor,
  }),
  icon: css({
    color: iconColor,
  }),
});
