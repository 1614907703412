import { ChatRepository } from '@/io/repository/ChatRepository';
import {
  ChatFilterParametersType,
  CreateMessage,
  UpdateMessage,
} from '@/models/ChatModel';
import { PractitionerQueries } from '@/queries/PractitionerQueries';
import { makeQueryKey } from '@/queries/Queries';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';

type ChatRequest = { subjectId: string; visibility: ChatFilterParametersType };

export class ChatQueries {
  constructor(
    private readonly chat: ChatRepository = new ChatRepository(),
    private readonly practitionerQueries: PractitionerQueries = new PractitionerQueries(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useChat = createQuery(
    'chat',
    ({ subjectId, visibility }) => makeQueryKey(subjectId, visibility),
    async ({ subjectId, visibility }: ChatRequest) =>
      this.chat.getMessages(subjectId, visibility),
    {
      onSuccess: (_, { subjectId }, { queryClient }) => {
        this.practitionerQueries.usePatientsList.invalidate(queryClient, {});
        this.practitionerQueries.useGetPatientMessageCount.invalidate(
          queryClient,
          subjectId,
        );
      },
    },
  );

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useCreateMessage = createMutation(
    'create-message',
    async ({ patientId, ...data }: { patientId: string } & CreateMessage) =>
      this.chat.createMessage(patientId, data),
    {
      onSuccess: (_, { patientId }, { queryClient }) => {
        this.useChat.invalidate(queryClient, {
          subjectId: patientId,
        });
      },
    },
  );

  useUpdateMessage = createMutation(
    'update-message',
    async ({
      patientId,
      messageId,
      ...data
    }: { patientId: string; messageId: string } & UpdateMessage) =>
      this.chat.updateMessage(patientId, messageId, data),
    {
      onSuccess: (_, { patientId }, { queryClient }) => {
        this.useChat.invalidate(queryClient, {
          subjectId: patientId,
        });
      },
    },
  );
}
