import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/loading/Loader';
import { SearchBar } from '@/components/search-bar/SearchBar.tsx';
import { usePatientsListData } from '@/hooks/usePatientsListData.ts';
import { useStyles } from '@/hooks/useStyles';
import { PractitionerUser } from '@/models/UserModel.ts';
import {
  PatientListMenu,
  PatientListMenuItems,
} from '@/pages/patients/patients-list/PatientListMenu.tsx';
import { PatientListTable } from '@/pages/patients/patients-list/PatientListTable.tsx';
import { Queries } from '@/queries/Queries.ts';

export type PatientListCardProps = {
  me: PractitionerUser;
  className?: string;
};

export const PatientListCardContent: React.FC<PatientListCardProps> = ({
  me,
  className,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const [search, setSearch] = React.useState<string>('');
  const patientsListData = usePatientsListData(search);
  const selectedTab = Queries.user.usePatientListUISettings(
    {
      userId: me.id,
    },
    {
      select: (data): PatientListMenuItems =>
        data?.selectedFilter ?? 'monitored',
    },
  );
  const setSelectedTab = Queries.user.useUpdatePatientListUISettings();

  if (!selectedTab.isSuccess) {
    return <Loader size="L" />;
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.header}>
        <PatientListMenu
          selectItem={(value: PatientListMenuItems) =>
            setSelectedTab.mutate({
              userId: me.id,
              data: { selectedFilter: value },
            })
          }
          selectedItem={selectedTab.data}
          patientListData={patientsListData}
        />
        <SearchBar
          id="search-in-patient-list"
          value={search}
          onChange={value => {
            setSearch(value);
            if (value) {
              setSelectedTab.mutate({
                userId: me.id,
                data: { selectedFilter: 'all' },
              });
            }
          }}
          placeholder={t('search.searchPatient')}
        />
      </div>
      {patientsListData.status === 'pending' && (
        <div className={styles.loader}>
          <Loader size="L" />
        </div>
      )}
      {patientsListData.status === 'success' && (
        <PatientListTable
          selectedItem={selectedTab.data}
          patientListData={patientsListData}
          me={me}
        />
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
    overflow: hidden;
  `,
  loader: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
});
