import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useNavigate } from 'react-router';
import { To } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { TextTooltip } from '@/components/floating/Tooltip';
import { useStyles } from '@/hooks/useStyles';

export type NavItemProps = {
  id: string;
  isCurrent?: boolean;
  to: To;
  tooltip?: string;
};

export const NavItem: React.FC<NavItemProps> = ({
  id,
  tooltip,
  to,
  isCurrent = false,
}) => {
  const styles = useStyles(makeStyles, isCurrent);
  const navigate = useNavigate();
  return (
    <div id={id} onClick={() => navigate(to)}>
      <TextTooltip text={tooltip} placement="right">
        <div className={styles.container}>
          <Icons.users className={styles.icon} />
        </div>
      </TextTooltip>
    </div>
  );
};

const makeStyles = (theme: Theme, isCurrent: boolean) => ({
  container: css`
    display: flex;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    margin: ${theme.spacing(4)} 0;
    padding: calc(${theme.spacing(12)} / 2);
    border-radius: ${theme.spacing(8)};
    background-color: ${isCurrent
      ? theme.palette.primary.main
      : theme.palette.common.white};
  `,
  icon: css`
    cursor: pointer;
    fill: ${isCurrent
      ? theme.palette.common.white
      : theme.palette.primary.main};

    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
  `,
});
