import React from 'react';

import { css, cx } from '@emotion/css';
import { Group } from '@visx/group';

import { useStyles } from '@/hooks/useStyles.ts';
import { Activity } from '@/models/diabetes/ActivityModel.ts';
import { Food } from '@/models/diabetes/FoodModel.ts';
import { Report } from '@/models/diabetes/ReportModel.ts';
import { AdditionalDiabetesInfoPoints } from '@/uiKit/molecules/graphs/AdditionalDiabetesInfoGraph/AdditionalDiabetesInfoPoints.tsx';
import { AdditionalDiabetesInfo } from '@/uiKit/molecules/graphs/AdditionalDiabetesInfoGraph/AdditionalDiabetesInfoUtils.ts';
import { DateUtils } from '@/utils/dateUtils.ts';
import { SvgLayout, SvgLegend } from '@/utils/graphUtils.ts';

export type AdditionalDiabetesInfoGraphProps = {
  date: string;
  activity: Activity[];
  food: Food[];
  reports: Report[];
  legend?: SvgLegend;
  className?: string;
} & SvgLayout;

type AdditionalDiabetesInfoGraphLayout = {
  pointsArea: SvgLayout;
};

export const AdditionalDiabetesInfoGraph: React.FC<
  AdditionalDiabetesInfoGraphProps
> = ({ date, activity, food, reports, legend, className, ...dim }) => {
  const styles = useStyles(makeStyles);
  const layout = getLayout(dim, legend);
  const data = mergeData(activity, food, reports);
  return (
    <Group className={cx(styles.container, className)} {...dim}>
      <AdditionalDiabetesInfoPoints
        date={date}
        items={data}
        {...layout.pointsArea}
      />
    </Group>
  );
};

const getLayout = (
  dim: SvgLayout,
  legend: SvgLegend | undefined,
): AdditionalDiabetesInfoGraphLayout => {
  const left = legend ? legend.legendWidth : 0;
  const width = legend ? dim.width - legend.legendWidth : dim.width;
  return {
    pointsArea: {
      top: 0,
      left,
      width,
      height: dim.height,
    },
  };
};

const mergeData = (
  activity: Activity[],
  food: Food[],
  reports: Report[],
): AdditionalDiabetesInfo[] => {
  const wrappedActivity: AdditionalDiabetesInfo[] = activity.map(activity => ({
    type: 'activity',
    data: activity,
  }));
  const wrappedFood: AdditionalDiabetesInfo[] = food.map(food => ({
    type: 'food',
    data: food,
  }));
  const wrappedReports: AdditionalDiabetesInfo[] = reports.map(report => ({
    type: 'report',
    data: report,
  }));

  const merged = [...wrappedActivity, ...wrappedFood, ...wrappedReports];
  return merged.sort((a, b) =>
    DateUtils.compareDateTimes(a.data.date, b.data.date),
  );
};

const makeStyles = () => ({
  container: css``,
});
