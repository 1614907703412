import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles.ts';
import {
  MessageNotificationsCount,
  PatientListItem,
} from '@/models/PatientSummaryModel.ts';
import { Tag } from '@/uiKit/atoms/Tag.tsx';

export type MessagesColumnProps = CellContext<
  PatientListItem,
  MessageNotificationsCount
>;

export const NurseMessagesColumn: React.FC<MessagesColumnProps> = ({
  getValue,
}) => {
  const styles = useStyles(makeStyles);
  const messages = getValue();
  const count =
    messages.fromPatient +
    messages.fromPractitionerToAll +
    messages.fromPractitionerToPractitioner;

  if (count <= 0) {
    return null;
  }

  const onlyHasPatientMessages = count !== messages.fromPatient;

  if (onlyHasPatientMessages) {
    return (
      <div className={styles.container}>
        <Tag
          color="blue"
          label={
            <div className={styles.messageTagIcon}>
              <Icons.letter className={styles.icon} />
              <Typography variant="body">{count}</Typography>
            </div>
          }
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <Tag
        color="blue"
        label={
          <div className={styles.messageTagIcon}>
            <Icons.letter className={styles.icon} />
            <Icons.stethoscope className={styles.icon} />
            <Typography variant="body">{count}</Typography>
          </div>
        }
      />
    </div>
  );
};

export const DoctorMessagesColumn: React.FC<MessagesColumnProps> = ({
  getValue,
}) => {
  const styles = useStyles(makeStyles);
  const messages = getValue();
  const count =
    messages.fromPractitionerToPractitioner + messages.fromGestationalPatient;

  if (count <= 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Tag
        color="blue"
        label={
          <div className={styles.messageTagIcon}>
            <Icons.letter className={styles.icon} />
            <Typography variant="body">{count}</Typography>
          </div>
        }
      />
    </div>
  );
};

export const MessagesHeader: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeHeaderStyles);

  return (
    <div className={styles.container}>
      <Typography>{t('pages.patients.messages')}</Typography>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
  messageTagIcon: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
  icon: css`
    width: ${theme.typography.body.fontSize};
    height: ${theme.typography.body.fontSize};
  `,
});

const makeHeaderStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
});
