import { QaRepository } from '@/io/repository/QaRepository';

import { createMutation } from './utils/CreateMutation';

export class QaQueries {
  constructor(private readonly qa: QaRepository = new QaRepository()) {}

  useExportGlycemiaCSV = createMutation(
    'export-glycemia-csv',
    async ({ patientId }: { patientId: string }) =>
      this.qa.downloadGlycemiaCSV(patientId),
  );

  useGenerateGlycemiaData = createMutation(
    'generate-glycemia-data',
    async ({
      patientId,
      glycemiaType,
    }: {
      patientId: string;
      glycemiaType?: string;
    }) => this.qa.generateGlycemiaData(patientId, glycemiaType),
  );

  useUploadGlycemiaCSV = createMutation(
    'upload-glycemia-csv',
    async ({ patientId, file }: { patientId: string; file: File }) =>
      this.qa.uploadGlycemiaCSV(patientId, file),
  );

  useDeleteGlycemiaData = createMutation(
    'delete-glycemia-data',
    async ({ patientId }: { patientId: string }) =>
      this.qa.deleteGlycemiaData(patientId),
  );

  useSkipOnboarding = createMutation(
    'skip-onboarding',
    async ({ patientId }: { patientId: string }) =>
      this.qa.skipOnboarding(patientId),
  );
}
