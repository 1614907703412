import { MixedSchema, addMethod, mixed } from 'yup';

import { FileToUpload } from '@/models/FileModel';
import { SupportedMimes } from '@/utils/fileUtils.ts';

declare module 'yup' {
  interface MixedSchema {
    file(message: string): MixedSchema<FileToUpload>;
    fileAccepts(
      message: string,
      accepts: SupportedMimes[],
      nullable: boolean,
    ): this;
  }
}

function file(this: MixedSchema, message: string) {
  return this.test(
    'isFile',
    message,
    (a: unknown) =>
      !!a &&
      typeof a === 'object' &&
      'name' in a &&
      'contentType' in a &&
      'size' in a &&
      typeof a.name === 'string' &&
      typeof a.size === 'number' &&
      a.size > 0,
  );
}

addMethod(mixed, 'file', file);

function fileAccepts(
  this: MixedSchema,
  message: string,
  accepts: SupportedMimes[],
  nullable: boolean,
) {
  return this.test('fileAccepts', message, (a: unknown) => {
    if (nullable && !a) return true;
    if (!a || !(typeof a === 'object') || !('contentType' in a)) {
      return false;
    }

    return accepts.includes(a.contentType as SupportedMimes);
  });
}

addMethod(mixed, 'fileAccepts', fileAccepts);
