import { array } from 'typescript-json-decoder';

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import {
  CarePlanDirectives,
  carePlanDirectivesDecoder,
} from '@/models/CarePlanDirectivesModel';
import {
  CarePlans,
  PatientRenewalDecision,
  carePlansDecoder,
} from '@/models/CarePlanModel';
import { CareTeam, careTeamDecoder } from '@/models/CareTeamModel';
import { Contact, contactDecoder } from '@/models/ContactModel';
import { CreatePatientModel } from '@/models/CreatePatientModel';
import {
  DiabetesDataDisplayParams,
  diabetesDisplayModeDecoder,
} from '@/models/DiabetesDisplayModeModel';
import { EditTherapeuticObjectivesData } from '@/models/EditTherapeuticObjectivesModel';
import {
  MessageCountType,
  Patient,
  PatientID,
  messageCountDecoder,
  patientDecoder,
  patientIDDecoder,
} from '@/models/PatientModel';
import {
  PatientList,
  patientListItemDecoder,
} from '@/models/PatientSummaryModel.ts';
import { RenewCarePlanData } from '@/models/RenewCarePlanModel';
import { ValidateOrRejectIdentity } from '@/models/identity/FrenchIdentity';
import {
  IdentityValidation,
  identityValidationDecoder,
} from '@/models/identity/IdentityValidationModel';
import { ContactData } from '@/pages/patient-medical-file/contact-patient/ContactPatient.schema';
import { forcedArray } from '@/utils/decoderUtils';

/**
 * For health pro only
 */
export class ManagePatientRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getPatientList = (): Promise<PatientList> => {
    return this.datasource.get(
      `practitioner/patients/list/all/`,
      forcedArray(patientListItemDecoder),
    );
  };

  getPatient = (id: string): Promise<Patient> => {
    return this.datasource.get(`practitioners/patients/${id}/`, patientDecoder);
  };

  createPatient = (data: CreatePatientModel): Promise<PatientID> => {
    return this.datasource.post(
      `practitioners/patients/create/`,
      patientIDDecoder,
      data,
    );
  };

  renewCarePlan = (
    patientId: string,
    data: RenewCarePlanData,
  ): Promise<PatientID> => {
    return this.datasource.post(
      `practitioners/renew-care-plan/${patientId}/`,
      patientIDDecoder,
      data,
    );
  };

  editTherapeuticObjectives = (
    carePlanId: string,
    data: EditTherapeuticObjectivesData,
  ): Promise<PatientID> => {
    return this.datasource.patch(
      `practitioners/edit-care-plan-description/${carePlanId}/`,
      patientIDDecoder,
      data,
    );
  };

  getPatientMessageCount = (id: string): Promise<MessageCountType> => {
    return this.datasource.get(
      `practitioners/patients/${id}/message-count/`,
      messageCountDecoder,
    );
  };

  getCareTeam = (patientId: string): Promise<CareTeam> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/care-team/`,
      careTeamDecoder,
    );
  };

  getCarePlans = (patientId: string): Promise<CarePlans> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/care-plans/`,
      carePlansDecoder,
    );
  };

  getCarePlanDirectives = (patientId?: string): Promise<CarePlanDirectives> => {
    return this.datasource.get(
      patientId
        ? `practitioners/care-plan-directives/${patientId}/`
        : 'practitioners/care-plan-directives/',
      carePlanDirectivesDecoder,
    );
  };

  getListContacts = (patientId: string): Promise<Contact[]> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/contacts/`,
      forcedArray(contactDecoder),
    );
  };

  getIdentitiesToValidate = (): Promise<IdentityValidation[]> => {
    return this.datasource.get(
      `practitioners/identities-to-validate/`,
      array(identityValidationDecoder),
    );
  };

  getDiabetesDisplayMode = (
    patientId: string,
  ): Promise<DiabetesDataDisplayParams> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/diabetes-display-mode/`,
      diabetesDisplayModeDecoder,
    );
  };

  validateOrRejectIdentity = (
    patientId: string,
    action: ValidateOrRejectIdentity,
  ): Promise<PatientID> => {
    return this.datasource.post(
      `practitioners/patients/${patientId}/validate-identity/${action}/`,
      patientIDDecoder,
    );
  };

  createPatientContacts = (
    patientId: string,
    data: ContactData,
  ): Promise<Contact> => {
    return this.datasource.post(`/contacts/${patientId}/`, contactDecoder, {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      phones: [{ number: data.phone, description: 'mobile' }],
      comment: data.comment,
      is_emergency_contact: data.isEmergencyContact,
      relationship_with_patient: data.relationshipWithPatient,
    });
  };

  updatePatientContacts = (
    patientId: string,
    contactId: string,
    data: Partial<ContactData>,
  ): Promise<Contact> => {
    return this.datasource.patch(
      `/contacts/${patientId}/${contactId}/`,
      contactDecoder,
      {
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        phones: [{ number: data.phone, description: 'mobile' }],
        comment: data.comment,
        is_emergency_contact: data.isEmergencyContact,
        relationship_with_patient: data.relationshipWithPatient,
      },
    );
  };

  deletePatientContacts = (
    patientId: string,
    contactId: string,
  ): Promise<undefined> => {
    return this.datasource.delete(
      `/contacts/${patientId}/${contactId}/`,
      () => undefined,
    );
  };

  decideRenewPatient = (
    patientId: string,
    decision: PatientRenewalDecision,
  ): Promise<undefined> => {
    return this.datasource.post(
      `/practitioners/patients/${patientId}/renewal-decision/`,
      () => undefined,
      { decision },
    );
  };
}
