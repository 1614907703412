import { css } from '@emotion/css';
import { Theme } from '@mui/material';

export const inputStyle = (theme: Theme, value?: string) => css`
  .MuiInputBase-input {
    padding: ${theme.spacing(1.5, 2)};
    margin: 0;

    width: calc(
      ${(value?.toString()?.length ?? 3) + 0.1}ch + ${theme.spacing(4)}
    );
    max-width: ${theme.spacing(256)};

    min-width: unset;
  }
`;

export const textStyle = () => css`
  flex: 0 1;
  white-space: nowrap;
`;
