import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

import { GlycemiaDataManagement } from './GlycemiaDataManagement';
import { OnboardingManagement } from './OnboardingManagement';

export const QATools: React.FC = () => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <GlycemiaDataManagement />
      <OnboardingManagement />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `,
});
