import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SideBar } from '@/components/navigation/SideBar';
import { useStyles } from '@/hooks/useStyles';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';

export const PatientLayout: React.FC<LayoutProps> = props => {
  const styles = useStyles(makeStyles);
  const { i18n } = useTranslation();

  return (
    <div id="patient-layout" lang={i18n.language} className={styles.container}>
      <SideBar home="/" />
      <main id="patient-layout-main" className={styles.content}>
        <RenderLayoutChildren {...props} />
      </main>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${theme.palette.background.default};
  `,
  content: css`
    display: flex;
    flex-direction: column;
    padding: 4vh 4vw;
    margin-left: ${theme.spacing(30)};
    flex-shrink: 1;
    flex-grow: 1;
    overflow-x: clip;
  `,
});
