import React from 'react';

import { css } from '@emotion/css';
import { Skeleton, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles.ts';
import { CGMDailyGraphData } from '@/models/CGMDailyGraphData.ts';
import { InsulinDataDisplayType } from '@/models/DiabetesDisplayModeModel.ts';
import { SvgDayAxis } from '@/uiKit/atoms/svg/SvgDayAxis.tsx';
import { SvgDayGrid } from '@/uiKit/atoms/svg/SvgDayGrid.tsx';
import { AdditionalDiabetesInfoGraph } from '@/uiKit/molecules/graphs/AdditionalDiabetesInfoGraph/AdditionalDiabetesInfoGraph.tsx';
import { GlycemiaGraph } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaGraph.tsx';
import { InsulinGraph } from '@/uiKit/molecules/graphs/InsulinGraph/InsulinGraph.tsx';
import { SvgLayout, SvgLegend } from '@/utils/graphUtils.ts';

const ADDITIONAL_INFO_HEIGHT = 25;
const AXIS_HEIGHT = 30;

const LEGEND_WIDTH = 150;
const LEGEND_HORIZONTAL_PADDING = 10;
const LEGEND_VERTICAL_PADDING = 20;

const GRAPH_HORIZONTAL_PADDING = 20;
const GRAPH_VERTICAL_PADDING = 0;

export type GraphLayoutOptions = {
  width: number;
  height: number;
  additionalInfoHeight?: number;
  axisHeight?: number;
  legendWidth?: number;
  legendHorizontalPadding?: number;
  legendVerticalPadding?: number;
  horizontalPadding?: number;
  verticalPadding?: number;
};

type SvgDailyGraphProps = {
  data: CGMDailyGraphData;
  insulinDisplayMode: InsulinDataDisplayType;
} & GraphLayoutOptions;

export type SvgDailyGraphLayout = {
  legend: SvgLegend;
  additionalInfo: SvgLayout;
  glycemia: SvgLayout;
  insulin: SvgLayout;
  axis: SvgLayout;
  grid: SvgLayout;
};

/**
 * Simple daily graph svg component.
 * ```
 * Layout pen : (ex: h = 240px => var. 185px + fixed 55px)
 * |--------------------------------------------------------------------|
 * |              Additional Info (Activity, Food, Reports)             |
 * |                     w = 100%         h = 25px                      |
 * |--------------------------------------------------------------------|
 * |                           Glycemia Graph                           |
 * |                                                                    |
 * |                     w = 100%        h = 2/3 (123px)                |
 * |                                                                    |
 * |--------------------------------------------------------------------|
 * |                           Insulin Graph                            |
 * |                                                                    |
 * |                     w = 100%        h = 1/3 (62px)                 |
 * |____________________________________________________________________|
 * |     legend         |                      Axis                     |
 * | w = 150px h = 30px |     w = 100% - 150px        h = 30px          |
 * |____________________|_______________________________________________|
 *
 * Layout pump : (ex: h = 320px => var. 264px + fixed 55px)
 * |--------------------------------------------------------------------|
 * |              Additional Info (Activity, Food, Reports)             |
 * |                      w = 100%       h = 25px                       |
 * |--------------------------------------------------------------------|
 * |                           Glycemia Graph                           |
 * |                                                                    |
 * |                     w = 100%       h = 5/11 (120px)                |
 * |                                                                    |
 * |--------------------------------------------------------------------|
 * |                           Insulin Graph                            |
 * |                                                                    |
 * |                      w = 100%       h = 6/11 (100px)               |
 * |____________________________________________________________________|
 * |     legend         |                      Axis                     |
 * |  w = 150   h = 30  |       w = 100% - 150        h = 30px          |
 * |____________________|_______________________________________________|
 * ```
 * Layout rules:
 * - Additional info and axis are fixed height (25px and 30px by default)
 * - Glycemia graph should always be at minimum 120px
 * - Adapt the height of the insulin based on the data display mode (discrete or continuous insulin)
 * - Each component manages its own legend, but the legend dimensions are shared between all components
 *
 * @param data The data to display in the graph
 * @param insulinDisplayMode The display mode of the insulin data (continuous or discrete)
 * @param width The width of the graph
 * @param height The height of the graph
 * @param additionalInfoHeight The height of the additional info section at the top of the graph
 * @param axisHeight The height of the x-axis at the bottom of the graph
 * @param legendWidth The width of the legend on the left of the graph
 * @param legendHorizontalPadding The horizontal padding inside the legend
 * @param legendVerticalPadding The vertical padding inside the legend
 * @param horizontalPadding The horizontal padding of the graph
 * @param verticalPadding The vertical padding of the graph
 */
const SvgDailyGraph_: React.FC<SvgDailyGraphProps> = ({
  data,
  insulinDisplayMode,
  width,
  height,
  additionalInfoHeight = ADDITIONAL_INFO_HEIGHT,
  axisHeight = AXIS_HEIGHT,
  legendWidth = LEGEND_WIDTH,
  legendHorizontalPadding = LEGEND_HORIZONTAL_PADDING,
  legendVerticalPadding = LEGEND_VERTICAL_PADDING,
  horizontalPadding = GRAPH_HORIZONTAL_PADDING,
  verticalPadding = GRAPH_VERTICAL_PADDING,
}) => {
  const styles = useStyles(makeStyles);
  // Memo necessary because mouse position listener constantly re-renders
  const layout = getLayout(
    {
      width,
      height,
      additionalInfoHeight,
      axisHeight,
      legendWidth,
      legendHorizontalPadding,
      legendVerticalPadding,
      horizontalPadding,
      verticalPadding,
    },
    insulinDisplayMode,
  );

  return (
    <svg width={width} height={height} className={styles.graphContainer}>
      {/* The grey grid in the background */}
      <SvgDayGrid {...layout.grid} />
      {/* The small 25px space on top of the graph */}
      <AdditionalDiabetesInfoGraph
        {...layout.additionalInfo}
        activity={data.activity}
        reports={data.reports}
        food={data.food}
        legend={layout.legend}
        date={data.date}
      />
      {/* The glycemia graph */}
      <GlycemiaGraph
        {...layout.glycemia}
        glycemia={data.glycemia}
        glycemiaParameters={data.parameters}
        legend={layout.legend}
        date={data.date}
      />
      {/* The insulin graph, either continuous or discrete */}
      <InsulinGraph
        {...layout.insulin}
        insulin={data.insulin}
        insulinDisplayMode={insulinDisplayMode}
        legend={layout.legend}
        date={data.date}
      />
      {/* The x-axis at the bottom of the graph */}
      <SvgDayAxis {...layout.axis} />
    </svg>
  );
};

export const SvgDailyGraph = React.memo(SvgDailyGraph_);

/**
 * Calculate the dimensions and positions of the different components of the graph
 * See SvgDailyGraph_ for a schema of the resulting layout
 *
 * @param layoutOptions Some constants that can be overridden
 * @param insulinDisplayMode The display mode of the insulin data (continuous or discrete)
 */
const getLayout = (
  layoutOptions: Required<GraphLayoutOptions>,
  insulinDisplayMode: InsulinDataDisplayType,
): SvgDailyGraphLayout => {
  const [glycemiaRatio, insulinRatio] =
    insulinDisplayMode === 'discrete' ? [2 / 3, 1 / 3] : [5 / 11, 6 / 11];
  const dim = {
    left: layoutOptions.horizontalPadding,
    top: layoutOptions.verticalPadding,
    width: layoutOptions.width - layoutOptions.horizontalPadding * 2,
    height: layoutOptions.height - layoutOptions.verticalPadding * 2,
  };
  const legendWidth = layoutOptions.legendWidth;

  const axisHeight = layoutOptions.axisHeight;
  const axisWidth = dim.width - legendWidth;

  const gridWidth = dim.width - legendWidth;
  const gridHeight = dim.height - axisHeight + axisHeight / 3;

  const additionalInfoHeight = layoutOptions.additionalInfoHeight;
  const graphHeight = dim.height - axisHeight - additionalInfoHeight;
  const glycemiaHeight = graphHeight * glycemiaRatio;
  const insulinHeight = graphHeight * insulinRatio;
  const additionalInfoWidth = dim.width;
  const glycemiaWidth = dim.width;
  const insulinWidth = dim.width;

  return {
    legend: {
      legendPadding: {
        x: layoutOptions.legendHorizontalPadding,
        y: layoutOptions.legendVerticalPadding,
      },
      legendWidth,
    },
    additionalInfo: {
      width: additionalInfoWidth,
      height: additionalInfoHeight,
      left: dim.left,
      top: dim.top,
    },
    glycemia: {
      width: glycemiaWidth,
      height: glycemiaHeight,
      left: dim.left,
      top: dim.top + additionalInfoHeight,
    },
    insulin: {
      width: insulinWidth,
      height: insulinHeight,
      left: dim.left,
      top: dim.top + additionalInfoHeight + glycemiaHeight,
    },
    axis: {
      width: axisWidth,
      height: axisHeight,
      left: dim.left + legendWidth,
      top: dim.top + additionalInfoHeight + glycemiaHeight + insulinHeight,
    },
    grid: {
      width: gridWidth,
      height: gridHeight,
      left: dim.left + legendWidth,
      top: dim.top,
    },
  };
};

export type SvgDailyGraphSkeletonProps = {
  insulinDisplayMode: InsulinDataDisplayType;
} & GraphLayoutOptions;

export const SvgDailyGraphSkeleton: React.FC<SvgDailyGraphSkeletonProps> = ({
  insulinDisplayMode,
  width,
  height,
  additionalInfoHeight = ADDITIONAL_INFO_HEIGHT,
  axisHeight = AXIS_HEIGHT,
  legendWidth = LEGEND_WIDTH,
  legendHorizontalPadding = LEGEND_HORIZONTAL_PADDING,
  legendVerticalPadding = LEGEND_VERTICAL_PADDING,
  horizontalPadding = GRAPH_HORIZONTAL_PADDING,
  verticalPadding = GRAPH_VERTICAL_PADDING,
}) => {
  const gap = 4;
  const layout = getLayout(
    {
      width,
      height: height - gap * 2,
      additionalInfoHeight,
      axisHeight,
      legendWidth,
      legendHorizontalPadding,
      legendVerticalPadding,
      horizontalPadding,
      verticalPadding,
    },
    insulinDisplayMode,
  );
  const styles = useStyles(makeSkeletonStyles, width, height, gap);

  return (
    <div className={styles.container}>
      <Skeleton variant="rectangular" height={layout.additionalInfo.height} />
      <Skeleton variant="rectangular" height={layout.glycemia.height} />
      <Skeleton variant="rectangular" height={layout.insulin.height} />
      <Skeleton variant="rectangular" height={layout.axis.height} />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  graphContainer: css`
    position: relative;
    color: ${theme.palette.text.primary};
  `,
});

const makeSkeletonStyles = (
  _: Theme,
  width: number,
  height: number,
  gap: number,
) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${gap}px;
    width: ${width}px;
    height: ${height}px;
    overflow: clip;
  `,
});
