import {
  Glycemia,
  ReaderGlycemia,
  SensorGlycemia,
  SplitGlycemiaData,
} from '@/models/diabetes/GlycemiaModel.ts';

export const isReaderGlycemia = (
  glycemia: Glycemia,
): glycemia is ReaderGlycemia => glycemia.type === 'capillary';

export const isSensorGlycemia = (
  glycemia: Glycemia,
): glycemia is SensorGlycemia => glycemia.type === 'interstitial';

/**
 * Split the glycemia data by type
 *
 * Used to display the insulin data in the daily graph
 *
 * Reader glycemia comes from Blood Glucose Meter
 * Sensor glycemia comes from Continuous Glucose Monitor
 * @param glycemia
 */
export const splitGlycemiaData = (glycemia: Glycemia[]): SplitGlycemiaData => {
  const reader: ReaderGlycemia[] = [];
  const sensor: SensorGlycemia[] = [];

  glycemia.forEach(glycemia => {
    // Unshift to set the data in ascending date order
    if (isReaderGlycemia(glycemia)) {
      reader.unshift(glycemia);
    } else if (isSensorGlycemia(glycemia)) {
      sensor.unshift(glycemia);
    }
  });

  return { reader, sensor };
};
