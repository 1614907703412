import { useState } from 'react';

import { css, cx } from '@emotion/css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Table as TSTable, flexRender } from '@tanstack/react-table';

import { useStyles } from '@/hooks/useStyles';

type MuiTableProps<T> = {
  id?: string;
  table: TSTable<T>;
  handleRowClick: (row: T) => void;
  headerRowClassName?: string;
  headerCellClassName?: string;
  rowClassName?: string;
  cellClassName?: string;
};

export const MuiTable = <T,>({
  id,
  table,
  handleRowClick,
  headerRowClassName,
  headerCellClassName,
  rowClassName,
  cellClassName,
}: MuiTableProps<T>) => {
  const [hoveredRowId, setHoveredRowId] = useState<string>('');
  const styles = useStyles(makeStyles);

  return (
    <TableContainer id={id} className={styles.container}>
      <Table size="medium">
        <TableHead className={styles.header}>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow
              key={headerGroup.id}
              className={cx(styles.row, headerRowClassName)}
            >
              {headerGroup.headers.map(header => (
                <TableCell
                  key={header.id}
                  className={cx(
                    styles.header,
                    css({ width: `${header.column.getSize()}%` }),
                    headerCellClassName,
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody className={styles.body}>
          {table.getRowModel().rows.map(row => (
            <TableRow
              key={row.id}
              hover
              onMouseEnter={() => setHoveredRowId(row.id)}
              onMouseLeave={() => setHoveredRowId('')}
              className={cx(styles.row, styles.contentRow, rowClassName)}
              onClick={() => handleRowClick(row.original)}
            >
              {row.getVisibleCells().map(cell => {
                return (
                  <TableCell
                    key={cell.id}
                    className={cx(
                      css({
                        width: `${cell.column.getSize()}%`,
                      }),
                      cellClassName,
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      meta: {},
                      hovered: hoveredRowId === row.id,
                    })}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const makeStyles = () => ({
  body: css``,
  row: css({
    '& > *': {
      borderBottom: 'none',
    },
  }),
  contentRow: css({
    cursor: 'pointer',
  }),
  container: css``,
  header: css({
    '& > *': {
      borderBottom: 'none',
    },
    padding: 0,
    margin: 0,
  }),
});
