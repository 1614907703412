export type SupportedMimes =
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'image/png'
  | 'image/jpeg'
  | 'image/jpg'
  | 'image/bmp'
  | 'image/webp'
  | 'image/svg+xml'
  | 'text/plain'
  | 'text/html'
  | 'text/csv'
  | 'text/calendar'
  | 'text/markdown'
  | 'audio/mpeg'
  | 'video/mp4'
  | 'application/zip';

export const cleanUuidFromFilename = (name: string): string =>
  name
    .replace(
      /\.?[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/,
      '',
    )
    .replace('/', '_');

export const downloadCSVFromBlob = (data: Blob) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'data.csv';
  a.click();
  window.URL.revokeObjectURL(url);
};
