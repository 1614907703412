import React from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuActionTooltip } from '@/components/floating/MenuTooltip.tsx';
import { UserPicture } from '@/components/user-picture/UserPicture';
import { useStyles } from '@/hooks/useStyles';
import { useKeycloak } from '@/keycloak';
import { Queries } from '@/queries/Queries';

export const UserMenu: React.FC = ({}) => {
  const styles = useStyles(makeStyles);
  const user = Queries.user.useMe({});
  const { t } = useTranslation();
  const keycloak = useKeycloak();

  const data = user?.data;

  switch (data) {
    case undefined:
      return (
        <div id="user-account-menu" className={styles.picture}>
          <UserPicture />
        </div>
      );
    default:
      if (data.userType === 'admin') {
        return (
          <div id="user-account-menu" className={styles.picture}>
            <UserPicture />
          </div>
        );
      }
      return (
        <MenuActionTooltip
          openOnHover
          placement="right-end"
          actions={[
            <Link id="nav-account" to="/account" key="account">
              {t('pages.account.title')}
            </Link>,
            <Typography
              id="disconnect-button"
              variant="body"
              onClick={() => keycloak.logout({ redirectUri: window.origin })}
              key="disconnect"
            >
              {t('navbar.disconnect')}
            </Typography>,
          ]}
        >
          <div id="user-account-menu" className={styles.picture}>
            <UserPicture
              givenName={data.firstName}
              familyName={data.lastName}
            />
          </div>
        </MenuActionTooltip>
      );
  }
};

const makeStyles = () => ({
  picture: css`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `,
});
