import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles.ts';
import {
  PatientListItem,
  TelemonitoringTag,
  telemonitoringTagColorMapping,
  telemonitoringTagTranslationMapping,
} from '@/models/PatientSummaryModel.ts';
import { Profession } from '@/models/ProfessionModel.ts';
import { userFormattedName } from '@/models/UserModel.ts';
import { PatientTableRenewButton } from '@/pages/patients/PatientTableRenewButton.tsx';
import { Tag } from '@/uiKit/atoms/Tag.tsx';

type BasePatientNameColumnProps = CellContext<PatientListItem, string>;
export type DoctorPatientNameColumnProps = BasePatientNameColumnProps;
export type NursePatientNameColumnProps = BasePatientNameColumnProps;
export type PatientNameColumnProps = BasePatientNameColumnProps & {
  qualification: Profession;
};

export const DoctorPatientNameColumn: React.FC<
  DoctorPatientNameColumnProps
> = props => <PatientNameColumn {...props} qualification="doctor" />;

export const NursePatientNameColumn: React.FC<
  NursePatientNameColumnProps
> = props => <PatientNameColumn {...props} qualification="nurse" />;

const PatientNameColumn: React.FC<PatientNameColumnProps> = ({
  row,
  hovered,
  qualification,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { id, familyName, givenName, tags, renewalSuggestion } = row.original;
  // Show one of the following tags: 'expiring_soon', 'expired_recently', 'no_glycemia_data'
  const tag: TelemonitoringTag | undefined = useMemo(() => {
    if (tags.includes('expiring_soon')) {
      return 'expiring_soon';
    }
    if (tags.includes('expired_recently')) {
      return 'expired_recently';
    }
    if (tags.includes('no_glycemia_data') && tags.includes('active')) {
      return 'no_glycemia_data';
    }
    return undefined;
  }, [tags]);

  const tagElement = tag && (
    <Tag
      label={t(telemonitoringTagTranslationMapping(tag))}
      color={telemonitoringTagColorMapping(tag)}
    />
  );

  return (
    <div className={styles.container}>
      <Typography className={styles.name} variant="body">
        {userFormattedName(familyName, givenName)}
      </Typography>
      {qualification === 'doctor' ? (
        <PatientTableRenewButton
          id={`renew-tls-from-patient-list-${id}`}
          data-action="renew-tls"
          data-source="patient-list"
          data-patient={id}
          renewalSuggestion={renewalSuggestion}
          patientId={id}
          isButtonVisible={!!hovered && renewalSuggestion !== undefined}
        >
          {tagElement}
        </PatientTableRenewButton>
      ) : (
        tagElement
      )}
    </div>
  );
};

export const PatientNameHeader: React.FC = () => {
  return <div />;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-left: ${theme.spacing(8)};
    gap: ${theme.spacing(8)};
  `,
  name: css`
    font-weight: bold;
    text-wrap: wrap;
  `,
});
