import React, { useContext, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { AttachmentContext } from '@/components/chat/footer/AttachmentContext';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { ChipLegacy } from '@/uiKit/Chip';

type RecipientToggleProps = {
  onUpdate: (x: string) => void;
};

export const RecipientToggle = ({ onUpdate }: RecipientToggleProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const attachmentContext = useContext(AttachmentContext);
  const patientId = usePatientIdFromURL() ?? '';

  const { data: condition } = Queries.condition.useCondition(patientId);

  const medicalStaffLabel = t('messaging.medicalStaff');
  const everybodyLabel = t('messaging.everybody');
  const [label, setLabel] = useState(
    !condition?.isBillable ? everybodyLabel : medicalStaffLabel,
  );

  const onButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    inputFile.current?.click();
  };

  const updateRecipientHandler = () => {
    let newLabel;
    if (label === medicalStaffLabel) {
      newLabel = everybodyLabel;
    } else {
      newLabel = medicalStaffLabel;
    }
    setLabel(newLabel);
    onUpdate(newLabel);
  };

  return (
    <div className={styles.container}>
      <Typography variant="caption" className={styles.label}>
        {t('messaging.recipient')}:
      </Typography>
      <ChipLegacy
        onClick={updateRecipientHandler}
        enabled={!!condition?.isBillable}
        className={cx(
          label === medicalStaffLabel ? styles.staffBackgound : '',
          styles.chip,
        )}
      >
        <Typography variant="caption" className={styles.label}>
          {label}
        </Typography>
        <div className={styles.chevron}>
          <Icons.chevronDown />
        </div>
      </ChipLegacy>

      <input
        multiple
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept={[
          'application/pdf',
          'image/png',
          'image/jpeg',
          'image/jpg',
          'image/bmp',
          'image/webp',
          'image/svg+xml',
          'text/plain',
          'text/html',
          'text/csv',
          'text/calendar',
          'text/markdown',
          'audio/mpeg',
          'video/mp4',
          'application/zip',
        ].join(', ')}
        onChange={() => {
          if (inputFile.current && inputFile.current.files) {
            attachmentContext.setFiles?.(Array.from(inputFile.current.files));
          }
        }}
      />
      <div className={styles.uploadButtonContainer}>
        <Icons.fileUpload
          className={styles.uploadButton}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  staffBackgound: css`
    background: ${theme.palette.green.contrastText};
  `,
  chip: css`
    display: flex;
    align-items: center;
    width: ${theme.spacing(100)};
    padding: ${theme.spacing(1, 8)};
  `,
  container: css`
    display: inline-flex;
    align-items: center;
    gap: ${theme.spacing(4)};
    padding-left: ${theme.spacing(8)};
    padding-top: ${theme.spacing(8)};
  `,
  label: css`
    margin-right: auto;
    font-weight: bold;
    color: ${theme.palette.text.primary};
  `,
  uploadButton: css`
    fill: ${theme.palette.text.primary};
    cursor: pointer;
  `,
  uploadButtonContainer: css`
    padding-left: ${theme.spacing(8)};
  `,
  chevron: css`
    display: flex;
    align-items: center;
  `,
});
