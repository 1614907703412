import {
  GetNextPageParamFunction,
  GetPreviousPageParamFunction,
  InfiniteData,
  QueryClient,
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ApiError } from '@/models/ApiErrorData.ts';
import {
  AcceptedVariables,
  CreateQueryOptions,
  QueryKey,
} from '@/queries/utils/CreateQuery';

type CreateInfiniteQueryOptions<
  Data,
  Variables extends AcceptedVariables,
> = CreateQueryOptions<Variables, Data> & {
  getPreviousPageParam?: GetPreviousPageParamFunction<Variables, Data>;
  maxPages?: number;
};

type CreateInfiniteQueryFunctionContext<Variables extends AcceptedVariables> =
  QueryFunctionContext<QueryKey, Variables> & {
    queryClient: QueryClient;
  };

/**
 * Same as the options of useInfiniteQuery but with some fields removed
 * @see UseQueryOptions
 *
 * queryKey: Already defined in the createQuery function and should not be modified
 * queryFn: Already defined in the createQuery function and should not be modified
 * networkMode: Depends on the data source and should not be modified by the UI
 * throwOnError: Use useSuspenseQuery / createSuspenseQuery instead
 * meta: Do not use if possible (untyped)
 * queryClient: Should use the default queryClient from the context (remove from omit only if you know what you are doing)
 *
 */
export type FilteredUseInfiniteQueryOptions<
  Variables extends AcceptedVariables,
  Data,
  TransformData = Data,
> = Omit<
  UseInfiniteQueryOptions<
    Data,
    ApiError,
    TransformData,
    Data,
    QueryKey,
    Variables
  >,
  | 'queryFn'
  | 'queryKey'
  | 'initialPageParam'
  | 'getNextPageParam'
  | 'getPreviousPageParam'
  | 'networkMode'
  | 'throwOnError'
  | 'meta'
  | 'queryClient'
>;

/**
 * Creates a new query
 * Returns a useQuery hook with an already defined behavior (the queryKey, queryFn and networkMode are already set) and some default options
 *
 * Usage:
 * - First call createQuery with a function and a query key outside the component tree. You can also add other options like retry, staleTime, etc.
 * ```ts
 * type UsePatientQueryVariables = {
 *    patientId: string;
 * };
 *
 * export const usePatientQuery = createQuery(
 *    name: 'usePatientQuery',
 *    queryKey: (variables) => [variables.id],
 *    queryFn: async ({ patientId }: UsePatientQueryVariables) => {
 *        // Your API / Storage call here
 *        return await fetchPatient(patientId);
 *    }, {
 *        staleTime: 1000 * 60 * 5, // 5 minutes, we want to refresh our patients regularly
 *        // The only option that is not part of useQuery is onSuccess. It is just called after the queryFn is successful
 *        onSuccess: (resultData, variables, context) => {
 *            // In there you have access to the context, so it can be used to invalidate other queries, update the cache, etc.
 *        }
 *    }
 *    );
 * ```
 *
 * - Then use the hook in your component
 * ```tsx
 * const patientQuery = usePatientQuery({ // First parameter is the variables
 *    patientId: '123'
 * }, { // Second parameter is the options, same as useQuery with some fields removed (see FilteredUseQueryOptions)
 *    enabled: true,
 *    staleTime: 1000 * 60, // 1 minute, Query options can also be overridden here
 * });
 * ```
 *
 * - Also, the hook has some additional properties
 *   They can be used outside the component tree
 * ```ts
 * // Get the query key for a specific set of variables
 * const queryKey = usePatientQuery.getQueryKey({ patientId: '123' });
 * // Manually update the query data
 * usePatientQuery.manualUpdate(queryClient, { patientId: '123' }, (data) => ({ ...data, name: 'John' }));
 * // Invalidate the query
 * usePatientQuery.invalidate({ patientId: '123' });
 * ```
 */
/**
 * Take inspiration of the doc useQuery above to generate this one :
 * Create a new infinite query
 * Returns a useInfiniteQuery hook with an already defined behavior (the queryKey, queryFn and networkMode are already set) and some default options
 *
 * Usage:
 * - First call createInfiniteQuery with a function, a query key and a getNextPageParams function outside the component tree. You can also add other options like retry, staleTime, etc.
 * ```ts
 * type StatsRequest = { patientId: string; from: string; to: string };
 *
 * export const useInfiniteDataviz = createInfiniteQuery(
 *    'infinite-dataviz',
 *    ({ patientId, from, to }) => makeQueryKey(patientId, from, to), // makeQueryKey generates a query key from potentially undefined variables @see makeQueryKey
 *    async ({ patientId, from, to }: StatsRequest) => {
 *        // Your API / Storage call here
 *        return await fetchDataviz(patientId, from, to);
 *    },
 *    (lastPage, allPages, lastPageParam) => {
 *
 */
export const createInfiniteQuery = <
  Name extends string,
  Variables extends AcceptedVariables,
  Data,
>(
  queryName: Name,
  queryKey: (variables: Partial<Variables>) => QueryKey,
  queryFn: (
    variables: Variables,
    context: CreateInfiniteQueryFunctionContext<Variables>,
  ) => Data | Promise<Data>,
  getNextPageParam: GetNextPageParamFunction<Variables, Data>,
  createQueryOptions?: CreateInfiniteQueryOptions<Data, Variables>,
) => {
  const useCustomInfiniteQuery = <TransformData = InfiniteData<Data>>(
    variables: Variables,
    options?: FilteredUseInfiniteQueryOptions<Variables, Data, TransformData>,
  ) => {
    const queryClient = useQueryClient();
    return useInfiniteQuery<Data, ApiError, TransformData, QueryKey, Variables>(
      {
        queryKey: [queryName, ...queryKey(variables)],
        queryFn: context =>
          'pageParam' in context
            ? queryFn(context.pageParam as Variables, {
                ...context,
                queryClient,
              })
            : queryFn(variables, { ...context, queryClient }),
        initialPageParam: variables,
        getNextPageParam,
        ...createQueryOptions,
        ...options,
      },
      queryClient,
    );
  };
  useCustomInfiniteQuery.getQueryKey = (variables: Partial<Variables>) => [
    queryName,
    ...queryKey(variables),
  ];
  useCustomInfiniteQuery.queryName = queryName;
  useCustomInfiniteQuery.manualUpdate = (
    queryClient: QueryClient,
    variables: Variables,
    updater: (data: Data) => Data,
  ) => {
    queryClient.setQueryData(
      useCustomInfiniteQuery.getQueryKey(variables),
      updater,
    );
  };
  useCustomInfiniteQuery.invalidate = (
    queryClient: QueryClient,
    variables: Partial<Variables>,
  ) => {
    queryClient.invalidateQueries({
      queryKey: useCustomInfiniteQuery.getQueryKey(variables),
    });
  };

  return useCustomInfiniteQuery;
};
