import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { NavItem } from '@/components/navigation/NavItem';
import { SideBar } from '@/components/navigation/SideBar';
import { useStyles } from '@/hooks/useStyles';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';

type HealthProLayoutPageProps = LayoutProps;

export const HealthProLayout: React.FC<HealthProLayoutPageProps> = props => {
  const styles = useStyles(makeStyles);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  return (
    <div
      id="practitioner-layout"
      lang={i18n.language}
      className={styles.container}
    >
      <SideBar home="/patients">
        <NavItem
          id="patient-list-nav-item"
          to="/patients"
          isCurrent={
            location.pathname === '/' ||
            location.pathname.startsWith('/patients')
          }
          tooltip={t('navbar.myPatients')}
        />
      </SideBar>
      <main id="practitioner-layout-main" className={styles.content}>
        <RenderLayoutChildren {...props} />
      </main>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: ${theme.palette.background.default};
  `,
  content: css`
    display: flex;
    flex-direction: column;
    padding: 4vh 4vw;
    margin-left: ${theme.spacing(30)};
    flex-shrink: 1;
    flex-grow: 1;
    overflow-x: clip;
  `,
});
