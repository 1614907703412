import React from 'react';

import { FormControlLabel, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  MailPreference,
  MailPreferenceUpdate,
} from '@/models/MailPreferenceModel';

type MailPreferenceItemType = {
  mail: MailPreference;
  onAddMailToUpdate: (data: MailPreferenceUpdate) => void;
  className?: string;
};

export const MailPreferenceItem: React.FC<MailPreferenceItemType> = ({
  mail,
  onAddMailToUpdate,
  className,
}) => {
  const { t } = useTranslation();

  const clickHandler = () => {
    onAddMailToUpdate({ id: mail.id });
  };

  const label = t(`pages.account.preferences.${mail.label}`);

  return (
    <div
      id={`mail-preference-${mail.label}-toggle`}
      data-action="mail-preference-toggle"
      data-label={mail.label}
      className={className}
    >
      <FormControlLabel
        control={
          <Switch
            onChange={clickHandler}
            checked={mail.enabled}
            color="success"
          />
        }
        label={<Typography variant="body">{label}</Typography>}
      />
    </div>
  );
};
