import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';

type GlycemiaStatsBGMNoDataProps = {
  className?: string;
};

export const GlycemiaStatsBGMNoData = ({
  className,
}: GlycemiaStatsBGMNoDataProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card className={className}>
      <CardHeader title={t('glycemia.title')} />
      <CardContent>
        <div className={styles.flexRow}>
          <StatItem
            className={styles.child}
            title={t('pages.patientMonitoring.glycemia.averageMeasuresPerDay')}
            value={0}
            precision={0}
          />
          <StatItem
            title={t('pages.patientMonitoring.glycemia.averageReader')}
            value={0}
            precision={0}
            unit={'mg/dL'}
          />
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  flexRow: css`
    display: flex;
    gap: ${theme.spacing(8)};
    width: 100%;
  `,
  child: css`
    width: 50%;
  `,
});
