import React from 'react';

import { captureException } from '@sentry/react';

import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries';
import { DiabetesStatsBGM } from '@/uiKit/organisms/DiabetesStats/DiabetesStatsBGM';
import { DiabetesStatsCGM } from '@/uiKit/organisms/DiabetesStats/DiabetesStatsCGM';

export const MonitoringStats: React.FC = () => {
  const state = usePatientMonitoringContext(state => state);
  const pump = Queries.insulin.useInsulinPump({
    patientId: state.patient.id,
    from: state.from,
    to: state.to,
  });
  const { data: diabetesDataDisplayMode } =
    Queries.practitioner.useDiabetesDisplayMode(state.patient.id);

  if (!diabetesDataDisplayMode) {
    return null;
  }

  switch (diabetesDataDisplayMode.glycemia_indicators_display_mode) {
    case 'bgm':
      if (
        diabetesDataDisplayMode.insulin_indicators_display_mode === 'continuous'
      ) {
        // Make sure to handle properly in API, continuous insulin is not supported with BGM
        captureException(
          new Error('Continuous insulin is not supported with BGM'),
        );
      }
      return (
        <DiabetesStatsBGM
          patientId={state.patient.id}
          from={state.from}
          to={state.to}
        />
      );
    case 'cgm':
    default:
      return (
        <DiabetesStatsCGM
          patientId={state.patient.id}
          from={state.from}
          to={state.to}
          insulinInjectionMethod={
            diabetesDataDisplayMode.insulin_indicators_display_mode ==
            'continuous'
              ? 'pump'
              : 'pen'
          }
          pump={pump.data?.device}
        />
      );
  }
};
