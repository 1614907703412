import React from 'react';

import { css, cx } from '@emotion/css';
import { Group } from '@visx/group';

import { useStyles } from '@/hooks/useStyles';
import { SvgLayout, SvgPoint } from '@/utils/graphUtils.ts';

export type GlycemiaLegendProps = {
  label: React.ReactNode;
  legendPadding: SvgPoint;
  className?: string;
} & SvgLayout;

type GlycemiaLegendLayout = {
  label: SvgLayout;
};

const GlycemiaLegend_: React.FC<GlycemiaLegendProps> = ({
  label,
  legendPadding,
  className,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const layout = getLayout(dim, legendPadding);
  return (
    <Group className={cx(styles.container, className)}>
      <Group {...layout.label}>{label}</Group>
    </Group>
  );
};

export const GlycemiaLegend = React.memo(GlycemiaLegend_);

const getLayout = (dim: SvgLayout, padding: SvgPoint): GlycemiaLegendLayout => {
  return {
    label: {
      left: dim.left + padding.x,
      top: dim.top + padding.y,
      width: dim.width - padding.x * 2,
      height: dim.height - padding.y * 2,
    },
  };
};

const makeStyles = () => ({
  container: css``,
});
