import { ChangeEvent } from 'react';

import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddMessageInfoData } from '@/components/chat/AddMessage.schema.ts';
import { RecipientToggle } from '@/components/chat/footer/RecipientToggle';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { Queries } from '@/queries/Queries';
import { Status } from '@/queries/Queries.ts';
import { TextArea } from '@/uiKit/input/textArea/muiTextArea';

import { Actions } from './Actions';

type TextBoxProps = {
  setValue: UseFormSetValue<AddMessageInfoData>;
  formValueGetter: (key: string) => string;
  requestStatus: Status;
};

/**
 * @todo Remake this component as controlled component and properly use MUI
 */
export const TextBox = ({
  setValue,
  formValueGetter,
  requestStatus,
}: TextBoxProps) => {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL() ?? '';
  const visibility = formValueGetter('visibility');
  const value = formValueGetter('contentText');

  const { data: condition } = Queries.condition.useCondition(patientId);

  let placeholder;
  if (visibility === 'all') {
    placeholder = !condition?.isBillable
      ? t('messaging.patientOnly')
      : t('messaging.toAllPlaceholder');
  } else {
    placeholder = t('messaging.toPractitionerPlaceholder');
  }

  const onUpdateRecipient = (recipient: string) => {
    if (recipient === t('messaging.everybody')) {
      setValue?.('visibility', 'all');
      setValue?.('category', 'notification');
    } else {
      setValue?.('visibility', 'practitioners');
      setValue?.('category', 'alert');
    }
  };

  const InputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue?.('contentText', e.target.value, {
      shouldValidate: true,
    });

    if (patientId) {
      sessionStorage.setItem(
        `${patientId}Draft`,
        e.target.value ? e.target.value : '',
      );
    }
  };
  const defaultValue = sessionStorage.getItem(`${patientId}Draft`);

  return (
    <TextArea
      value={value ?? ''}
      variant="standard"
      defaultValue={defaultValue ? defaultValue : ''}
      startAdornment={<RecipientToggle onUpdate={onUpdateRecipient} />}
      maxHeight={100}
      disableUnderline={true}
      endAdornment={
        <Actions
          requestStatus={requestStatus}
          formValueGetter={formValueGetter}
        />
      }
      placeholder={placeholder}
      onChange={InputHandler}
    />
  );
};
