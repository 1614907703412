import React from 'react';

import { css, cx } from '@emotion/css';
import { localPoint } from '@visx/event';

import { useStyles } from '@/hooks/useStyles';
import { SvgLayout, SvgPoint } from '@/utils/graphUtils.ts';

export type GraphAreaListenerProps = {
  onMouseMove: (position: SvgPoint | undefined) => void;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & SvgLayout;

export const GraphAreaListener: React.FC<GraphAreaListenerProps> = ({
  onMouseMove,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  className,
  ...dim
}) => {
  const styles = useStyles(makeStyles);

  return (
    <svg {...dim}>
      <rect
        x={0}
        y={0}
        width={dim.width}
        height={dim.height}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cx(styles.container, className)}
        pointerEvents="all"
        onMouseMove={e => onMouseMove(localPoint(e) ?? undefined)}
      />
    </svg>
  );
};

const makeStyles = () => ({
  container: css({
    cursor: 'pointer',
    fill: 'none',
  }),
});
