import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { DeviceType } from '@/models/MedicalDeviceModel.ts';
import { toDateTime } from '@/utils/dateUtils.ts';

export type SvgDataTooltipProps = {
  title: string;
  inputMethod?: DeviceType;
  content?: string | (string | undefined)[];
  date: string;
};

export const SvgDataTooltip: React.FC<SvgDataTooltipProps> = ({
  title,
  inputMethod,
  content,
  date,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const caption =
    date && inputMethod
      ? `${toDateTime(date).toLocaleString(DateTime.DATETIME_SHORT)} • ${t(`charts.inputMethod.${inputMethod}`)}`
      : date
        ? toDateTime(date).toLocaleString(DateTime.DATETIME_SHORT)
        : inputMethod
          ? t(`charts.inputMethod.${inputMethod}`)
          : undefined;

  const contentElement = !content ? null : typeof content === 'string' ? (
    <Typography variant="bodySmall" className={cx(styles.item, styles.content)}>
      {content}
    </Typography>
  ) : (
    content.map((item, index) =>
      item ? (
        <Typography key={index} variant="bodySmall" className={styles.item}>
          {item}
        </Typography>
      ) : null,
    )
  );

  return (
    <div className={styles.tooltip}>
      <Typography variant="body" className={cx(styles.item, styles.title)}>
        {title}
      </Typography>
      {contentElement}
      {caption ? (
        <Typography variant="caption" className={styles.item}>
          {caption}
        </Typography>
      ) : null}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  tooltip: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(4)} 0;
  `,

  item: css`
    max-width: ${theme.spacing(128)};
    text-align: center;
  `,
  content: css`
    word-break: break-all;
    white-space: pre-wrap;
  `,
  title: css`
    font-weight: bold;
  `,
});
