import React from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import {
  ContactMode,
  ContactPatient,
} from '@/pages/patient-medical-file/contact-patient/ContactPatient';

type EntourageProps = {
  patientId: string;
};

export const Entourage: React.FC<EntourageProps> = ({ patientId }) => {
  const { t } = useTranslation();

  const [mode, setMode] = React.useState<ContactMode>({ mode: 'view' });

  return (
    <Card elevation={0}>
      <CardHeader title={t('contact.entourage')} />
      <CardContent>
        <ContactPatient patientId={patientId} mode={mode} setMode={setMode} />
      </CardContent>
      <CardActions>
        <Button
          id="add-relationship"
          startIcon={<Icons.filePlus />}
          variant="contained"
          disabled={mode.mode !== 'view'}
          sx={
            mode.mode !== 'view'
              ? {
                  display: 'none',
                }
              : {}
          }
          onClick={() => setMode({ mode: 'create' })}
        >
          {t('contact.addRelationship')}
        </Button>
      </CardActions>
    </Card>
  );
};
