import { useCallback, useMemo } from 'react';

import { DateTime } from 'luxon';

import { BGMData } from '@/models/BGMLogbook.ts';
import { Patient } from '@/models/PatientModel.ts';
import { BGMViewKind } from '@/models/UISettingsModel';
import { PractitionerUser } from '@/models/UserModel.ts';
import {
  BGM_DOWN_IS_FUTURE_WEEKS,
  BGM_DOWN_IS_PAST_WEEKS,
} from '@/queries/DiabetesQueries.ts';
import { Queries, Status } from '@/queries/Queries.ts';
import { DateUtils } from '@/utils/dateUtils.ts';

export type UseBGMContentReturn = {
  data: BGMData[];
  status: Status;
  loadMoreData: () => void;
  kind: BGMViewKind;
  setKind: (newKind: BGMViewKind) => void;
};

/**
 * Hook to fetch BGM data for a patient.
 * The data is fetched based on the view kind.
 * The user preferences are stored in local storage and are updated when the user changes the view kind.
 * The view kind is either 'downIsPast' or 'downIsFuture' and is used to determine the order the data is displayed.
 * Everything is memoized to limit the re-renders of the heavy BGMLogBook component.
 *
 * @param practitioner
 * @param patient
 * @param date
 */
export const useBGMContent = (
  practitioner: PractitionerUser,
  patient: Patient,
  date: DateTime,
): UseBGMContentReturn => {
  const preferences = Queries.user.useBGMPreferences({
    userId: practitioner.id,
  });
  const setPreferences = Queries.user.useUpdateBGMPreferences();

  const kind = preferences.data?.viewKind ?? 'downIsPast';

  const dates = useMemo(() => getDateRange(date, kind), [date, kind]);

  const bgmRequest = Queries.diabetes.useInfiniteBGMLogbook(
    {
      patientId: patient.id,
      order: kind,
      from: dates.from,
      to: dates.to,
    },
    {
      select: data => {
        const pages = data.pages.flat();
        return kind === 'downIsFuture' ? pages.reverse() : pages;
      },
    },
  );

  const loadMoreData = useCallback(() => {
    bgmRequest.fetchNextPage({ cancelRefetch: false });
  }, [kind]);

  const setKind = useCallback(
    (newKind: BGMViewKind) => {
      setPreferences.mutate({
        userId: practitioner.id,
        data: { viewKind: newKind },
      });
    },
    [patient.id],
  );

  return useMemo(() => {
    return {
      data: bgmRequest.data ?? [],
      status: bgmRequest.status,
      loadMoreData,
      kind,
      setKind,
    };
  }, [bgmRequest.data, bgmRequest.status, loadMoreData]);
};

/**
 * Get the initial date range to fetch BGM data.
 *
 *  #### To
 *  If the target date is yesterday, we fetch one additional day (today).
 *  Today is not included for indicators, as it is incomplete and thus misleading, but we want to display the data.
 *
 *  #### From
 *
 *  If the view is downIsPast, there is no special rule, we fetch as many weeks as needed.
 *
 *  If the view is downIsFuture, we fetch one week. Minus one day because:
 *  ```
 *  Sunday
 *    |> minus(1 week) => Sunday of past week
 *    |> startOf(week) => Monday of past week (2 weeks fetched, KO)
 *  Sunday
 *    |> plus(1 day) => Monday of next week
 *    |> minus(1 week) => Monday of this week
 *    |> startOf(week) => Monday of this week (1 week fetched, OK)
 *  Monday
 *      |> plus(1 day) => Tuesday of this week
 *      |> minus(1 week) => Tuesday of past week
 *      |> startOf(week) => Monday of past week (1.3 weeks fetched, OK)
 *  Saturday
 *    |> plus(1 day) => Sunday of this week
 *    |> minus(1 week) => Sunday of past week
 *    |> startOf(week) => Monday of past week (1.9 weeks fetched, OK)
 *  ```
 *
 */
const getDateRange = (date: DateTime, kind: BGMViewKind) => {
  const isDateYesterday = DateUtils.compareDates(date, DateTime.now()) === -1;
  const to = isDateYesterday ? date.plus({ days: 1 }) : date;

  const from =
    kind === 'downIsFuture'
      ? to.minus({ day: -1, weeks: BGM_DOWN_IS_FUTURE_WEEKS }).startOf('week')
      : to.minus({ weeks: BGM_DOWN_IS_PAST_WEEKS }).startOf('week');
  return { from, to };
};
