import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import { Patient } from '@/models/PatientModel.ts';
import { Queries } from '@/queries/Queries';
import { DateUtils } from '@/utils/dateUtils.ts';

type PatientSummaryCardProps = {
  patient: Patient;
  className?: string;
};

export const PatientSummaryCard: React.FC<PatientSummaryCardProps> = ({
  patient,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const navigate = useNavigate();

  return (
    <Card className={className} elevation={0}>
      <CardTitle style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">
          {t('pages.patientMonitoring.patient.title')}
        </Typography>
        <div>
          <IconButton
            id="patient-summary-card-link"
            style={{ marginRight: 0, marginLeft: 0, paddingRight: 0 }}
            icon={Icons.externalLink}
            buttonType="link"
            placement="left"
            onClick={() => navigate(`/patients/${patient.id}/medical-file`)}
          />
        </div>
      </CardTitle>
      <CardBody className={styles.body}>
        {patient.birthDate ? <Age birthDate={patient.birthDate} /> : null}
        <DiabetesTypeComponent patientId={patient.id} />
        <InjectionSchemeComponent patientId={patient.id} />
        <TermDateComponent patientId={patient.id} />
      </CardBody>
    </Card>
  );
};
type ConditionProps = {
  patientId: string;
};

type AgeProps = {
  birthDate: string;
};

const Age: React.FC<AgeProps> = ({ birthDate }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Typography variant="bodySmall" className={styles.text}>
      {t('pages.patientMonitoring.patient.age', {
        age: DateUtils.age(birthDate),
      })}
    </Typography>
  );
};

const DiabetesTypeComponent: React.FC<ConditionProps> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: condition } = Queries.condition.useCondition(patientId);

  return condition ? (
    <Typography variant="bodySmall" className={styles.text}>
      {t(`pages.patientMonitoring.patient.diabetes.${condition.type}`)}
    </Typography>
  ) : (
    <Typography variant="bodySmall" className={cx(styles.text, styles.noValue)}>
      {t('pages.patientMonitoring.patient.unspecifiedDiabetesType')}
    </Typography>
  );
};

const InjectionSchemeComponent: React.FC<ConditionProps> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: condition } = Queries.condition.useCondition(patientId);

  return condition ? (
    <Typography variant="bodySmall" className={styles.text}>
      {t(
        `pages.patientMonitoring.patient.insulin.${condition.medicationSchema}`,
      )}
    </Typography>
  ) : (
    <Typography variant="bodySmall" className={cx(styles.text, styles.noValue)}>
      {t('pages.patientMonitoring.patient.unspecifiedInsulinScheme')}
    </Typography>
  );
};

export const TermDateComponent: React.FC<ConditionProps> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: condition } = Queries.condition.useCondition(patientId);

  return condition && condition.termDate ? (
    <Typography variant="bodySmall" className={styles.text}>
      {t('diabetesForms.termDate')} :{' '}
      {DateTime.fromISO(condition.termDate).toFormat('dd/MM/yyyy')}
    </Typography>
  ) : null;
};

const makeStyles = (theme: Theme) => ({
  body: css``,
  text: css`
    margin: ${theme.spacing(2)} 0;
  `,
  noValue: css`
    color: ${theme.palette.text.disabled};
    font-style: italic;
  `,
  footer: css`
    justify-content: flex-end;
    padding-bottom: ${theme.spacing(8)};
  `,
});
