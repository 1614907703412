import React from 'react';

import { GridColumns } from '@visx/grid';
import { scaleLinear } from '@visx/scale';

import { SvgLayout } from '@/utils/graphUtils.ts';

export type SvgDayGridProps = {
  className?: string;
} & SvgLayout;

/**
 * Grid component for SVGs.
 *
 * Displays a 24-hour grid.
 * Only display lines every 6 hours.
 *
 */
export const SvgDayGrid: React.FC<SvgDayGridProps> = ({
  className,
  ...dim
}) => {
  const scale = scaleLinear({ domain: [0, 24], range: [0, dim.width] });

  return (
    <GridColumns
      scale={scale}
      tickValues={[0, 6, 12, 18, 24]}
      className={className}
      {...dim}
    />
  );
};
