import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles.ts';
import { FoodComment } from '@/models/BGMLogbook';
import {
  getFoodMealTranslation,
  getFoodSizeTranslation,
} from '@/models/diabetes/FoodModel.ts';
import { DetailComment } from '@/uiKit/organisms/BGMLogBook/TableBody/Comments/DetailComment.tsx';

export const MealComment = ({ mealComment }: { mealComment: FoodComment }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  if (!mealComment.carbs && !mealComment.size) {
    // If there is no information about the meal, we don't show anything
    return null;
  }

  let text = '';

  if (mealComment.meal) {
    text = `${t(getFoodMealTranslation(mealComment.meal))} : `;
  }

  text += mealComment.size
    ? t(getFoodSizeTranslation(mealComment.size))
    : `${mealComment.carbs}g`;

  if (mealComment.comments) {
    text += `, ${mealComment.comments}`;
  }

  return (
    <DetailComment
      className={styles.chip}
      icon={<Icons.meal className={styles.icon} />}
      commentText={text}
      tooltipText={t('bgmLogBook.mealTooltip')}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  chip: css`
    background-color: ${theme.palette.bgm.greenBackground};
  `,
  icon: css`
    height: ${theme.spacing(8)};
    color: ${theme.palette.green.main};
  `,
});
