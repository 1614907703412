import React from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { ScaleLinear } from 'd3-scale';

import { useStyles } from '@/hooks/useStyles';
import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel.ts';
import { Glycemia } from '@/models/diabetes/GlycemiaModel.ts';
import { SvgBadge } from '@/uiKit/atoms/svg/SvgBadge.tsx';
import { thresholdColorMapping } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaGraphUtils.ts';
import { GlycemiaPointTooltip } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaTooltips.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';
import { SvgLayout, boundedDateTimeToSecond } from '@/utils/graphUtils.ts';
import { formatNumber } from '@/utils/mathUtils.ts';

type BaseGlycemiaPointsProps = {
  discrete: Glycemia[];
  thresholds: CGMGlycemiaParameters;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  date: string;
};

export type DiscreteGlycemiaPointsProps = BaseGlycemiaPointsProps & {
  type: 'compact' | 'normal';
  className?: string;
  paddingTop?: number;
} & SvgLayout;

export const DiscreteGlycemiaPoints_: React.FC<DiscreteGlycemiaPointsProps> = ({
  type,
  discrete,
  thresholds,
  xScale,
  yScale,
  date,
  className,
  paddingTop = 0,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const graphArea = {
    top: dim.top + paddingTop,
    left: dim.left,
    width: dim.width,
    height: dim.height - paddingTop,
  };
  return (
    <Group className={cx(styles.container, className)} {...graphArea}>
      {type === 'normal' && (
        <FullGlycemiaPoints
          discrete={discrete}
          thresholds={thresholds}
          xScale={xScale}
          yScale={yScale}
          date={date}
        />
      )}
      {type === 'compact' && (
        <CompactGlycemiaPoints
          discrete={discrete}
          thresholds={thresholds}
          xScale={xScale}
          yScale={yScale}
          date={date}
        />
      )}
    </Group>
  );
};

export const DiscreteGlycemiaPoints = React.memo(DiscreteGlycemiaPoints_);

const CompactGlycemiaPoints: React.FC<BaseGlycemiaPointsProps> = ({
  discrete,
  thresholds,
  xScale,
  yScale,
  date,
}) => {
  const theme = useTheme();
  const radius = 8;
  const day = toDateTime(date);
  return discrete.map(glycemia => {
    const x = xScale(boundedDateTimeToSecond(day, glycemia.date));
    const y = yScale(glycemia.value);
    return (
      <GlycemiaPointTooltip
        key={glycemia.id}
        glycemia={glycemia}
        x={radius}
        y={0}
      >
        <circle
          className={css({ cursor: 'pointer', pointerEvents: 'all' })}
          key={glycemia.id}
          r={radius}
          cy={y}
          cx={x + radius} // begin circle at cx (not middle circle at cx)
          fill={thresholdColorMapping(glycemia.value, thresholds, theme).light}
        />
      </GlycemiaPointTooltip>
    );
  });
};

const FullGlycemiaPoints: React.FC<BaseGlycemiaPointsProps> = ({
  discrete,
  thresholds,
  xScale,
  yScale,
  date,
}) => {
  const theme = useTheme();
  const height = 24;
  const width = 40;
  const day = toDateTime(date);
  return discrete.map(glycemia => {
    const x = xScale(boundedDateTimeToSecond(day, glycemia.date));
    const y = yScale(glycemia.value);
    return (
      <GlycemiaPointTooltip
        key={glycemia.id}
        glycemia={glycemia}
        x={width / 2}
        y={0}
      >
        <SvgBadge
          key={glycemia.id}
          text={formatNumber(glycemia.value, 0)}
          top={y - height / 2}
          left={x}
          width={width}
          height={height}
          color={thresholdColorMapping(glycemia.value, thresholds, theme)}
        />
      </GlycemiaPointTooltip>
    );
  });
};

const makeStyles = () => ({
  container: css``,
});
