import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { InsulinDistribution } from '@/uiKit/organisms/DiabetesStats/InsulinDistribution.tsx';
import { InsulinStatItem } from '@/uiKit/organisms/DiabetesStats/InsulinStatItem.tsx';

type InsulinStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  className?: string;
};

export const InsulinStatsBGM = ({
  patientId,
  from,
  to,
  className,
}: InsulinStatsProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: insulinStats } = Queries.diabetes.useInsulinDiscreteIndicators({
    patientId,
    from: from,
    to: to,
  });

  if (!insulinStats) {
    return null;
  }

  return (
    <Card className={className}>
      <CardHeader title={t('insulin.title')} />
      <CardContent className={styles.content}>
        <InsulinStatItem
          title={t('insulin.averageNbInjectionsPerDay')}
          value={insulinStats.averageNumberOfInjection}
          precision={1}
          className={styles.leftChild}
        />
        <div className={styles.rightChild}>
          <InsulinDistribution
            basalTitle={t('insulin.slowInsulinPerDay')}
            bolusTitle={t('insulin.fastInsulinPerDay')}
            basal={insulinStats.averageQuantityInjected.basal}
            bolus={insulinStats.averageQuantityInjected.bolus}
            wheelSize="small"
          />
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (_theme: Theme) => ({
  content: css`
    display: flex;
    width: 100%;
  `,
  leftChild: css`
    width: 40%;
  `,
  rightChild: css`
    width: 60%;
  `,
});
