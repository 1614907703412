import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { Chip, ChipProps, Theme, Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { AtomProps } from '@/uiKit/atoms/AtomTypes.ts';

type BaseTagProps = {
  /**
   * The content of the component.
   */
  label?: ReactNode;
  /**
   * Icon element.
   */
  icon?: ReactElement;
  /**
   * The color palette of the component.
   * @default 'default'
   */
  color?: ChipProps['color'];
  /**
   * If true, the chip will appear clickable, and will raise when pressed, even if the onClick prop is not defined.
   * If false, the chip will not appear clickable, even if onClick prop is defined.
   * This can be used, for example, along with the component prop to indicate an anchor Chip is clickable.
   * Note: this controls the UI and does not affect the onClick event.
   */
  clickable?: boolean;
  /**
   * On click event handler.
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
};

type TagStyleClasses = {
  root?: string;
  label?: string;
  icon?: string;
};

export type TagProps = AtomProps<BaseTagProps, TagStyleClasses>;

export const Tag: React.FC<TagProps> = ({ label, classes, ...props }) => {
  const styles = useStyles(makeStyles, !!label);

  return (
    <Chip
      label={
        label && typeof label == 'string' ? (
          <Typography variant="caption" classes={{ root: styles.text }}>
            {label}
          </Typography>
        ) : (
          label
        )
      }
      variant="inverted"
      size="small"
      classes={{
        root: cx(styles.root, classes?.root),
        label: cx(styles.label, classes?.label),
        icon: cx(styles.icon, classes?.icon),
      }}
      {...props}
    />
  );
};

const makeStyles = (theme: Theme, hasLabel: boolean) => ({
  root: css`
    border-radius: ${theme.spacing(1)};
  `,
  label: css`
    padding: 0 ${hasLabel ? theme.spacing(4) : theme.spacing(2)};
  `,
  text: css`
    color: inherit;
  `,
  icon: css``,
});
