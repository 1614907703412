import React from 'react';

import { PaletteColor, Theme, useTheme } from '@mui/material';
import { ScaleLinear } from 'd3-scale';

import {
  CGMGlycemiaParameters,
  GlycemiaParametersType,
} from '@/models/DiabetesDataModel.ts';
import { valueToPercentage } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaGraphUtils.ts';

export type GlycemiaGradientProps = {
  id: string;
  thresholds: CGMGlycemiaParameters;
  yScale: ScaleLinear<number, number>;
};

const GlycemiaGradient_: React.FC<GlycemiaGradientProps> = ({
  id,
  thresholds,
  yScale,
}) => {
  const theme = useTheme();
  const range = yScale.range();

  const offsets = glycemiaColorInfo(thresholds, yScale, theme);

  return (
    <defs>
      <linearGradient
        id={id}
        x1={0}
        x2={0}
        y1={range[1]}
        y2={range[0]}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={offsets.severeHyperglycemia.percentage}
          stopColor={offsets.severeHyperglycemia.color.light}
        />
        <stop
          offset={offsets.severeHyperglycemia.percentage}
          stopColor={offsets.hyperglycemia.color.light}
        />
        <stop
          offset={offsets.hyperglycemia.percentage}
          stopColor={offsets.hyperglycemia.color.light}
        />
        <stop
          offset={offsets.hyperglycemia.percentage}
          stopColor={theme.palette.glycemia.normal.light}
        />
        <stop
          offset={offsets.hypoglycemia.percentage}
          stopColor={theme.palette.glycemia.normal.light}
        />
        <stop
          offset={offsets.hypoglycemia.percentage}
          stopColor={offsets.hypoglycemia.color.light}
        />
        <stop
          offset={offsets.severeHypoglycemia.percentage}
          stopColor={offsets.hypoglycemia.color.light}
        />
        <stop
          offset={offsets.severeHypoglycemia.percentage}
          stopColor={offsets.severeHypoglycemia.color.light}
        />
      </linearGradient>
    </defs>
  );
};

export type GlycemiaColorInfo = {
  threshold: number;
  y: number;
  percentage: number;
  color: PaletteColor;
};

export const glycemiaColorInfo = (
  thresholds: CGMGlycemiaParameters,
  yScale: ScaleLinear<number, number>,
  theme: Theme,
): Record<GlycemiaParametersType, GlycemiaColorInfo> => {
  const range = yScale.range();
  const start = range[1];
  const end = range[0];

  const severeHyperY = yScale(thresholds.severeHyperglycemia);
  const hyperY = yScale(thresholds.hyperglycemia);
  const hypoY = yScale(thresholds.hypoglycemia);
  const severeHypoY = yScale(thresholds.severeHypoglycemia);

  return {
    severeHyperglycemia: {
      threshold: thresholds.severeHyperglycemia,
      y: severeHyperY,
      percentage: valueToPercentage(severeHyperY, start, end),
      color: theme.palette.glycemia.severeHyperglycemia,
    },
    hyperglycemia: {
      threshold: thresholds.hyperglycemia,
      y: hyperY,
      percentage: valueToPercentage(hyperY, start, end),
      color: theme.palette.glycemia.hyperglycemia,
    },
    hypoglycemia: {
      threshold: thresholds.hypoglycemia,
      y: hypoY,
      percentage: valueToPercentage(hypoY, start, end),
      color: theme.palette.glycemia.hypoglycemia,
    },
    severeHypoglycemia: {
      threshold: thresholds.severeHypoglycemia,
      y: severeHypoY,
      percentage: valueToPercentage(severeHypoY, start, end),
      color: theme.palette.glycemia.severeHypoglycemia,
    },
  };
};

/**
 * Get the color of a value according to the thresholds.
 *
 * @param value The y value to get the color of
 * @param colorInfo the color information from the above function
 * @param theme The theme to get the default color from
 */
export const valueToColor = (
  value: number,
  colorInfo: Record<GlycemiaParametersType, GlycemiaColorInfo>,
  theme: Theme,
): PaletteColor => {
  if (value >= colorInfo.severeHypoglycemia.y) {
    return colorInfo.severeHypoglycemia.color;
  }
  if (value >= colorInfo.hypoglycemia.y) {
    return colorInfo.hypoglycemia.color;
  }
  if (value <= colorInfo.severeHyperglycemia.y) {
    return colorInfo.severeHyperglycemia.color;
  }
  if (value <= colorInfo.hyperglycemia.y) {
    return colorInfo.hyperglycemia.color;
  }

  return theme.palette.glycemia.normal;
};

export const GlycemiaGradient = React.memo(GlycemiaGradient_);
