import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import {
  DeviceType,
  MedicalDevice as MedicalDeviceModel,
} from '@/models/MedicalDeviceModel';
import { DateUtils, toDateTime } from '@/utils/dateUtils.ts';

export type MedicalDeviceProps = {
  device: MedicalDeviceModel;
};

const medicalDeviceMapping = {
  reader: 'devices.reader',
  sensor: 'devices.sensor',
  pump: 'devices.pump',
  pen: 'devices.pen',
  manual: 'devices.manual',
};

export const MedicalDevice: React.FC<MedicalDeviceProps> = ({ device }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const dateFromNow = (date: string | undefined) => {
    const dt = toDateTime(date);
    if (!dt.isValid) {
      return t('pages.patientMedicalFile.neverSynced');
    }
    const humanDuration = DateUtils.humanDurationFromNow(dt);

    return t('pages.patientMedicalFile.thereIs', { duration: humanDuration });
  };

  const deviceLastSync = () =>
    `${t('pages.patientMedicalFile.lastSync')} ${dateFromNow(device.lastUpload)}`;

  const deviceName = useDeviceNameMapping(device);

  return (
    <div className={styles.device}>
      <div className={styles.deviceType}>
        <DeviceTypeLogo type={device.kind} />
        <Typography variant="body">
          {t(medicalDeviceMapping[device.kind])}
        </Typography>
      </div>
      <Typography variant="body" className={styles.deviceName}>
        {deviceName}
      </Typography>
      <Typography variant="body" className={styles.lastSync}>
        {deviceLastSync()}
      </Typography>
    </div>
  );
};

const useDeviceNameMapping = (device: MedicalDeviceModel) => {
  const { t } = useTranslation();

  if (device.name === 'libre link') {
    return t('devices.libreLink');
  } else if (device.name === 'manual') {
    return '';
  }
  return device.name.capitalize();
};

const DeviceTypeLogo: React.FC<{ type: DeviceType }> = ({ type }) => {
  const styles = useStyles(makeStyles);
  switch (type) {
    case 'manual':
      return <Icons.manual className={styles.deviceIcons} />;
    case 'pump':
      return <Icons.pump className={styles.deviceIcons} />;
    case 'reader':
      return <Icons.reader className={styles.deviceIcons} />;
    case 'pen':
      return <Icons.insulinPen className={styles.deviceIcons} />;
    case 'sensor':
      return <Icons.sensor className={styles.deviceIcons} />;
  }
};

const makeStyles = (theme: Theme) => ({
  device: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(8)};
    justify-content: space-between;
    align-items: center;
  `,
  deviceType: css`
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.palette.text.primary};
  `,
  deviceName: css`
    flex: 1 1;
    text-align: center;
  `,
  lastSync: css`
    flex: 2 1;
    text-align: end;
    color: ${theme.palette.text.secondary};
  `,
  deviceIcons: css`
    margin: ${theme.spacing(0, 2)};
    width: ${theme.spacing(12)};
    height: ${theme.spacing(12)};
    color: ${theme.palette.text.primary};
  `,
  stetoIcon: css`
    color: ${theme.palette.environment};
  `,
});
