import { scaleLinear } from '@visx/scale';

import { ThemeDim } from '@/theme/types.ts';
import { SupportedDate, toDateTime } from '@/utils/dateUtils.ts';

export type SvgPoint = {
  x: number;
  y: number;
};

export type SvgLayout = {
  width: number;
  height: number;
  left: number;
  top: number;
};

export type ExtendedSvgLayout = {
  width: number | ThemeDim;
  height: number | ThemeDim;
  left: number | ThemeDim;
  top: number | ThemeDim;
};

export type SvgCoords = {
  from: SvgPoint;
  to: SvgPoint;
};

export type SvgLegend = {
  legendPadding: SvgPoint;
  legendWidth: number;
};

/**
 * Create a scale for the day.
 * For values between 0 and 24 * 60 * 60 seconds. (24 hours)
 * @param dim the width or height of the graph.
 */
export const dayScale = (dim: number) =>
  scaleLinear({
    domain: [0, 24 * 60 * 60],
    range: [0, dim],
  });

/**
 * Create a scale that goes from 0 to the maximum value.
 * @param dim the width or height of the graph.
 * @param maxValue the maximum value to display.
 */
export const zeroScale = (dim: number, maxValue: number) =>
  scaleLinear({
    domain: [0, maxValue],
    range: [dim, 0],
    zero: true,
  });

type BoundedDateTimeToSecondOptions = {
  min?: number;
  max?: number;
  precision?: number;
};
/**
 * Convert a date to seconds since the start of the day.
 * Used to display the data on the graph.
 * Data from the day before or after can be added to the graph for continuity.
 * These values have to be bounded to the start and end of the day.
 *
 * By default :
 * - Returns a value between 0 and 24 * 60 * 60.
 * - If the date is before the start of the day, it will return 0.
 * - If the date is after the end of the day, it will return 24 * 60 * 60.
 *
 * @param day the day to use as a reference
 * @param dataDate the date to convert
 * @param options the options to bound the value
 *  - min the minimum date as seconds since the start of the day
 *  - max the maximum date as seconds since the start of the day
 *  - precision the precision to round the value to (for example 15 * 60 for 15 minutes increments)
 */
export const boundedDateTimeToSecond = (
  day: SupportedDate,
  dataDate: SupportedDate,
  options: BoundedDateTimeToSecondOptions = {},
) => {
  const { min = 0, max = 24 * 60 * 60, precision } = options;
  let seconds = toDateTime(dataDate).toSeconds() - toDateTime(day).toSeconds();
  if (precision) {
    seconds = Math.round(seconds / precision) * precision;
  }
  if (seconds < min) {
    return min;
  }
  if (seconds > max) {
    return max;
  }
  return seconds;
};

/**
 * Find gaps in the data and mark them.
 * To mark a gap, add a { x: NaN, y: NaN } point in the data.
 * y should be the time in seconds since the start of the day.
 * The gap threshold is in seconds too.
 * @param data the data to check { x: number, y: number }[]
 * @param gapThreshold the threshold to consider a gap, in seconds
 */
export const splitGapsInData = (
  data: SvgPoint[],
  gapThreshold: number,
): SvgPoint[][] => {
  const result: SvgPoint[][] = [];
  let prev: SvgPoint | undefined = undefined;
  let index = 0;
  for (const point of data) {
    if (prev) {
      const secondsDiff = Math.abs(point.x - prev.x);
      if (secondsDiff > gapThreshold) {
        result.push([]);
        index += 1;
      }
    } else {
      result.push([]);
    }
    prev = point;
    result[index].push(point);
  }
  return result;
};

export type SvgPointWithData<T = undefined> = {
  data: T;
} & SvgPoint;

export type SvgPointWithDistance<T> = {
  distance: number;
} & SvgPointWithData<T>;

export type SurroundingPoints<T> = {
  closest?: SvgPointWithDistance<T>;
  furthest?: SvgPointWithDistance<T>;
  before?: SvgPointWithDistance<T>;
  after?: SvgPointWithDistance<T>;
};

/**
 * Find the closest points to the mouse x position in a graph data.
 * The data should be sorted by x position ASC.
 * @param data
 * @param mouseX
 */
export const findSurroundingPoints = <T>(
  data: SvgPointWithData<T>[],
  mouseX: number | undefined,
): SurroundingPoints<T> => {
  let before: SvgPointWithDistance<T> | undefined = undefined;
  let after: SvgPointWithDistance<T> | undefined = undefined;
  let closest: SvgPointWithDistance<T> | undefined = undefined;
  let furthest: SvgPointWithDistance<T> | undefined = undefined;

  if (!mouseX) {
    return {};
  }

  for (const point of data) {
    if (point.x <= mouseX) {
      before = {
        ...point,
        distance: 0,
      };
    } else if (point.x > mouseX) {
      after = {
        ...point,
        distance: 0,
      };
      break;
    }
  }

  if (before && !after) {
    before.distance = mouseX - before.x;
    return {
      closest: before,
      before,
    };
  } else if (!before && after) {
    after.distance = after.x - mouseX;
    return {
      closest: after,
      after,
    };
  } else if (before && after) {
    const beforeDistance = mouseX - before.x;
    const afterDistance = after.x - mouseX;
    before.distance = beforeDistance;
    after.distance = afterDistance;
    if (beforeDistance < afterDistance) {
      closest = before;
      furthest = after;
    } else {
      closest = after;
      furthest = before;
    }
    return {
      closest,
      furthest,
      before,
      after,
    };
  } else {
    return {};
  }
};

export type SelectSurroundingPointOptions = {
  rule: 'closest' | 'furthest' | 'before' | 'after';
  maxDistance?: number;
};

/**
 * Select a surrounding point based on a rule and a maximum distance.
 *
 * @param surroundings
 * @param options
 */
export const selectSurroundingPoint = <T>(
  surroundings: SurroundingPoints<T>,
  options: SelectSurroundingPointOptions,
) => {
  const { rule, maxDistance } = options;
  if (!surroundings[rule]) {
    return undefined;
  }
  if (maxDistance && surroundings[rule].distance > maxDistance) {
    return undefined;
  }
  return surroundings[rule];
};

/**
 * Check if a point is within the bounds of a layout.
 * @param layout
 * @param point
 */
export const isPointWithinBounds = (layout: SvgLayout, point?: SvgPoint) => {
  if (!point) {
    return false;
  }

  const x =
    !point.x ||
    (point.x >= layout.left && point.x <= layout.left + layout.width);
  const y =
    !point.y ||
    (point.y >= layout.top && point.y <= layout.top + layout.height);

  return x && y;
};

/**
 * Return the point if it is within the bounds of a layout, or undefined.
 * @param layout
 * @param point
 */
export const pointWithinBoundsOrUndef = (
  layout: SvgLayout,
  point: SvgPoint | undefined,
) => {
  return isPointWithinBounds(layout, point) ? point : undefined;
};

/**
 * Get the x position of an item.
 */
export type ToScaledX<T> = (item: T) => number;

/**
 * Merged data points.
 * @param x the average x position of the data points
 * @param data the data points
 */
export type MergedData<T> = {
  x: number;
  data: T[];
}[];

/**
 * Merge overlapping data points.
 * The data should be sorted by x position (ASC).
 * If points could overlap, based on the size and the x position, they are merged.
 * @param data
 * @param toScaledX
 * @param size
 */
export const mergeOverlappingData = <T>(
  data: T[],
  toScaledX: ToScaledX<T>,
  size: number,
): MergedData<T> => {
  const mergedData: MergedData<T> = [];
  let currentGroup: T[] = [];
  let currentX: number = -1;
  for (const item of data) {
    const x = toScaledX(item);

    if (currentX == -1) {
      // If no current, create a new group
      currentGroup = [item];
      currentX = x;
    } else if (currentX + size >= x) {
      // If the current x is close to the new x, add to the group
      currentGroup.push(item);
      currentX = (currentX + x) / 2;
    } else {
      // If the x is too far, create a new group
      mergedData.push({ x: currentX, data: currentGroup });
      currentGroup = [item];
      currentX = x;
    }
  }

  if (currentGroup.length > 0) {
    // Add the last group
    mergedData.push({ x: currentX, data: currentGroup });
  }

  return mergedData;
};
