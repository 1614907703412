import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';

import { useStyles } from '@/hooks/useStyles';
import { InsulinInjectionMethod } from '@/models/InsulinStatsModel.ts';
import { MedicalDevice } from '@/models/MedicalDeviceModel.ts';
import { GlycemiaStatsCGM } from '@/uiKit/organisms/DiabetesStats/GlycemiaStatsCGM.tsx';
import { InsulinPumpSettings } from '@/uiKit/organisms/DiabetesStats/InsulinPumpSettings.tsx';
import {
  InsulinStatsCGMContinuous,
  InsulinStatsCGMDiscrete,
} from '@/uiKit/organisms/DiabetesStats/InsulinStatsCGM.tsx';

type DiabetesStatsProps = {
  patientId: string;
  to: DateTime;
  from: DateTime;
  insulinInjectionMethod: InsulinInjectionMethod;
  pump?: MedicalDevice;
};

export const DiabetesStatsCGM = ({
  patientId,
  from,
  to,
  insulinInjectionMethod,
  pump,
}: DiabetesStatsProps) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <GlycemiaStatsCGM patientId={patientId} from={from} to={to} />
      {insulinInjectionMethod === 'pump' ? (
        <InsulinStatsCGMContinuous patientId={patientId} from={from} to={to} />
      ) : (
        <InsulinStatsCGMDiscrete patientId={patientId} from={from} to={to} />
      )}
      <InsulinPumpSettings pump={pump} />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    gap: ${theme.spacing(8)};
    flex-direction: column;
  `,
});
