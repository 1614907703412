import { useRef, useState } from 'react';

import { css } from '@emotion/css';
import { Button, IconButton, Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { FileModel } from '@/models/FileModel';
import { Patient } from '@/models/PatientModel';
import { UploadedFileType } from '@/models/UploadFileModel';
import { Queries } from '@/queries/Queries';

import { Loader } from '../loading/Loader';

type UploadButtonProps = {
  id?: string;
  onFileChange: (file?: FileModel) => void;
  fileType: UploadedFileType;
  patientInfo: Pick<Patient, 'givenName' | 'familyName'>;
  defaultValue?: FileModel;
  hasTrash?: boolean;
  displayFilename?: boolean;
};

export const UploadButton = ({
  id,
  onFileChange,
  fileType,
  patientInfo,
  defaultValue,
  hasTrash = false,
  displayFilename = true,
}: UploadButtonProps) => {
  const styles = useStyles(makeStyles);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadFile = Queries.practitioner.useUploadFile();

  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setHasBeenDeleted(false);

    const file = event.target.files[0];
    uploadFile.mutate(
      {
        file,
        type: fileType,
        patient_firstname: patientInfo.givenName,
        patient_lastname: patientInfo.familyName,
      },
      {
        onSuccess: data => {
          onFileChange(data);
        },
      },
    );
  };

  const filename =
    hasBeenDeleted || !displayFilename
      ? null
      : fileInputRef.current?.files?.[0]?.name || defaultValue?.name;

  const Icon = () =>
    uploadFile.status == 'pending' ? <Loader size="S" /> : <Icons.fileUpload />;

  return (
    <div id={id}>
      <Button
        component="label"
        size="medium"
        variant="outlined"
        tabIndex={-1}
        onClick={handleButtonClick}
        endIcon={filename && <Icon />}
      >
        {filename ? filename : <Icon />}
      </Button>
      <input
        type="file"
        className={styles.hidden}
        ref={fileInputRef}
        onChange={uploadFileHandler}
      />
      {hasTrash && filename && (
        <IconButton
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            setHasBeenDeleted(true);
            onFileChange({
              name: '',
              url: '',
              path: '',
            });
          }}
        >
          <Icons.trash color="black" />
        </IconButton>
      )}
    </div>
  );
};

const makeStyles = (_theme: Theme) => ({
  hidden: css`
    display: none;
  `,
});
