import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles.ts';
import { Dot } from '@/uiKit/atoms/Dot.tsx';
import { InsulinStatsWheel } from '@/uiKit/molecules/graphs/InsulinStatsWheel.tsx';
import { InsulinStatItem } from '@/uiKit/organisms/DiabetesStats/InsulinStatItem.tsx';

type InsulinDistributionProps = {
  basalTitle: string;
  basal: number;
  bolusTitle: string;
  bolus: number;
  className?: string;
  wheelSize: 'big' | 'small';
};

export const InsulinDistribution = ({
  basalTitle,
  basal,
  bolusTitle,
  bolus,
  wheelSize,
  className,
}: InsulinDistributionProps) => {
  const theme = useTheme();
  const styles = useStyles(makeStyles);

  const totalAverageInjected = basal + bolus;

  const ratioBasal =
    totalAverageInjected === 0 ? 0 : basal / totalAverageInjected;

  const ratioBolus =
    totalAverageInjected === 0 ? 0 : bolus / totalAverageInjected;
  return (
    <div className={cx(styles.container, className)}>
      <InsulinStatsWheel
        className={styles.wheel}
        basal={basal}
        bolus={bolus}
        size={wheelSize}
      />
      <div className={styles.legend}>
        <InsulinStatItem
          legend={
            <Dot
              backgroundColor={theme.palette.insulin.basal.contrastText}
              borderColor={theme.palette.insulin.basal.main}
            />
          }
          title={basalTitle}
          percentage={ratioBasal}
          percentagePrecision={0}
          value={basal}
          unit="U"
          precision={1}
        />
        <InsulinStatItem
          legend={
            <Dot
              backgroundColor={theme.palette.insulin.bolus.contrastText}
              borderColor={theme.palette.insulin.bolus.main}
            />
          }
          title={bolusTitle}
          percentage={ratioBolus}
          percentagePrecision={0}
          value={bolus}
          unit="U"
          precision={1}
        />
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  wheel: css``,
  legend: css`
    flex: 0 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
    justify-content: space-between;
  `,
  container: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(16)};
    align-items: center;
    flex: 1 1 0;
    flex-wrap: wrap;
  `,
});
