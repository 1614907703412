import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';

import { useStyles } from '@/hooks/useStyles';
import { SvgText } from '@/uiKit/atoms/svg/SvgText.tsx';
import { SvgLayout } from '@/utils/graphUtils.ts';

export type SvgDayAxisProps = {
  className?: string;
  width: number;
  left: number;
} & SvgLayout;

/**
 * Day axis component for SVGs.
 *
 * Displays a 24-hour axis.
 *
 * @param className
 * @param dim
 */
const SvgDayAxis_: React.FC<SvgDayAxisProps> = ({ className, ...dim }) => {
  const styles = useStyles(makeStyles);
  const scale = scaleLinear({ domain: [0, 24], range: [0, dim.width] });

  return (
    <Group {...dim} className={className}>
      <AxisBottom
        scale={scale}
        hideTicks
        hideAxisLine
        numTicks={12}
        tickComponent={({ x, formattedValue }) => (
          <SvgText left={x} dy="1em" className={styles.label}>
            {formattedValue}
          </SvgText>
        )}
        tickFormat={e => `${e}h`}
      />
    </Group>
  );
};

export const SvgDayAxis = React.memo(SvgDayAxis_);

const makeStyles = (theme: Theme) => ({
  label: css({
    fill: theme.palette.grey[400],
    textAnchor: 'middle',
    fontSize: theme.typography.bodySmall.fontSize,
  }),
});
