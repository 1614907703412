import React from 'react';

import { css, cx } from '@emotion/css';
import { UseHoverProps } from '@floating-ui/react';
import { Theme } from '@mui/material';

import {
  BaseTooltip,
  BaseTooltipProps,
} from '@/components/floating/BaseTooltip';
import { useStyles } from '@/hooks/useStyles';
import { Optional } from '@/utils/commonUtils';

export type MenuTooltipProps = Omit<
  Optional<BaseTooltipProps, 'role'>,
  'type'
> & {
  click?: UseHoverProps;
  openOnHover?: boolean;
};

export const MenuTooltip: React.FC<MenuTooltipProps> = ({
  role = 'menu',
  click,
  openOnHover = false,
  className,
  floaterClassName,
  wrapperClassName,
  children,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <BaseTooltip
      type={
        openOnHover
          ? { type: 'all', click: click ?? {}, hover: {} }
          : { type: 'click', ...click }
      }
      role={role}
      className={cx(styles.container, className)}
      wrapperClassName={cx(styles.wrapper, wrapperClassName)}
      floaterClassName={cx(styles.floater, floaterClassName)}
      {...props}
    >
      {children}
    </BaseTooltip>
  );
};

export type MenuActionTooltipProps = Omit<
  Optional<BaseTooltipProps, 'role'>,
  'type' | 'content'
> & {
  click?: UseHoverProps;
  openOnHover?: boolean;
  actions: React.ReactNode[];
};

export const MenuActionTooltip: React.FC<MenuActionTooltipProps> = ({
  role = 'menu',
  click,
  openOnHover = false,
  className,
  floaterClassName,
  wrapperClassName,
  children,
  actions,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <BaseTooltip
      type={
        openOnHover
          ? { type: 'all', click: click ?? {}, hover: {} }
          : { type: 'click', ...click }
      }
      role={role}
      className={cx(styles.container, className)}
      wrapperClassName={cx(styles.wrapper, wrapperClassName)}
      floaterClassName={cx(styles.actionFloater, floaterClassName)}
      content={<div className={styles.actions}>{actions}</div>}
      {...props}
    >
      {children}
    </BaseTooltip>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    cursor: pointer;
  `,
  wrapper: css``,
  floater: css`
    padding: 0;
  `,
  actions: css`
    > * {
      display: flex;
      padding: ${theme.spacing(4)} ${theme.spacing(8)};
      cursor: pointer;

      &:hover {
        background-color: ${theme.palette.background.default};
      }
    }
  `,
  actionFloater: css`
    padding: 0;
    min-width: min(200px, calc(90vw - ${theme.spacing(30)}));
    max-width: calc(90vw - ${theme.spacing(30)});
  `,
});
