import React from 'react';

import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextExternalLink } from '@/components/link/TextExternalLink';
import {
  CONTACT_EMAIL,
  HEALTH_PRO_MANUAL_URL,
  PATIENT_MANUAL_URL,
} from '@/constants/Constants';
import { useStyles } from '@/hooks/useStyles';

export type UserHelpProps = {
  isPractitioner?: boolean;
};

export const UserHelp: React.FC<UserHelpProps> = ({
  isPractitioner = false,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card id="help-card" className={styles.container} elevation={0}>
      <CardHeader id="help-title" title={t('pages.account.userHelp.title')} />
      <CardContent>
        <Typography variant="body">
          <TextExternalLink id="help-contact" to={`mailto:${CONTACT_EMAIL}`}>
            {t('pages.account.userHelp.contactUs')}
          </TextExternalLink>
          {` ${t('pages.account.userHelp.or')} `}
          <TextExternalLink
            id="help-manual"
            to={isPractitioner ? HEALTH_PRO_MANUAL_URL : PATIENT_MANUAL_URL}
          >
            {t('pages.account.userHelp.userManual')}
          </TextExternalLink>
        </Typography>
      </CardContent>
    </Card>
  );
};

const makeStyles = () => ({
  container: css``,
  questionIcon: css`
    height: 1em;
    width: 1em;
  `,
});
