import { css } from '@emotion/css';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { MailPreferenceBody } from '@/pages/account/preferences/MailPreferenceBody';

export const MailPreference = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card id="mail-preference-card" className={styles.container} elevation={0}>
      <CardHeader
        id="security-title"
        title={t('pages.account.preferences.title')}
      />
      <CardContent>
        <MailPreferenceBody />
      </CardContent>
    </Card>
  );
};

const makeStyles = () => ({
  container: css``,
});
