import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/floating/Tooltip';
import {
  TelemonitoringTier,
  getTelemonitoringTierTranslation,
} from '@/models/CarePlanModel';
import { TeleMonitoringCriteria } from '@/models/TelemonitoringCriteriaModel';
import { Queries } from '@/queries/Queries';

import { ChipSelect } from '../atoms/ChipSelect';

type TelemonitoringTierChipProps = {
  patientId?: string;
};
export const TelemonitoringTierChip = ({
  patientId,
}: TelemonitoringTierChipProps) => {
  const context = useFormContext();
  const { data } = Queries.practitioner.useCarePlanDirectives({ patientId });
  const { t } = useTranslation();

  const telemonitoringCriteria: TeleMonitoringCriteria | undefined =
    context.watch('carePlan.telemonitoringCriteria');
  const insulinType = context.watch('condition.insulinType');

  if (!data || !telemonitoringCriteria || !insulinType) {
    return null;
  }

  const tier =
    data.telemonitoringTierMap[
      telemonitoringCriteria as TeleMonitoringCriteria
    ];

  const tooltipTranslationKey: Record<TelemonitoringTier, string> = {
    base: 'diabetesForms.criteriaMeaningBase',
    level1: 'diabetesForms.criteriaMeaningLevel1',
    level2: 'diabetesForms.criteriaMeaningLevel2',
  };

  return (
    <Tooltip
      content={<Typography>{t(tooltipTranslationKey[tier])}</Typography>}
      placement="top"
    >
      <ChipSelect
        id="tls-tier-chips"
        selected={false}
        clickable={false}
        label={t(getTelemonitoringTierTranslation(tier))}
      />
    </Tooltip>
  );
};
