import React, { useCallback, useMemo } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { PatientListData } from '@/hooks/usePatientsListData.ts';
import { useStyles } from '@/hooks/useStyles.ts';
import { PatientListItem } from '@/models/PatientSummaryModel.ts';
import { Profession } from '@/models/ProfessionModel.ts';
import { PractitionerUser } from '@/models/UserModel.ts';
import {
  DiabetesTypeColumn,
  DiabetesTypeHeader,
} from '@/pages/patients/patients-list/DiabetesTypeColumn.tsx';
import {
  DoctorMessagesColumn,
  MessagesHeader,
  NurseMessagesColumn,
} from '@/pages/patients/patients-list/MessagesColumn.tsx';
import {
  DoctorNoticeColumn,
  NoticeHeader,
  NurseNoticeColumn,
} from '@/pages/patients/patients-list/NoticeColumn.tsx';
import { PatientListMenuItems } from '@/pages/patients/patients-list/PatientListMenu.tsx';
import {
  DoctorPatientNameColumn,
  NursePatientNameColumn,
  PatientNameHeader,
} from '@/pages/patients/patients-list/PatientNameColumn.tsx';
import {
  TelemonitoringColumn,
  TelemonitoringHeader,
} from '@/pages/patients/patients-list/TelemonitoringColumn.tsx';
import { MuiTable } from '@/uiKit/Table.tsx';

export type PatientListTableProps = {
  selectedItem: PatientListMenuItems;
  patientListData: PatientListData;
  me: PractitionerUser;
};

const columnHelper = createColumnHelper<PatientListItem>();

const DIABETES_TYPE_COLUMN_SIZE = 15;
const TELEMONITORING_COLUMN_SIZE = 18;
const NOTICES_COLUMN_SIZE = 18;
const MESSAGES_COLUMN_SIZE = 18;

const columnDefinitions = {
  default: {
    size: 0,
    minSize: 0,
    maxSize: 100,
  },
  patientName: (qualification: Profession) =>
    columnHelper.accessor('familyName', {
      cell:
        qualification === 'doctor'
          ? DoctorPatientNameColumn
          : NursePatientNameColumn,
      header: PatientNameHeader,
    }),
  diabetes: columnHelper.accessor('diabetesType', {
    cell: DiabetesTypeColumn,
    header: DiabetesTypeHeader,
    size: DIABETES_TYPE_COLUMN_SIZE,
  }),
  telemonitoring: columnHelper.accessor('tags', {
    cell: TelemonitoringColumn,
    header: TelemonitoringHeader,
    size: TELEMONITORING_COLUMN_SIZE,
  }),
  notices: (qualification: Profession) => {
    return columnHelper.accessor('hasNurseNotice', {
      cell: qualification === 'doctor' ? DoctorNoticeColumn : NurseNoticeColumn,
      header: NoticeHeader,
      size: NOTICES_COLUMN_SIZE,
    });
  },
  messages: (qualification: Profession) => {
    return columnHelper.accessor('messages', {
      cell:
        qualification === 'doctor' ? DoctorMessagesColumn : NurseMessagesColumn,
      header: MessagesHeader,
      size: MESSAGES_COLUMN_SIZE,
    });
  },
};

export const PatientListTable: React.FC<PatientListTableProps> = ({
  selectedItem,
  patientListData,
  me,
}) => {
  const styles = useStyles(makeStyles);
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const patientNameColumn = columnDefinitions.patientName(me.qualification);
    const messagesColumn = columnDefinitions.messages(me.qualification);
    const noticesColumn = columnDefinitions.notices(me.qualification);

    switch (selectedItem) {
      case 'monitored':
        patientNameColumn.size =
          100 - NOTICES_COLUMN_SIZE - MESSAGES_COLUMN_SIZE;
        return [patientNameColumn, messagesColumn, noticesColumn];
      case 'gestational':
        patientNameColumn.size =
          100 -
          DIABETES_TYPE_COLUMN_SIZE -
          NOTICES_COLUMN_SIZE -
          MESSAGES_COLUMN_SIZE;
        return [
          patientNameColumn,
          columnDefinitions.diabetes,
          messagesColumn,
          noticesColumn,
        ];
      case 'all':
        patientNameColumn.size =
          100 -
          DIABETES_TYPE_COLUMN_SIZE -
          TELEMONITORING_COLUMN_SIZE -
          NOTICES_COLUMN_SIZE -
          MESSAGES_COLUMN_SIZE;
        return [
          patientNameColumn,
          columnDefinitions.diabetes,
          columnDefinitions.telemonitoring,
          messagesColumn,
          noticesColumn,
        ];
    }
  }, [selectedItem, me.qualification]);

  const table = useReactTable<PatientListItem>({
    data: patientListData[selectedItem],
    defaultColumn: columnDefinitions.default,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleClick = useCallback((patient: PatientListItem) => {
    navigate(`/patients/${patient.id}`);
  }, []);

  return (
    <MuiTable
      id="patient-list-table"
      table={table}
      handleRowClick={handleClick}
      rowClassName="patient-list-row"
      cellClassName={styles.cell}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
    height: 100%;
  `,
  cell: css`
    height: ${theme.spacing(26)};
    padding: ${theme.spacing(2, 0)};
  `,
});
