import React from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { Group } from '@visx/group';

import { Icons } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles';
import { ThemeColor } from '@/theme/types.ts';
import { SvgCircledIcon } from '@/uiKit/atoms/svg/SvgCircledIcon.tsx';
import { AdditionalDiabetesInfoPointTooltip } from '@/uiKit/molecules/graphs/AdditionalDiabetesInfoGraph/AdditionalDiabetesInfoTooltips.tsx';
import { AdditionalDiabetesInfo } from '@/uiKit/molecules/graphs/AdditionalDiabetesInfoGraph/AdditionalDiabetesInfoUtils.ts';
import { toDateTime } from '@/utils/dateUtils.ts';
import {
  SvgLayout,
  boundedDateTimeToSecond,
  dayScale,
  mergeOverlappingData,
} from '@/utils/graphUtils.ts';

export type AdditionalDiabetesInfoPointsProps = {
  date: string;
  items: AdditionalDiabetesInfo[];
  className?: string;
  paddingTop?: number;
} & SvgLayout;

const ICON_SIZE = 20;
const BUNCHED_ICONS_OFFSET = 8;

const AdditionalDiabetesInfoPoints_: React.FC<
  AdditionalDiabetesInfoPointsProps
> = ({ date, items, className, paddingTop = 0, ...dim }) => {
  const styles = useStyles(makeStyles);
  const theme = useTheme();
  const graphArea = {
    top: dim.top + paddingTop,
    left: dim.left,
    width: dim.width,
    height: dim.height - paddingTop,
  };

  const xScale = dayScale(graphArea.width);

  const day = toDateTime(date);

  const mergedData = mergeOverlappingData<AdditionalDiabetesInfo>(
    items,
    item => xScale(boundedDateTimeToSecond(day, item.data.date)),
    ICON_SIZE,
  );
  // Convert data to x, y coordinates
  const coordData = mergedData.map(merged => ({
    data: merged.data,
    x: merged.x - ICON_SIZE / 2,
    y: (dim.height - ICON_SIZE) / 2,
    count: merged.data.length,
  }));

  return (
    <Group className={cx(styles.container, className)} {...graphArea}>
      {coordData.map(point => (
        <AdditionalDiabetesInfoPointTooltip
          key={point.x}
          items={point.data}
          x={ICON_SIZE / 2}
        >
          {point.data.map((item, index) => {
            // Slightly offset the icons to the left and right of the point
            const offset =
              (-(point.data.length - 1) / 2 + index) * BUNCHED_ICONS_OFFSET;
            const Icon =
              item.type === 'activity'
                ? Icons.activity
                : item.type === 'food'
                  ? Icons.meal
                  : Icons.alertTriangle;
            const [iconColor, circleColor] =
              item.type === 'activity'
                ? [theme.palette.yellow.main, theme.palette.yellow.contrastText]
                : item.type === 'food'
                  ? [theme.palette.green.main, theme.palette.green.contrastText]
                  : [
                      theme.palette.error.main,
                      theme.palette.error.contrastText,
                    ];
            const id = `${item.type}-${item.data.id}`;
            return (
              <SvgCircledIcon
                key={id}
                Icon={Icon}
                circleColor={circleColor as ThemeColor}
                iconColor={iconColor as ThemeColor}
                left={point.x + offset}
                top={point.y}
                width={ICON_SIZE}
                height={ICON_SIZE}
                iconToCircleRatio={0.6}
              />
            );
          })}
        </AdditionalDiabetesInfoPointTooltip>
      ))}
    </Group>
  );
};

export const AdditionalDiabetesInfoPoints = React.memo(
  AdditionalDiabetesInfoPoints_,
);

const makeStyles = () => ({
  container: css``,
});
