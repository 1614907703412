import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { InsulinPumpProfile } from '@/models/MedicalDeviceModel.ts';
import { toDateTime } from '@/utils/dateUtils.ts';
import { formatNumber } from '@/utils/mathUtils.ts';

export type InsulinPumpProfileContentProps = {
  profile: InsulinPumpProfile;
  className?: string;
};

const columnDefs = {
  timeRange: 'insulinPump.timeRange',
  basalRate: 'insulinPump.basalRate',
  carbsRatio: 'insulinPump.carbsRatio',
  insulinSensitivity: 'insulinPump.insulinSensitivity',
  targetBloodGlucose: 'insulinPump.targetBloodGlucose',
};

const units = {
  timeRange: '',
  basalRate: 'insulinPump.units.basalRate',
  carbsRatio: 'insulinPump.units.carbsRatio',
  insulinSensitivity: 'insulinPump.units.insulinSensitivity',
  targetBloodGlucose: 'insulinPump.units.targetBloodGlucose',
};

type SegmentTableData = {
  timeRange: string;
  basalRate: string;
  carbsRatio: string;
  insulinSensitivity: string;
  targetBloodGlucose: string;
};

export const InsulinPumpProfileContent: React.FC<
  InsulinPumpProfileContentProps
> = ({ profile, className }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const columns = Object.keys(columnDefs);
  const data = makeSegmentTableData(profile);
  return (
    <div className={cx(styles.container, className)}>
      <table className={styles.table}>
        <thead className={styles.row}>
          <tr>
            {columns.map(key => (
              <th key={key} className={styles.cell}>
                <Typography component="span" variant="body">
                  {t(columnDefs[key])}
                </Typography>
                <br />
                <Typography
                  component="span"
                  variant="bodySmall"
                  color="textSecondary"
                >
                  {t(units[key])}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(segment => (
            <tr
              key={segment.timeRange}
              className={cx(styles.row, styles.contentRow)}
            >
              {columns.map(key => (
                <td key={key} className={styles.cell}>
                  {segment[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const makeSegmentTableData = (
  profile: InsulinPumpProfile,
): SegmentTableData[] => {
  return profile.segments.map(segment => ({
    timeRange:
      toDateTime(segment.start).toLocaleString(DateTime.TIME_24_SIMPLE) +
      ' - ' +
      toDateTime(segment.end).toLocaleString(DateTime.TIME_24_SIMPLE),
    basalRate: formatNumber(segment.basalRate / 1000, 1),
    carbsRatio: formatNumber(segment.insulinToCarbRatio / 1000, 1),
    insulinSensitivity: formatNumber(segment.sensitivity, 1),
    targetBloodGlucose: segment.targetBloodGlucose
      ? formatNumber(segment.targetBloodGlucose, 0)
      : '-',
  }));
};

const makeStyles = (theme: Theme) => ({
  container: css`
    border-radius: ${theme.spacing(4)};
    background-color: ${theme.palette.purple.contrastText};
  `,
  table: css`
    width: 100%;
    padding: ${theme.spacing(8, 0)};
    border-collapse: separate;
    border-spacing: 0;
    overflow: clip;
  `,
  row: css`
    &:first-child {
      padding: ${theme.spacing(2, 4, 2, 12)};
    }
    &:last-child {
      padding: ${theme.spacing(2, 12, 2, 4)};
    }
    background-color: ${theme.palette.purple.contrastText};
  `,
  contentRow: css`
    &:hover {
      background-color: color-mix(
        in srgb,
        ${theme.palette.purple.contrastText} 80%,
        white 20%
      );
    }
  `,
  cell: css`
    text-align: start;
    border: 0;
    padding: ${theme.spacing(2, 4)};
  `,
});
