import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ChatContainer } from '@/components/chat/ChatContainer';
import { ChatStatusContext } from '@/components/chat/ChatContext';
import { NavItem } from '@/components/navigation/NavItem';
import { PatientNavigation } from '@/components/navigation/PatientNavigation';
import { SideBar } from '@/components/navigation/SideBar';
import { useStyles } from '@/hooks/useStyles';
import { PractitionerUser } from '@/models/UserModel.ts';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';

export type PractitionerManagePatientLayoutProps = LayoutProps & {
  me: PractitionerUser;
};

export const PractitionerManagePatientLayout: React.FC<
  PractitionerManagePatientLayoutProps
> = props => {
  const styles = useStyles(makeStyles);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [chatState, setChatState] = useState(false);

  return (
    <div
      id="manage-patient-layout"
      lang={i18n.language}
      className={styles.container}
    >
      <ChatStatusContext.Provider
        // TODO use zustand instead
        value={{ open: chatState, setOpen: setChatState }}
      >
        <SideBar home="/patients">
          <NavItem
            id="patient-list-nav-item"
            to="/patients"
            isCurrent={
              location.pathname === '/' ||
              location.pathname.startsWith('/patients')
            }
            tooltip={t('navbar.myPatients')}
          />
        </SideBar>
        <div className={styles.content}>
          <PatientNavigation className={styles.header} />
          <main id="manage-patient-layout-main" className={styles.main}>
            <RenderLayoutChildren {...props} />
          </main>
        </div>
        {chatState && <ChatContainer />}
      </ChatStatusContext.Provider>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0;
    padding: 0;
    max-width: 100vw;
    min-height: 100vh;
    background-color: ${theme.palette.background.default};
    overflow-x: clip;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
    overflow-x: clip;
  `,
  header: css`
    margin-left: ${theme.spacing(30)};
  `,
  main: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(12)} 4vw;
    margin-left: ${theme.spacing(30)};
  `,
});
