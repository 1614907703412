import { Condition, conditionerDecoder } from '@/models/ConditionModel';

import { ApiDataSourceSpec, apiDataSource } from '../datasource/ApiDatasource';

export class ConditionRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getCondition = (patientId: string): Promise<Condition> => {
    return this.datasource.get(
      `/practitioners/patients/${patientId}/conditions/`,
      conditionerDecoder,
    );
  };
}
