import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/floating/Tooltip';
import { useStyles } from '@/hooks/useStyles';

export const HeaderWithTooltip = ({
  tootlipContent,
  titleLocaleString,
}: {
  tootlipContent: string;
  titleLocaleString: string;
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="top"
      content={t(`${tootlipContent}`)}
      hover={{ handleClose: null }}
    >
      <Typography variant="body" className={styles.header}>
        {t(`${titleLocaleString}`)}
      </Typography>
    </Tooltip>
  );
};

const makeStyles = () => ({
  header: css`
    color: #5029be;
    text-align: center;
  `,
});
