import React from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles.ts';

export const NotFound: React.FC = ({}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <div id="not-found-page" className={styles.container}>
      <Typography variant="h4">{t('pages.notFound.title')}</Typography>
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  `,
});
