import React from 'react';

import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { PatientSummaryCard } from '@/uiKit/organisms/PatientSummaryCard';
import { RPMInfo } from '@/uiKit/organisms/RPMInfo';

export const MonitoringAside: React.FC = () => {
  const state = usePatientMonitoringContext(state => state);
  return (
    <>
      <PatientSummaryCard patient={state.patient} />
      <RPMInfo patientId={state.patient.id} />
    </>
  );
};
