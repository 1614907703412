import React from 'react';

import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import {
  BGMGlycemiaParameters,
  BGMGlycemiaParametersType,
  EditBGMGlycemiaParameters,
  GlycemiaParametersType,
} from '@/models/DiabetesDataModel.ts';
import { Queries } from '@/queries/Queries.ts';
import { ThresholdSettings } from '@/uiKit/molecules/thresholds/ThresholdSettings.tsx';

export type BGMThresholdSettingsProps = {
  parameters: BGMGlycemiaParameters;
  gestational: boolean;
};

export const BGMThresholdSettings: React.FC<BGMThresholdSettingsProps> = ({
  parameters,
  gestational,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const thresholdDisplayType = gestational ? 'gestational' : 'all';
  const updateThreshold = Queries.diabetes.useEditBGMParameters();

  const onEditThreshold = (
    category: BGMGlycemiaParametersType,
    threshold: GlycemiaParametersType,
    value: number,
  ) =>
    updateThreshold.mutate(
      toEditParametersDTO(parameters, category, threshold, value),
      {
        onSuccess: () =>
          TextToast.success(
            `${t(`thresholdSettings.${threshold}`)}`,
            `${t('thresholdSettings.thresholdUpdated')}: ${
              parameters[category][threshold]
            } => ${value}`,
          ),
      },
    );

  return (
    <Card className={styles.container}>
      <CardHeader title={t('thresholdSettings.gestationalThresholds')} />
      <CardContent className={styles.content}>
        <ThresholdSettings
          id="bgm-threshold-before-breakfast"
          title={t('thresholdSettings.beforeBreakfast')}
          type={thresholdDisplayType}
          glycemiaParameters={parameters.fasting}
          className={styles.step}
          onSubmit={(threshold, value) =>
            onEditThreshold('fasting', threshold, value)
          }
        />

        <ThresholdSettings
          id="bgm-threshold-before-meal"
          title={t('thresholdSettings.beforeMeal')}
          type={thresholdDisplayType}
          glycemiaParameters={parameters.beforeMeal}
          className={styles.step}
          onSubmit={(threshold, value) =>
            onEditThreshold('beforeMeal', threshold, value)
          }
        />
        <ThresholdSettings
          id="bgm-threshold-after-meal"
          title={t('thresholdSettings.afterMeal')}
          type={thresholdDisplayType}
          glycemiaParameters={parameters.afterMeal}
          className={styles.step}
          onSubmit={(threshold, value) =>
            onEditThreshold('afterMeal', threshold, value)
          }
        />
      </CardContent>
    </Card>
  );
};

/**
 * Convert the parameters to the DTO to edit the parameters
 * Set all the parameters to the current value except the one to edit
 */
const toEditParametersDTO = (
  parameters: BGMGlycemiaParameters,
  category: BGMGlycemiaParametersType,
  threshold: GlycemiaParametersType,
  value: number,
): EditBGMGlycemiaParameters => {
  const oldParameters = parameters[category];
  const newParameters = {
    severe_hyperglycemia:
      threshold === 'severeHyperglycemia'
        ? value
        : oldParameters.severeHyperglycemia,
    hyperglycemia:
      threshold === 'hyperglycemia' ? value : oldParameters.hyperglycemia,
    hypoglycemia:
      threshold === 'hypoglycemia' ? value : oldParameters.hypoglycemia,
    severe_hypoglycemia:
      threshold === 'severeHypoglycemia'
        ? value
        : oldParameters.severeHypoglycemia,
  };

  return {
    id: parameters.id,
    fasting:
      category === 'fasting'
        ? newParameters
        : {
            severe_hyperglycemia: parameters.fasting.severeHyperglycemia,
            hyperglycemia: parameters.fasting.hyperglycemia,
            hypoglycemia: parameters.fasting.hypoglycemia,
            severe_hypoglycemia: parameters.fasting.severeHypoglycemia,
          },
    before_meal:
      category === 'beforeMeal'
        ? newParameters
        : {
            severe_hyperglycemia: parameters.beforeMeal.severeHyperglycemia,
            hyperglycemia: parameters.beforeMeal.hyperglycemia,
            hypoglycemia: parameters.beforeMeal.hypoglycemia,
            severe_hypoglycemia: parameters.beforeMeal.severeHypoglycemia,
          },
    after_meal:
      category === 'afterMeal'
        ? newParameters
        : {
            severe_hyperglycemia: parameters.afterMeal.severeHyperglycemia,
            hyperglycemia: parameters.afterMeal.hyperglycemia,
            hypoglycemia: parameters.afterMeal.hypoglycemia,
            severe_hypoglycemia: parameters.afterMeal.severeHypoglycemia,
          },
  };
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  content: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
  `,
  step: css`
    flex-grow: 1;
  `,
});
