import { ApiDataSourceSpec, apiDataSource } from '../datasource/ApiDatasource';

export class QaRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  downloadGlycemiaCSV = (patientId: string): Promise<Blob> => {
    return this.datasource.get(
      `/qa/diabetes-data/${patientId}/`,
      data => data as Blob,
      {
        responseType: 'blob',
      },
    );
  };

  generateGlycemiaData = (
    patientId: string,
    glycemiaType?: string,
  ): Promise<void> => {
    return this.datasource.post(
      `/qa/diabetes-data/${patientId}/generate-data/?glycemiaType=${glycemiaType}`,
      () => undefined,
    );
  };

  deleteGlycemiaData = (patientId: string): Promise<void> => {
    return this.datasource.delete(
      `/qa/diabetes-data/${patientId}/`,
      () => undefined,
    );
  };

  uploadGlycemiaCSV = (patientId: string, file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);
    return this.datasource.post(
      `/qa/diabetes-data/${patientId}/`,
      () => undefined,
      formData,
      {
        headers: {
          'Content-Disposition': `attachment; filename=${file.name}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };

  skipOnboarding = (patientId: string): Promise<void> => {
    return this.datasource.post(
      `/qa/skip-onboarding/${patientId}/`,
      () => undefined,
    );
  };
}
