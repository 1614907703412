import { DateTime, Settings } from 'luxon';

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { AGPStats, agpStatsDecoder } from '@/models/AGPStatsModel';
import { BGMData, bgmDataDecoder } from '@/models/BGMLogbook';
import {
  BGMGlycemiaParameters,
  CGMGlycemiaParameters,
  EditBGMGlycemiaParameters,
  EditCGMGlycemiaParameters,
  bgmGlycemiaParametersDecoder,
  cgmGlycemiaParametersDecoder,
} from '@/models/DiabetesDataModel.ts';
import { GlobalStats, globalStatsDecoder } from '@/models/GlobalStatsModel';
import {
  GlycemiaIndicatorsResponse,
  glycemiaIndicatorsDecoder,
} from '@/models/GlycemiaStatsModel';
import {
  InsulinContinuousIndicators,
  InsulinIndicatorsResponse,
  insulinContinuousIndicatorDecoder,
  insulinIndicatorsDecoder,
} from '@/models/InsulinStatsModel';
import {
  MedicalDevice,
  medicalDeviceDecoder,
} from '@/models/MedicalDeviceModel';
import {
  TargetDurationStats,
  targetDurationStatsDecoder,
} from '@/models/TargetDurationStatsModel';
import { activityDecoder } from '@/models/diabetes/ActivityModel.ts';
import { foodDecoder } from '@/models/diabetes/FoodModel.ts';
import { glycemiaDecoder } from '@/models/diabetes/GlycemiaModel';
import { reportDecoder } from '@/models/diabetes/ReportModel.ts';
import { DateUtils } from '@/utils/dateUtils.ts';
import { forcedArray } from '@/utils/decoderUtils';

export class DiabetesRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getMedicalDevices = (patientId: string): Promise<MedicalDevice[]> => {
    return this.datasource.get(
      `/practitioner/devices/${patientId}/`,
      forcedArray(medicalDeviceDecoder),
    );
  };

  getBGMLogbook = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<BGMData[]> =>
    this.datasource.get(
      `/bgm-logbook/${patientId}`,
      forcedArray(bgmDataDecoder),
      {
        params: {
          from: from.startOf('day').toISO(),
          to: to.endOf('day').toISO(),
        },
      },
    );

  getGlycemia = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/glycemia/${patientId}/`,
      forcedArray(glycemiaDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };

  getFood = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/food/${patientId}/`,
      forcedArray(foodDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };

  getActivity = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/activity/${patientId}/`,
      forcedArray(activityDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };

  getReports = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/reports/${patientId}/`,
      forcedArray(reportDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };

  getCGMParameters = (patientId: string): Promise<CGMGlycemiaParameters> => {
    return this.datasource.get(
      `/practitioner/parameters/cgm/${patientId}/`,
      cgmGlycemiaParametersDecoder,
    );
  };

  editCGMParameters = ({
    id,
    ...parameters
  }: EditCGMGlycemiaParameters): Promise<CGMGlycemiaParameters> => {
    return this.datasource.patch(
      `/practitioner/parameters/cgm/${id}/`,
      cgmGlycemiaParametersDecoder,
      parameters,
    );
  };

  getBGMParameters = (patientId: string): Promise<BGMGlycemiaParameters> => {
    return this.datasource.get(
      `/practitioner/parameters/bgm/${patientId}/`,
      bgmGlycemiaParametersDecoder,
    );
  };

  editBGMParameters = ({
    id,
    ...parameters
  }: EditBGMGlycemiaParameters): Promise<BGMGlycemiaParameters> => {
    return this.datasource.patch(
      `/practitioner/parameters/bgm/${id}/`,
      bgmGlycemiaParametersDecoder,
      parameters,
    );
  };

  /**
   * @deprecated Moving to useGlycemiaIndicators
   */
  getTargetDurationStats = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<TargetDurationStats> => {
    return this.datasource.get(
      `/stats/${patientId}/target_duration/`,
      targetDurationStatsDecoder,
      {
        params: {
          date_min: from.startOf('day').toISO(),
          date_max: to.endOf('day').toISO(),
        },
      },
    );
  };

  /**
   * @deprecated Moving to useGlycemiaIndicators and useInsulinIndicators
   */
  getGlobalStats = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<GlobalStats> => {
    return this.datasource.get(
      `/stats/${patientId}/global/`,
      globalStatsDecoder,
      {
        params: {
          date_min: from.startOf('day').toISO(),
          date_max: to.plus({ day: 1 }).startOf('day').toISO(),
        },
      },
    );
  };

  /**
   * @deprecated Moving to useGlycemiaIndicators
   */
  getAGPStats = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<AGPStats> => {
    return this.datasource.get(`/stats/${patientId}/agp/`, agpStatsDecoder, {
      params: {
        date_min: from.startOf('day').toISO(),
        date_max: to.endOf('day').toISO(),
        timezone: DateUtils.tzOffset(from) / 60,
      },
    });
  };

  getGlycemiaIndicators = (
    patientId: string,
    from: DateTime<true>,
    to: DateTime<true>,
    glycemiaMeasurementMethod: string,
  ): Promise<GlycemiaIndicatorsResponse> => {
    return this.datasource.get(
      `/practitioner/indicators/glycemia/${patientId}/`,
      glycemiaIndicatorsDecoder,
      {
        params: {
          start_date: from.startOf('day').toISO(),
          end_date: to.plus({ day: 1 }).startOf('day').toISO(),
          glycemia_measurement_method: glycemiaMeasurementMethod,
          timezone_utc_offset: DateUtils.tzOffset(from) / 60,
        },
      },
    );
  };

  getInsulinDiscreteIndicators = (
    patientId: string,
    from: DateTime<true>,
    to: DateTime<true>,
  ): Promise<InsulinIndicatorsResponse> => {
    return this.datasource.get(
      `/practitioner/indicators/insulin/${patientId}/`,
      insulinIndicatorsDecoder,
      {
        params: {
          start_date: from.startOf('day').toISO(),
          end_date: to.plus({ day: 1 }).startOf('day').toISO(),
          insulin_injection_method: 'pen',
          timezone_utc_offset: DateUtils.tzOffset(from) / 60,
        },
      },
    );
  };

  getInsulinContinuousIndicators = (
    patientId: string,
    from: DateTime<true>,
    to: DateTime<true>,
  ): Promise<InsulinContinuousIndicators> => {
    return this.datasource.get(
      `/practitioner/indicators/insulin/${patientId}/`,
      insulinContinuousIndicatorDecoder,
      {
        params: {
          start_date: from.startOf('day').toISO(),
          end_date: to.plus({ day: 1 }).startOf('day').toISO(),
          insulin_injection_method: 'pump',
          timezone_utc_offset: DateUtils.tzOffset(from) / 60,
        },
      },
    );
  };

  uploadFile = (patientId: string, file: File, timezone?: string) => {
    const data = new FormData();
    data.append('timezone', timezone ?? Settings.defaultZone.name);
    data.append('name', file.name);
    data.append('file', file);
    return this.datasource.post(
      `/diabetes/file/${patientId}/`,
      () => undefined,
      data,
      {
        headers: {
          'Content-Disposition': `attachment; filename=${file.name}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };
}
