import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { MedicalDevice } from '@/models/MedicalDeviceModel.ts';
import { toDateTime } from '@/utils/dateUtils.ts';
import { formatNumber } from '@/utils/mathUtils.ts';

import { InsulinPumpProfileContent } from './InsulinPumpProfileContent';

export type InsulinPumpSettingsProps = {
  pump: MedicalDevice | undefined;
};

export const InsulinPumpSettings: React.FC<InsulinPumpSettingsProps> = ({
  pump,
}) => {
  const styles = useStyles(makeStyles);
  const theme = useTheme();
  const { t } = useTranslation();
  const profiles = useMemo(
    () =>
      pump?.insulinPump?.profiles.sort((a, b) =>
        a.currentProfile ? -1 : a.name.localeCompare(b.name),
      ) ?? [],
    [pump],
  );
  const [selectedProfile, setSelectedProfile] = useState(
    profiles.length > 0 ? profiles[0] : undefined,
  );

  useEffect(() => {
    if (selectedProfile === undefined && profiles.length > 0) {
      setSelectedProfile(profiles[0]);
    }
  }, [profiles]);

  if (!pump?.insulinPump || profiles.length === 0 || !selectedProfile) {
    return null;
  }

  return (
    <Card className={styles.container}>
      <CardHeader title={t('insulinPump.profiles')} />
      <CardContent className={styles.content}>
        <Tabs
          id="insulin-pump-profiles-tabs"
          value={selectedProfile?.id}
          onChange={(_, value) =>
            setSelectedProfile(profiles.find(profile => profile.id === value))
          }
          variant="scrollable"
          scrollButtons="auto"
        >
          {profiles.map(profile => (
            <Tab
              key={profile.id}
              id={`insulin-pump-profiles-tab-${profile.name}`}
              label={`${t('insulinPump.profile')}: ${profile.name}`}
              value={profile.id}
              color={theme.palette.purple.contrastText}
            />
          ))}
        </Tabs>

        <div className={styles.tabContent}>
          <InsulinPumpProfileContent
            profile={selectedProfile}
            className={styles.profileTable}
          />

          <div className={styles.pumpInfo}>
            <div className={styles.infoItem}>
              <Typography variant="body">{t('insulinPump.model')}</Typography>
              <Typography variant="subtitle">
                {pump.name.capitalizeAll()}
              </Typography>
            </div>

            <div className={styles.infoItem}>
              <Typography variant="body">
                {t('insulinPump.lastUpload')}
              </Typography>
              <Typography variant="subtitle">
                {toDateTime(pump.lastUpload).toLocaleString()}
              </Typography>
            </div>
            {selectedProfile.activeInsulinTime && (
              <div className={styles.infoItem}>
                <Typography variant="body">
                  {t('insulinPump.activeInsulinTime')}
                </Typography>
                <Typography variant="subtitle">
                  {`${formatNumber(selectedProfile.activeInsulinTime, 0)} ${t('insulinPump.units.activeInsulinTime')}`}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `,
  tabContent: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(16)};
    flex-wrap: wrap;
  `,
  profileTable: css`
    flex-grow: 1;
  `,
  pumpInfo: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
    justify-content: center;
  `,
  infoItem: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  title: css`
    margin-bottom: ${theme.spacing(4)};
  `,
});
