/**
 * GlobalStatsModel
 *
 * Partial object of the global stats
 * TODO find out what the real object looks like
 */
export type GlobalStatsGlycemiaReader = {
  count_hypoglycemia: number;
  daily_mean_count_measures: number;
  mean_glycemia?: number;

  prev_daily_mean_count_measures: number;
  prev_mean_glycemia?: number;
  prev_count_hypoglycemia: number;
};

export type GlobalStatsGlycemiaSensor = {
  count_hypoglycemia: number;
  daily_mean_count_measures: number;
  mean_glycemia?: number;
  usage_percent: number;
  coefficient_of_variation?: number;
  measure_per_day: number;

  prev_daily_mean_count_measures: number;
  prev_mean_glycemia?: number;
  prev_coefficient_of_variation?: number;
  prev_count_hypoglycemia: number;
  prev_usage_percent: number;
};

export type GlobalStats = {
  count_days: number;
  glycemia: {
    reader: GlobalStatsGlycemiaReader;
    sensor: GlobalStatsGlycemiaSensor;
  };
  insulin: {
    daily_mean_basal_insulin?: number;
    daily_mean_bolus_insulin?: number;
    daily_mean_count_injections: number;
    daily_mean_long_insulin: number;
    daily_mean_short_insulin: number;
    daily_mean_total_insulin: number;
  };
};

export const globalStatsDecoder = (value: unknown): GlobalStats =>
  value as GlobalStats;

/*
export const globalStatsDecoder: DecoderFunction<GlobalStats> =
  recordWithContext('GlobalStats', {
    count_days: number,
    glycemia: nullOrUndef(
      record({
        reader: nullOrUndef(
          record({
            count_hypoglycemia: nullOrUndef(number),
            daily_mean_count_measures: nullOrUndef(number),
            mean_glycemia: nullOrUndef(number),
            prev_count_hypoglycemia: nullOrUndef(number),
            prev_daily_mean_count_measures: nullOrUndef(number),
            prev_mean_glycemia: nullOrUndef(number),
          }),
        ),
        sensor: record({
          count_hypoglycemia: nullOrUndef(number),
          daily_mean_count_measures: nullOrUndef(number),
          mean_glycemia: nullOrUndef(number),
          prev_count_hypoglycemia: nullOrUndef(number),
          prev_daily_mean_count_measures: nullOrUndef(number),
          prev_mean_glycemia: nullOrUndef(number),
          prev_usage_percent: nullOrUndef(number),
          usage_percent: nullOrUndef(number),
        }),
      }),
    ),
    insulin: nullOrUndef(
      record({
        daily_mean_basal_insulin: nullOrUndef(number),
        daily_mean_bolus_insulin: nullOrUndef(number),
        daily_mean_count_injections: nullOrUndef(number),
        daily_mean_long_insulin: nullOrUndef(number),
        daily_mean_short_insulin: nullOrUndef(number),
        daily_mean_total_insulin: nullOrUndef(number),
      }),
    ),
  });
*/
