import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { SelfPatient, selfPatientDecoder } from '@/models/SelfPatientModel';

/**
 * For patient only
 */
export class PatientRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getPatient = (): Promise<SelfPatient> => {
    return this.datasource.get(`/patients/me/`, selfPatientDecoder);
  };
}
