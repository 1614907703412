import React from 'react';

import { css, cx } from '@emotion/css';
import { Button, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MenuActionTooltip } from '@/components/floating/MenuTooltip.tsx';
import { useStyles } from '@/hooks/useStyles';
import { Languages } from '@/i18n.ts';

export type LanguageSelectorProps = {
  id?: string;
  display: 'flag' | 'full';
  className?: string;
};

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  id,
  display,
  className,
}) => {
  const styles = useStyles(makeStyles);
  const { t, i18n } = useTranslation();

  return (
    <MenuActionTooltip
      placement="right"
      className={className}
      actions={Object.keys(Languages).map(lang => (
        <Typography
          key={lang}
          variant="body"
          onClick={() => i18n.changeLanguage(lang)}
          className={cx({ [styles.selected]: lang == i18n.language })}
        >
          {t(`languages.${lang}`)}
        </Typography>
      ))}
    >
      <Button id={id} variant="text">
        {display === 'full' ? (
          <Typography variant="h5">{t('languageFull')}</Typography>
        ) : (
          <Typography variant="h4">{t('languageFlag')}</Typography>
        )}
      </Button>
    </MenuActionTooltip>
  );
};

const makeStyles = (theme: Theme) => ({
  selected: css`
    background-color: ${theme.palette.action.selected};
    cursor: default;
    pointer-events: none;
  `,
});
