import camelcase from 'camelcase';
import { DecoderFunction, boolean, number } from 'typescript-json-decoder';

import { recordWithContext, stringUnion } from '@/utils/decoderUtils';

export type MailLabelType =
  | 'nursePing'
  | 'nurseToDoctor'
  | 'patientToNurse'
  | 'doctorToNurse'
  | 'dgNoInsulinPatientToDoctor'
  | 'patientExpiresSoon'
  | 'patientExpiresSoonNurse';

export type MailPreference = {
  id: number;
  enabled: boolean;
  label: MailLabelType;
};

export const mailPreferenceDecoder: DecoderFunction<MailPreference> =
  recordWithContext('MailPreference', {
    id: number,
    enabled: boolean,
    label: (value: unknown) => {
      const result = stringUnion(
        'nurse_ping',
        'nurse_to_doctor',
        'patient_to_nurse',
        'doctor_to_nurse',
        'dg_no_insulin_patient_to_doctor',
        'patient_expires_soon',
        'patient_expires_soon_nurse',
      )(value);
      return camelcase(result);
    },
  });

export type MailPreferenceUpdate = {
  id: number;
};

export const mailPreferenceUpdaterDecoder: DecoderFunction<MailPreferenceUpdate> =
  recordWithContext('MailPreferenceUpdate', {
    id: number,
  });

export const mailPreferencePriority = (mail: MailPreference): number => {
  switch (mail.label) {
    case 'nursePing':
      return 0;
    case 'nurseToDoctor':
    case 'doctorToNurse':
      return 1;
    case 'patientToNurse':
      return 2;
    case 'patientExpiresSoon':
    case 'patientExpiresSoonNurse':
      return 3;
    case 'dgNoInsulinPatientToDoctor':
      return 4;
  }
};

export const compareMailPreference = (a: MailPreference, b: MailPreference) => {
  return mailPreferencePriority(a) - mailPreferencePriority(b);
};
