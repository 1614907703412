import { MailPreferenceRepository } from '@/io/repository/MailPreferenceRepository';
import {
  MailPreferenceUpdate,
  compareMailPreference,
} from '@/models/MailPreferenceModel';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';

export class MailPreferenceQueries {
  constructor(
    private readonly mailPreference: MailPreferenceRepository = new MailPreferenceRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useMailPreference = createQuery(
    'mail-preference',
    ({}) => [],
    async () =>
      (await this.mailPreference.getPreferences()).sort(compareMailPreference),
  );
  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useUpdatePreference = createMutation(
    'update-mail-preference',
    async (ids: MailPreferenceUpdate[]) =>
      this.mailPreference.updatePreferences(ids),
    {
      onSuccess: (_, __, { queryClient }) => {
        this.useMailPreference.invalidate(queryClient, {});
      },
    },
  );
}
