import {
  DecoderFunction,
  array,
  field,
  intersection,
  number,
  record,
  union,
} from 'typescript-json-decoder';
import { fieldDecoder } from 'typescript-json-decoder/dist/literal-decoders';
import { Decoder, decodeType } from 'typescript-json-decoder/dist/types';
import { tag } from 'typescript-json-decoder/dist/utils';

/**
 * @deprecated Move to Zod
 */
type intersectUnion<U> = (U extends unknown ? (_: U) => void : never) extends (
  _: infer I,
) => void
  ? I
  : never;
type asObject<T extends unknown[]> = {
  [K in Exclude<keyof T, keyof []>]: {
    _: decodeType<T[K]>;
  };
};
type values<T> = T[keyof T];
type fromObject<T> = T extends {
  _: infer V;
}
  ? V
  : never;
type getProductOfDecoderArray<arr extends Decoder<unknown>[]> =
  fromObject<intersectUnion<values<asObject<arr>>>> extends infer P
    ? {
        [K in keyof P]: P[K];
      }
    : never;

/**
 * @deprecated Move to Zod
 */
export const stringUnion = <T extends string = ''>(...values: T[]) =>
  union(...values) as unknown as DecoderFunction<T>;

/**
 * @deprecated Move to Zod
 * undefinedValue is the value that will be decoded as undefined
 * values are the values that will be decoded as the string
 */
export const stringUnionMapUndef =
  <T extends string = ''>(undefinedValue: string, ...values: T[]) =>
  (value: unknown) => {
    if (typeof value === 'string' && value === undefinedValue) {
      return undefined;
    } else {
      return stringUnion(...values)(value);
    }
  };

/**
 * @deprecated Move to Zod
 */
export const withDefaultValue =
  <T>(decoder: DecoderFunction<T>, defaultValue: T) =>
  (value: unknown) => {
    if (value === undefined || value === null) {
      return defaultValue;
    }
    return decoder(value);
  };

/**
 * @deprecated Move to Zod
 */
export const nullOrUndef =
  <T>(decoder: DecoderFunction<T>): DecoderFunction<T | undefined> =>
  (value: unknown) => {
    if (value === null || value === undefined) {
      return undefined;
    }
    return decoder(value);
  };

/**
 * @deprecated Move to Zod
 */
export const decodeId: DecoderFunction<string> = (value: unknown) =>
  number(value).toString(10);

/**
 * @deprecated Move to Zod
 */
export const deepField = <T>(
  key: string,
  decoder: DecoderFunction<T>,
): DecoderFunction<T> => {
  const newDecoder = (value: unknown): T => {
    const keys = key.split('.');
    // Finds the value at path a.b.c in the object { a: { b: { c: value } } }
    const parsedValue = keys.reduce((acc, key) => {
      // Get the value at the current key with no decoding
      if (acc !== undefined && acc !== null && typeof acc === 'object') {
        return field(key, v => v)(acc);
      }
      return undefined;
    }, value);

    return decoder(parsedValue);
  };
  tag(newDecoder, fieldDecoder);
  return newDecoder;
};

/**
 * @deprecated Move to Zod
 */
export const recordWithContext =
  <
    schema extends {
      [key: string]: Decoder<unknown>;
    },
  >(
    context: string,
    s: schema,
  ): DecoderFunction<decodeType<schema>> =>
  (value: unknown) => {
    try {
      return record(s)(value);
    } catch (e) {
      if (typeof e === 'string') {
        e.replace(/\\t+/g, '$&\t');
        throw `While decoding ${context} : \n\t${e}`;
      } else {
        throw e;
      }
    }
  };

/**
 * @deprecated Move to Zod
 */
export const intersectionWithContext =
  <decoders extends Decoder<unknown>[]>(
    context: string,
    ...decoders: decoders
  ): DecoderFunction<getProductOfDecoderArray<decoders>> =>
  (value: unknown) => {
    try {
      return intersection(...decoders)(value);
    } catch (e) {
      if (typeof e === 'string') {
        e.replace(/\\t+/g, '$&\t');
        throw `While decoding ${context} : \n\t${e}`;
      } else {
        throw e;
      }
    }
  };

/**
 * @deprecated Move to Zod
 */
export const forcedArray =
  <T>(decoder: DecoderFunction<T>): DecoderFunction<T[]> =>
  (value: unknown): T[] => {
    if (value === undefined || value === null) {
      return [];
    }
    const data = array(v => v)(value);

    return data.reduce((acc: T[], item: unknown) => {
      try {
        acc.push(decoder(item));
      } catch (e) {
        console.warn(e);
      }
      return acc;
    }, []);
  };
