import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Logo from '@/assets/icons/logo-steto-light-full.svg?url';
import { Button } from '@/components/button/Button';
import { useStyles } from '@/hooks/useStyles';
import { useKeycloak } from '@/keycloak';
import { ScreenMin } from '@/theme/responsive';

export const Login: React.FC = () => {
  const keycloak = useKeycloak();

  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={Logo} className="" alt="Steto" />
      </div>
      <Typography variant="body" className={styles.text}>
        {t('pages.login.disclaimer')}
      </Typography>
      <Button
        buttonType="inverted"
        className={styles.button}
        onClick={() =>
          keycloak.login({
            redirectUri: window.origin,
          })
        }
      >
        {t('pages.login.connection')}
      </Button>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: clip;
    background-color: ${theme.palette.primary.main};
    padding: 10vh 15vw;
  `,
  logoContainer: css`
    display: inline-block;
    margin: auto;
  `,
  logo: css`
    min-height: 50%;
    max-width: 500px;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
  `,
  text: css`
    flex-shrink: 1;
    overflow: clip;
    text-overflow: ellipsis;
    color: ${theme.palette.grey[100]};
    margin-top: ${theme.spacing(20)};
    text-align: center;
    ${ScreenMin.MD} {
      width: 70%;
    }
    ${ScreenMin.LG} {
      width: 50%;
    }
  `,
  button: css`
    margin-top: ${theme.spacing(20)};
  `,
});
