import { DateTime } from 'luxon';

import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel.ts';
import { Activity } from '@/models/diabetes/ActivityModel.ts';
import { Food } from '@/models/diabetes/FoodModel.ts';
import { Glycemia } from '@/models/diabetes/GlycemiaModel.ts';
import { Insulin } from '@/models/diabetes/InsulinModel.ts';
import { Report } from '@/models/diabetes/ReportModel.ts';
import { DateUtils, SupportedDate } from '@/utils/dateUtils.ts';

export type CGMDailyGraphData = {
  date: string;
  parameters: CGMGlycemiaParameters;
  glycemia: Glycemia[];
  insulin: Insulin[];
  food: Food[];
  activity: Activity[];
  reports: Report[];
};

/**
 * Split the diabetes data by day
 *
 * Used to display diabetes data in the daily graph

 */
export const makeCGMDailyGraphData = (
  from: DateTime,
  to: DateTime,
  parameters: CGMGlycemiaParameters,
  glycemia: Glycemia[],
  insulin: Insulin[],
  food: Food[] = [],
  activity: Activity[] = [],
  reports: Report[] = [],
): CGMDailyGraphData[] => {
  const start = from.startOf('day');
  const end = to.endOf('day');
  if (!start.isValid || !end.isValid) {
    throw new Error('Invalid date');
  }

  // Create a map of all days between the start and end date
  const dayMap: Record<string, CGMDailyGraphData> = {};
  let index = 0;
  let day: DateTime<true> = start.plus({ days: index });
  while (day <= end) {
    // Each day has a date, a copy of the parameters and empty arrays for the data
    // The date is repeated as the key for the dayMap
    const key = day.toISODate();
    dayMap[key] = {
      date: key,
      parameters,
      glycemia: [],
      insulin: [],
      food: [],
      activity: [],
      reports: [],
    };
    index += 1;
    day = start.plus({ days: index });
  }

  // For each item in the data, add it to the corresponding day
  // Repeated for each type of data, shortening is not worth it
  glycemia.forEach(item => {
    const dayString = _get_day_key(start, item.date);
    if (dayString in dayMap) {
      dayMap[dayString].glycemia.push(item);
    }
  });
  insulin.forEach(item => {
    const dayString = _get_day_key(start, item.date);
    if (dayString in dayMap) {
      dayMap[dayString].insulin.push(item);
    }
  });
  food.forEach(item => {
    const dayString = _get_day_key(start, item.date);
    if (dayString in dayMap) {
      dayMap[dayString].food.push(item);
    }
  });
  activity.forEach(item => {
    const dayString = _get_day_key(start, item.date);
    if (dayString in dayMap) {
      dayMap[dayString].activity.push(item);
    }
  });
  reports.forEach(item => {
    const dayString = _get_day_key(start, item.date);
    if (dayString in dayMap) {
      dayMap[dayString].reports.push(item);
    }
  });

  // Turn the map into an array and sort it by date
  return Object.values(dayMap).sort((a, b) =>
    DateUtils.compareDates(b.date, a.date),
  );
};

/**
 * Get the number of days between two dates and add it to the start date
 * Then turn it into a string
 * This is the key for the dayMap
 * Used by makeCGMDailyGraphData to get the day key for an item
 * @param startDate The start date of the data
 * @param date The date of the item
 */
const _get_day_key = (startDate: DateTime, date: SupportedDate) => {
  const index = DateUtils.compareDates(date, startDate);
  const day: DateTime<true> = startDate.plus({ days: index });
  return day.toISODate();
};
