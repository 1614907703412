import React from 'react';

import { css } from '@emotion/css';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextExternalLink } from '@/components/link/TextExternalLink';
import { useStyles } from '@/hooks/useStyles';

export const Terms: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card className={styles.container} elevation={0}>
      <CardHeader id="terms-title" title={t('pages.account.terms.title')} />
      <CardContent className={styles.body}>
        <TextExternalLink
          id="terms-link"
          to="https://steto.notion.site/Conditions-G-n-rales-d-Utilisation-des-Applications-Web-et-Mobiles-St-to-040d5c1ab33544cf90cdbe121e3587cc"
        >
          {t('pages.account.terms.title')}
        </TextExternalLink>
      </CardContent>
    </Card>
  );
};

const makeStyles = () => ({
  container: css``,
  body: css``,
  iframe: css`
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  `,
});
