import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { Form } from '@/components/form/Form';
import { GridItem } from '@/components/layout/Grid';
import { FileView } from '@/components/pdf/FileView';
import { useStyles } from '@/hooks/useStyles';
import {
  CarePlan,
  getTelemonitoringTierTranslation,
} from '@/models/CarePlanModel.ts';
import {
  Condition,
  diabetesTypeTranslationMapping,
  medicationSchemaTranslationMapping,
} from '@/models/ConditionModel';
import { userFormattedName } from '@/models/UserModel.ts';
import { PatientsTagsList } from '@/pages/patients/PatientsTagsList';
import { Queries } from '@/queries/Queries';
import { ChipSelect } from '@/uiKit/atoms/ChipSelect.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';

import { EditTherapeuticObjectives } from './EditTherapeuticObjectives';

type CurrentCarePlanProps = {
  patientId: string;
  carePlan: CarePlan;
};

type FormType = {
  diabetesType: string;
  insulinScheme: string;
  telemonitoringTier: string;
};

export const CurrentCarePlan: React.FC<CurrentCarePlanProps> = ({
  patientId,
  carePlan,
}) => {
  const fakeForm = useForm<FormType>();
  const styles = useStyles(makeStyles);
  const { data: condition } = Queries.condition.useCondition(patientId);

  useEffect(() => {
    if (carePlan && condition) {
      fakeForm.setValue('diabetesType', condition.type);
      fakeForm.setValue('insulinScheme', condition.medicationSchema);
      fakeForm.setValue('telemonitoringTier', carePlan.telemonitoringTier);
    }
  }, [fakeForm, carePlan]);

  return (
    <Form
      id="FAKE_DIABETES_FORM"
      formContext={fakeForm}
      onSubmit={() => {
        // Empty
      }}
      className={styles.grid}
    >
      <GridItem row={1} col={1}>
        <TelemonitoringInfo patientId={patientId} />
      </GridItem>
      <GridItem row={1} col={2}>
        <TelemonitoringDatesInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={1} col={3}>
        <ResponsiblePractitionerInfo patientId={patientId} />
      </GridItem>
      <GridItem row={2} col={1}>
        {condition ? <DiabetesTypeInfo condition={condition} /> : null}
      </GridItem>
      <GridItem row={2} col={2}>
        {condition ? <InsulinSchemeInfo condition={condition} /> : null}
      </GridItem>
      <GridItem row={2} col={3}>
        <TelemonitoringCriteriaInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={3} col={1}>
        {condition ? <TermDateInfo condition={condition} /> : null}
      </GridItem>
      <GridItem row={4} col={`1 / span 2`}>
        <TelemonitoringGoalsInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={4} col={3}>
        <PrescriptionFileInfo carePlan={carePlan} />
      </GridItem>
    </Form>
  );
};

type TelemonitoringInfoProps = {
  patientId: string;
};
export const TelemonitoringInfo: React.FC<TelemonitoringInfoProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: carePlanData } = Queries.practitioner.useCarePlans(patientId);
  if (!carePlanData) {
    return null;
  }

  return (
    <div className={styles.column}>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {t('diabetesForms.telemonitoring')}
      </Typography>
      <PatientsTagsList telemonitoringTags={carePlanData.telemonitoring_tags} />
    </div>
  );
};

type ResponsiblePractitionerInfo = {
  patientId: string;
};

export const ResponsiblePractitionerInfo: React.FC<
  ResponsiblePractitionerInfo
> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: careTeam } = Queries.practitioner.useCareTeam(patientId);
  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  const participants = careTeam?.participants;

  if (!participants || !practitioner) {
    return null;
  }

  const nurses = participants.filter(
    participant => participant.role === 'nurse',
  );
  const doctor = participants.find(
    participant => participant.role === 'doctor',
  );

  return (
    <div className={styles.column}>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {practitioner.qualification === 'nurse'
          ? t('diabetesForms.responsibleDoctor')
          : t('diabetesForms.responsibleNurse')}
      </Typography>
      {practitioner.qualification === 'nurse' ? (
        doctor ? (
          <Typography variant="body">
            {userFormattedName(doctor.familyName, doctor.givenName)}
          </Typography>
        ) : null
      ) : nurses.length > 0 ? (
        <Typography variant="body">
          {nurses
            .map(nurse => userFormattedName(nurse.familyName, nurse.givenName))
            .join(', ')}
        </Typography>
      ) : null}
    </div>
  );
};

type InfoProps = {
  carePlan?: CarePlan;
};

type ConditionProps = {
  condition: Condition;
};

export const DiabetesTypeInfo: React.FC<ConditionProps> = ({ condition }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <div className={styles.column}>
      <Typography variant="subtitle">
        {t('diabetesForms.diabetesType')}
      </Typography>
      <div>
        <ChipSelect
          label={t(
            diabetesTypeTranslationMapping(
              condition.type,
              condition.medicationSchema,
            ),
          )}
        />
      </div>
    </div>
  );
};

export const InsulinSchemeInfo: React.FC<ConditionProps> = ({ condition }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  //
  return (
    <div className={styles.column}>
      <Typography variant="subtitle">
        {t('diabetesForms.insulinScheme')}
      </Typography>
      <div>
        <ChipSelect
          label={t(
            medicationSchemaTranslationMapping(condition.medicationSchema),
          )}
        />
      </div>
    </div>
  );
};

export const TelemonitoringCriteriaInfo: React.FC<InfoProps> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <div className={styles.column}>
      <Typography variant="subtitle">{t('diabetesForms.criteria')}</Typography>
      <div>
        {carePlan?.telemonitoringTier && (
          <ChipSelect
            label={t(
              getTelemonitoringTierTranslation(carePlan.telemonitoringTier),
            )}
          />
        )}
      </div>
    </div>
  );
};

export const TermDateInfo: React.FC<ConditionProps> = ({ condition }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  if (!condition || !condition.termDate) {
    return null;
  }

  return (
    <div className={styles.column}>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {t('diabetesForms.termDate')}
      </Typography>
      <Typography variant="body">
        {DateTime.fromISO(condition.termDate).toFormat('dd/MM/yy')}
      </Typography>
    </div>
  );
};

const TelemonitoringGoalsInfo: React.FC<Required<InfoProps>> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  const isNurse = practitioner?.qualification === 'nurse';

  return (
    <div className={styles.column}>
      <div>
        <Typography variant="subtitle" className={styles.labelStyle}>
          {t('diabetesForms.goalsShort')}
        </Typography>
        {!isNurse && <EditTherapeuticObjectives carePlan={carePlan} />}
      </div>
      {!carePlan.description && !carePlan.descriptionAttachment && (
        <Typography variant="body" className={styles.noValue}>
          {t('diabetesForms.goalsNone')}
        </Typography>
      )}
      {carePlan.descriptionAttachment && (
        <FileView file={carePlan.descriptionAttachment} />
      )}
      {carePlan.description && (
        <Typography variant="body" className={styles.goals}>
          {carePlan.description}
        </Typography>
      )}
    </div>
  );
};

const TelemonitoringDatesInfo: React.FC<Required<InfoProps>> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.column}>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {t('diabetesForms.dates')}
      </Typography>
      {
        <Typography variant="body">{`${toDateTime(
          carePlan.periodStart,
        ).toLocaleString()} - ${
          carePlan.periodEnd
            ? toDateTime(carePlan.periodEnd).toLocaleString()
            : '...'
        }`}</Typography>
      }
    </div>
  );
};

const PrescriptionFileInfo: React.FC<Required<InfoProps>> = ({ carePlan }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.column}>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {t('diabetesForms.prescription')}
      </Typography>
      {carePlan?.prescription ? (
        <FileView file={carePlan.prescription}></FileView>
      ) : (
        <Typography variant="body" className={styles.noValue}>
          {t('diabetesForms.prescriptionNone')}
        </Typography>
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  grid: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacing(4)};
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  labelStyle: css`
    font-size: ${FontSizes.body};
  `,
  goals: css`
    white-space: pre-wrap;
  `,
  noValue: css`
    color: ${theme.palette.text.disabled};
    font-style: italic;
  `,
  icon: css`
    height: ${FontSizes.body};
    width: ${FontSizes.body};
    margin-right: 0;
  `,
});
