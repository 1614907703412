import React from 'react';

import { css } from '@emotion/css';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons.ts';
import { Loader } from '@/components/loading/Loader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { AlertSettingsTable } from '@/pages/patient-settings/alert-settings/AlertSettingsTable';
import { Queries } from '@/queries/Queries';

import { AlertCreationDialog } from './AlertCreationDialog';

export const AlertSettings: React.FC = () => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL() ?? '';
  const alertSettingsQuery = Queries.alerts.useAlertConfigList(patientId, {
    enabled: !!patientId,
  });
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);

  return (
    <Card elevation={0} className={styles.container}>
      <CardHeader title={t('alertConfig.title')} />
      <CardContent>
        {alertSettingsQuery.isSuccess ? (
          <AlertSettingsTable
            patientId={patientId}
            alertConfigList={alertSettingsQuery.data}
          />
        ) : (
          <Loader size="L" />
        )}
      </CardContent>
      <CardActions>
        <Button
          id="create-alert-config"
          variant="contained"
          startIcon={<Icons.plus />}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          {t('alertConfig.create')}
        </Button>
      </CardActions>
      <Dialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      >
        <AlertCreationDialog
          patientId={patientId}
          onClose={() => setIsCreateDialogOpen(false)}
        />
      </Dialog>
    </Card>
  );
};

const makeStyles = () => ({
  container: css`
    flex: 1 1 0;
  `,
});
