import { css } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { AgpProfile } from '@/uiKit/molecules/graphs/AgpProfile';
import { GlycemiaBars } from '@/uiKit/molecules/graphs/GlycemiaBars';

type GlycemiaStatsCGMProps = { patientId: string };

export const GlycemiaStatsCGMNoData = ({
  patientId,
}: GlycemiaStatsCGMProps) => {
  const { t } = useTranslation();

  const styles = useStyles(makeStyles);

  const { data: glycemiaParameters } =
    Queries.diabetes.useParameters(patientId);

  return (
    <Card>
      <CardHeader title={t('glycemia.title')} />
      <CardContent className={styles.content}>
        <div className={styles.row}>
          <div className={styles.statsCol}>
            <div className={styles.statsItems}>
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.averageSensor')}
                precision={0}
                value={0}
                unit={'mg/dL'}
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.estimatedHba1c')}
                value={0}
                precision={0}
                unit={'%'}
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.coefVariation')}
                value={0}
                precision={0}
                unit={'%'}
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.sensorUsage')}
                value={0}
                precision={0}
                unit={'%'}
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.nbHypo')}
                value={0}
                precision={0}
              />
            </div>
          </div>
          <div>
            <Typography variant="body">
              {t('charts.timeBetweenThresholds')}
            </Typography>
            {glycemiaParameters?.type === 'CGM' && (
              <GlycemiaBars
                width={300}
                height={275}
                data={{
                  severeHyperglycemia: 0,
                  hyperglycemia: 0,
                  normal: 0,
                  hypoglycemia: 0,
                  severeHypoglycemia: 0,
                }}
                glycemiaThresholds={glycemiaParameters.parameters}
              />
            )}
          </div>
        </div>
        <div className={styles.row}>
          {glycemiaParameters?.type === 'CGM' && (
            <AgpProfile
              agp={[]}
              glycemiaParameters={glycemiaParameters.parameters}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    display: flex;
    flex-direction: column;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    row-gap: ${theme.spacing(8)};
  `,
  statsCol: css`
    flex: 1 1 50%;
  `,
  statsItems: css`
    display: flex;
    flex-direction: row;
    column-gap: ${theme.spacing(8)};
    row-gap: ${theme.spacing(12)};
    flex-wrap: wrap;
  `,
  item: css`
    flex: 0 1 30%;
  `,
});
