import { useCallback, useState } from 'react';

import { SvgPoint } from '@/utils/graphUtils.ts';

type Point = {
  x: number;
  y: number;
};

export type MousePositionOptions = {
  precision?: number;
};

export type UseMousePositionReturn = {
  position: Point | undefined;
  onMouseMove: (newPos: Point | undefined) => void;
  onMouseLeave: () => void;
};

/**
 * Hook to get the mouse position
 *
 * - Precision is the minimum distance between two points to trigger a new position
 *
 * @param options - Options for the hook
 */
export const useMousePosition = (
  options?: MousePositionOptions,
): UseMousePositionReturn => {
  const precision = options?.precision ?? 1;
  const [mousePosition, setMousePosition] = useState<Point | undefined>(
    undefined,
  );

  const onMouseMove = useCallback(
    (newPos: Point | undefined) => {
      setMousePosition(prevPos => {
        if (!newPos && !prevPos) {
          return prevPos;
        }
        if (
          newPos &&
          prevPos &&
          isNumberClose(newPos.x, prevPos.x, precision) &&
          isNumberClose(newPos.y, prevPos.y, precision)
        ) {
          return prevPos;
        }
        return newPos;
      });
    },
    [precision],
  );

  const onMouseLeave = useCallback(() => {
    setMousePosition(undefined);
  }, []);

  return {
    position: mousePosition,
    onMouseMove,
    onMouseLeave,
  };
};

const isNumberClose = (a: number, b: number, precision: number): boolean => {
  return Math.abs(a - b) < precision;
};

/**
 * When the component the mouse position is sent to is offset by a padding, the mouse position should be offset too.
 */
export const offsetMousePosition = (
  offset: { left: number; top: number } | undefined,
  position: SvgPoint | undefined,
): SvgPoint | undefined => {
  if (!position || !offset) {
    return undefined;
  }

  const offsetPosition = {
    x: position.x - offset.left,
    y: position.y - offset.top,
  };

  // If the position is outside the offset, return undefined
  if (offsetPosition.x < 0 || offsetPosition.y < 0) {
    return undefined;
  }

  return offsetPosition;
};
