import React from 'react';

import { css } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { Practitioner } from '@/models/PractitionerModel';
import { ProfessionsTranslations } from '@/models/ProfessionModel';
import { userFormattedName } from '@/models/UserModel.ts';
import { Queries } from '@/queries/Queries';

export const GeneralInformation: React.FC = () => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Card id="general-info-card" className={styles.container} elevation={0}>
      <CardHeader
        id="security-title"
        title={t('pages.account.generalInformation.title')}
      />
      <CardContent>
        <GeneralInformationContent />
      </CardContent>
    </Card>
  );
};

export const GeneralInformationContent: React.FC = () => {
  const practitioner = Queries.practitioner.usePractitioner({});

  switch (practitioner.status) {
    case 'success':
      return <PractitionerInformation practitioner={practitioner.data} />;
    default:
      return <Loader size="S" />;
  }
};

type InformationProps = {
  practitioner: Practitioner;
};

const PractitionerInformation: React.FC<InformationProps> = ({
  practitioner,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <div className={styles.infoDisplay}>
      <div className={styles.infoDisplayContent}>
        <Typography variant="bodyLarge">
          {userFormattedName(practitioner?.familyName, practitioner?.givenName)}
        </Typography>
        <Typography variant="bodySmall">{practitioner.user.email}</Typography>
        <Typography variant="bodySmall">
          {t(ProfessionsTranslations[practitioner.qualification])}
        </Typography>
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  infoDisplay: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: ${theme.spacing(12)};
  `,
  infoDisplayContent: css`
    display: flex;
    flex-direction: column;
  `,
});
