import { DateTime } from 'luxon';

import { DateUtils } from '@/utils/dateUtils.ts';

export type DiabetesDataParams = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
};

/**
 * Get next page parameters for diabetes data infinite queries
 * Go in the past
 */
export const getDiabetesDataNextPageParam = (params: DiabetesDataParams) => {
  // The diff between the "to" and "from" dates
  // + 1 to offset by one day, we don't want to include the current page's "to" date
  const diff = DateUtils.compareDates(params.to, params.from) + 1;

  return {
    patientId: params.patientId,
    from: params.from.minus({
      days: diff,
    }),
    to: params.to.minus({
      days: diff,
    }),
  };
};

/**
 * Get previous page parameters for diabetes data infinite queries
 * Go in the future
 */
export const getDiabetesDataPreviousPageParam = (
  params: DiabetesDataParams,
) => {
  // No more pages if the first page reaches the present
  if (DateUtils.compareDates(params.to, DateTime.now()) >= 0) {
    return undefined;
  }

  const diff = DateUtils.compareDates(params.to, params.from);

  return {
    patientId: params.patientId,
    // The "from" date is the next day after the current page's "to" date
    from: params.to.plus({ days: 1 }),
    // The "to" date is the next day after the current page's "to" date + the diff
    to: DateUtils.min(params.to.plus({ days: diff + 1 }), DateTime.now()),
  };
};
