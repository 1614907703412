import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { FileModel, fileDecoder } from '@/models/FileModel';
import {
  GeneratePrescriptionData,
  Prescription,
} from '@/models/PrescriptionGenerationModel';

/**
 * Generate a prescription
 */
export class PrescriptionRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  generatePrescription = (
    data: GeneratePrescriptionData,
  ): Promise<Prescription> => {
    return this.datasource.post(
      'prescriptions/create/',
      (value: unknown) => value as Prescription,
      data,
    );
  };

  signPrescription = (data: GeneratePrescriptionData): Promise<FileModel> => {
    return this.datasource.post('prescriptions/sign/', fileDecoder, data);
  };
}
