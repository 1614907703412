import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { Group } from '@visx/group';
import { ScaleLinear } from 'd3-scale';

import { useStyles } from '@/hooks/useStyles';
import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel.ts';
import { SvgText } from '@/uiKit/atoms/svg/SvgText.tsx';
import { SvgLayout } from '@/utils/graphUtils.ts';

export type GlycemiaThresholdsRectProps = {
  thresholds: CGMGlycemiaParameters;
  yScale: ScaleLinear<number, number>;
  className?: string;
} & SvgLayout;

const GlycemiaThresholdsRect_: React.FC<GlycemiaThresholdsRectProps> = ({
  className,
  thresholds,
  yScale,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const y1 = yScale(thresholds.hyperglycemia);
  const y2 = yScale(thresholds.hypoglycemia);
  const height = y2 - y1;
  const offset = 4;

  return (
    <Group className={cx(styles.container, className)} {...dim}>
      <SvgText
        left={-offset}
        top={y1 - offset}
        className={styles.text}
        variant="caption"
      >
        {thresholds.hyperglycemia}
      </SvgText>
      <SvgText
        left={-offset}
        top={y2 - offset}
        className={styles.text}
        variant="caption"
      >
        {thresholds.hypoglycemia}
      </SvgText>
      <rect
        x={0}
        y={y1}
        width={dim.width}
        height={height}
        className={styles.rect}
      />
    </Group>
  );
};

export const GlycemiaThresholdsRect = React.memo(GlycemiaThresholdsRect_);

const makeStyles = (theme: Theme) => ({
  container: css``,
  rect: css`
    fill: ${theme.palette.glycemia.normal.light};
    opacity: 0.1;
  `,
  text: css({
    textAnchor: 'end',
    stroke: 'transparent',
    fill: theme.palette.glycemia.normal.main,
  }),
});
