import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { InsulinDistribution } from '@/uiKit/organisms/DiabetesStats/InsulinDistribution.tsx';
import { InsulinStatItem } from '@/uiKit/organisms/DiabetesStats/InsulinStatItem.tsx';

type InsulinStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
};

export const InsulinStatsCGMContinuous = ({
  patientId,
  from,
  to,
}: InsulinStatsProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: insulinStats } =
    Queries.diabetes.useInsulinContinuousIndicators({
      patientId,
      from: from,
      to: to,
    });

  if (!insulinStats) {
    return null;
  }

  return (
    <Card className={styles.container}>
      <CardHeader title={t('insulin.title')} />
      <CardContent className={styles.content}>
        <div>
          <InsulinStatItem
            title={t('insulin.averageNbBolusInjectionsPerDay')}
            value={insulinStats.averageNumberOfBolusInjection}
            precision={1}
          />
        </div>
        <InsulinDistribution
          basalTitle={t('insulin.basalInsulinPerDay')}
          bolusTitle={t('insulin.bolusInsulinPerDay')}
          basal={insulinStats.averageBasalQuantityInjected}
          bolus={insulinStats.averageBolusQuantityInjected}
          wheelSize="big"
          className={styles.distribution}
        />
      </CardContent>
    </Card>
  );
};

export const InsulinStatsCGMDiscrete = ({
  patientId,
  from,
  to,
}: InsulinStatsProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: insulinStats } = Queries.diabetes.useInsulinDiscreteIndicators({
    patientId,
    from: from,
    to: to,
  });

  if (!insulinStats) {
    return null;
  }

  return (
    <Card className={styles.container}>
      <CardHeader title={t('insulin.title')} />
      <CardContent className={styles.content}>
        <div>
          <InsulinStatItem
            title={t('insulin.averageNbInjectionsPerDay')}
            value={insulinStats.averageNumberOfInjection}
            precision={1}
          />
        </div>
        <InsulinDistribution
          basalTitle={t('insulin.slowInsulinPerDay')}
          bolusTitle={t('insulin.fastInsulinPerDay')}
          basal={insulinStats.averageQuantityInjected.basal}
          bolus={insulinStats.averageQuantityInjected.bolus}
          wheelSize="big"
          className={styles.distribution}
        />
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
  `,

  distribution: css`
    flex: 1 1;
    justify-content: flex-end;
  `,
});
