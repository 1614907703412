import React, { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/floating/Tooltip.tsx';
import {
  Insulin,
  getInsulinReasonTranslation,
  getInsulinTypeTranslation,
} from '@/models/diabetes/InsulinModel.ts';
import { SvgDataListTooltip } from '@/uiKit/atoms/svg/SvgDataListTooltip';
import { formatNumber } from '@/utils/mathUtils.ts';

const InsulinTooltipContent: React.FC<{ items: Insulin[] }> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <SvgDataListTooltip
      items={items.map(insulin => ({
        title: `${formatNumber(insulin.quantity, 1)} U`,
        content: [
          `${t(getInsulinTypeTranslation(insulin.type))} - ${t(getInsulinReasonTranslation(insulin.reason), { context: 'short' })}`,
          insulin.carbs
            ? `${t('insulin.carbs')} ${formatNumber(insulin.carbs ?? 0, 1)} g`
            : undefined,
          insulin.carbsRatio
            ? `${t('insulin.carbsRatio')} ${formatNumber(insulin.carbsRatio ?? 0, 1)} g/U`
            : undefined,
        ],
        date: insulin.date,
        inputMethod: insulin.deviceType,
      }))}
    />
  );
};

export type InsulinGraphTooltipProps = PropsWithChildren<{
  insulin: Insulin;
}>;

export const InsulinGraphTooltip: React.FC<InsulinGraphTooltipProps> = ({
  insulin,
  children,
}) => (
  <Tooltip
    svg
    placement="top"
    dismiss={false}
    autoOpen
    offset={0}
    content={<InsulinTooltipContent items={[insulin]} />}
  >
    {children}
  </Tooltip>
);

export type InsulinPointTooltipProps = PropsWithChildren<{
  insulins: Insulin[];
  x?: number;
  y?: number;
}>;

export const InsulinPointTooltip: React.FC<InsulinPointTooltipProps> = ({
  insulins,
  x,
  y,
  children,
}) => (
  <Tooltip
    svg
    placement="top"
    relativeClientPoint
    clientPoint={{
      enabled: true,
      x: x ?? 0,
      y: y ?? 0,
    }}
    content={<InsulinTooltipContent items={insulins} />}
  >
    {children}
  </Tooltip>
);
