import { DateTime } from 'luxon';

import { Tooltip } from '@/components/floating/Tooltip.tsx';
import { BGMConcentrationType } from '@/models/BGMLogbook';
import { toDateTime } from '@/utils/dateUtils.ts';

export const Insulin = ({ data }: { data: BGMConcentrationType[] }) => {
  const insulinFormatted = data.map(el => el.concentration).join('+');
  const timeFormatted = data
    .map(el => toDateTime(el.date).toLocaleString(DateTime.TIME_SIMPLE))
    .join('+');
  return (
    <Tooltip
      placement="top"
      content={timeFormatted}
      animation={{ duration: 50 }}
      hover={{ handleClose: null }}
    >
      <span>{insulinFormatted}</span>
    </Tooltip>
  );
};
