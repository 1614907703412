import {
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
  useTransition,
} from 'react';

export type ComponentSize = {
  width: number;
  height: number;
};

export type UseComponentSizeReturn = [
  MutableRefObject<HTMLElement | null>,
  ComponentSize,
  boolean,
];

export const useComponentSize = (): UseComponentSizeReturn => {
  const [isTransitioning, startTransition] = useTransition();
  const componentRef = useRef<HTMLElement>(null);
  const [componentSize, setComponentSize] = useState<ComponentSize>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      const component = componentRef.current;
      if (component) {
        setTimeout(
          () =>
            startTransition(() => {
              setComponentSize({
                width: component.clientWidth,
                height: component.clientHeight,
              });
            }),
          1,
        );
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [componentRef, componentSize, isTransitioning];
};
