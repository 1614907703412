import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import {
  AlertConfig,
  AlertSummary,
  CreateAlertConfig,
  EditAlertConfig,
  alertConfigDecoder,
  alertSummaryDecoder,
} from '@/models/AlertModel';
import { forcedArray } from '@/utils/decoderUtils';

export class AlertsRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getAlertConfigList = (patientId: string): Promise<AlertConfig[]> => {
    return this.datasource.get(
      `/alert-configs/${patientId}/`,
      forcedArray(alertConfigDecoder),
    );
  };

  getAlertList = (patientId: string): Promise<AlertSummary[]> => {
    return this.datasource.get(
      `/practitioners/patients/${patientId}/alerts/`,
      forcedArray(alertSummaryDecoder),
    );
  };

  createAlertConfig = (
    patientId: string,
    alertConfig: CreateAlertConfig,
  ): Promise<AlertConfig> => {
    return this.datasource.post(
      `/alert-configs/${patientId}/`,
      alertConfigDecoder,
      alertConfig,
    );
  };

  editAlertConfig = (
    patientId: string,
    { id: alertConfigId, ...alertConfig }: EditAlertConfig,
  ): Promise<AlertConfig> => {
    return this.datasource.patch(
      `/alert-configs/${patientId}/${alertConfigId}/`,
      alertConfigDecoder,
      alertConfig,
    );
  };

  deleteAlertConfig = (
    patientId: string,
    alertConfigId: string,
  ): Promise<AlertConfig> => {
    return this.datasource.delete(
      `/alert-configs/${patientId}/${alertConfigId}/`,
      alertConfigDecoder,
    );
  };

  dismissAlert = (
    patientId: string,
    alertId: string,
  ): Promise<AlertSummary> => {
    return this.datasource.delete(
      `/alerts/${patientId}/${alertId}/`,
      alertSummaryDecoder,
    );
  };
}
