import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import {
  SvgDataTooltip,
  SvgDataTooltipProps,
} from '@/uiKit/atoms/svg/SvgDataTooltip.tsx';

export type SvgDataListTooltipProps = {
  items: SvgDataTooltipProps[];
};

export const SvgDataListTooltip: React.FC<SvgDataListTooltipProps> = ({
  items,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      {items.map((d, idx) => (
        <SvgDataTooltip key={idx} {...d} />
      ))}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.divider};
    }
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
  `,
});
