import React from 'react';

import { css } from '@emotion/css';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { useKeycloak } from '@/keycloak';

export type SecurityProps = {
  className?: string;
};

export const Security: React.FC<SecurityProps> = ({ className }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const keycloak = useKeycloak();

  return (
    <Card id="security-card" className={className} elevation={0}>
      <CardHeader
        id="security-title"
        title={t('pages.account.security.title')}
      />
      <CardContent>
        <Button
          id="update-password-button"
          className={styles.button}
          variant="outlined"
          onClick={() => keycloak.login({ action: 'UPDATE_PASSWORD' })}
        >
          {t('pages.account.security.updatePassword')}
        </Button>
      </CardContent>
    </Card>
  );
};

const makeStyles = () => ({
  button: css`
    width: fit-content;
  `,
});
