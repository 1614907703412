import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { FileModel, fileDecoder } from '@/models/FileModel';
import { UploadFileModel } from '@/models/UploadFileModel';

export class UploadFileRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  uploadFile = (data: UploadFileModel): Promise<FileModel> => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('type', data.type);
    formData.append('patient_firstname', data.patient_firstname);
    formData.append('patient_lastname', data.patient_lastname);

    return this.datasource.post(
      'practitioners/upload-file/',
      fileDecoder,
      formData,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      },
    );
  };
}
